import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import * as css from '../styles'
import propStyles from 'prop-styles'
import Cookies from 'js-cookie'

import { Link, Grid, FadeIn, Button } from 'components'
import { AnimatedLogo } from 'app/browse/LogoAnimations'


const SitemapLink = ({ url, title, group, user, target, condition, onClick = false, external = false }) => {
  return condition ? (
    <li>
      <NavLink nav to={url} target={target} onClick={onClick} external={external}>{title}</NavLink>
    </li>
  ) : null
}

const Sitemap = (props) => {
  const { auth_token, roles } = useSelector(store => store.auth.data)
  const { data: { user: { first_name, is_artist, is_guest = true }, unread_messages, available_funds }, status } = useSelector(store => store.current_user)
  const isAuthorized = !is_guest
  const isArtist = roles.includes('Artist') || is_artist

  const year = new Date().getFullYear()

  const links = [
    // Template
    // { url: '', title: '', group: '', user: false, condition: true },

    // Sitemap main links
    // { url: '/', title: 'Home', group: 'discover', user: false, condition: true },
    // { url: '/search', title: 'Search', group: 'discover', user: false, condition: true },
    { url: '/artworks', title: 'Artworks', group: 'discover', user: false, condition: true },
    { url: '/artists', title: 'Artists', group: 'discover', user: false, condition: true },
    { url: '/articles', title: 'Articles', group: 'discover', user: false, condition: true },
    { url: '/cart', title: 'Cart', group: 'discover', user: false, condition: true },
    { url: '/gift-cards', title: 'Gift Cards', group: 'discover', user: false, condition: true, },

    // Company
    { url: '/how-it-works', title: 'How It Works', group: 'company', user: false, condition: true },
    { url: '/faq', title: 'FAQ', group: 'company', user: false, condition: true },
    { url: '/chat', title: 'Chat', group: 'company', user: false, condition: true },
    { url: '/articles/the-story-of-art-in-res', title: 'About', group: 'company', user: false, condition: true },

    // Community / social links
    { url: 'https://instagram.com/artinres', title: 'Instagram', group: 'social', user: false, condition: true, target: '_blank' },
    { url: 'https://twitter.com/artinres', title: 'Twitter', group: 'social', user: false, condition: true, target: '_blank' },
    { url: '/invite', title: 'Invite Friends', group: 'social', user: true, condition: true },
    // { url: 'https://pinterest.com/art_in_res', title: 'Pinterest', group: 'social', user: false, condition: true },

    // Account - logged out
    { url: '/login', title: 'Login', group: 'account', user: false, condition: true },
    { url: '/create-account', title: 'Create Account', group: 'account', user: false, condition: true },
    { url: '/artist-application', title: 'Artist Application', group: 'account', user: false, condition: true },
    { url: '/start-your-own-art-business', title: 'Start your own Art Business', group: 'account', user: false, condition: true },

    // Account - logged in
    { url: '/works', title: 'Works', group: 'account', user: true, condition: isArtist },
    { url: '/messages', title: 'Messages', group: 'account', user: true, condition: true },
    { url: '/purchases', title: 'Purchases', group: 'account', user: true, condition: true },
    { url: '/likes', title: 'Saved', group: 'account', user: true, condition: true },
    // { url: '/likes', title: 'Likes', group: 'account', user: true, condition: true },
    // { url: '/following', title: 'Following', group: 'account', user: true, condition: true },
    // { url: '/lists', title: 'Collections', group: 'account', user: true, condition: true },
    // { url: '/history', title: 'View History', group: 'account', user: true, condition: true },
    { url: '/useraccount', title: 'My Account', group: 'account', user: true, condition: true, external: true },
    { url: '/logout', title: 'Logout', group: 'account', user: true, condition: true },

    // Footer links
    { url: '/articles/terms-of-service', title: 'Terms of Service', group: 'footer', user: false, condition: true },
  ]

  return(
    <SitemapWrapper>

      <SitemapNav columns="1fr 1fr 1fr 0.6fr" columnsMobile="1fr 1fr 1fr 1fr 0.5fr">

        <Menu>
          <h5>Discover</h5>
          <ul>
            {links.filter(l=>l.group==='discover').map(link => <SitemapLink {...link}/>)}
          </ul>
        </Menu>

        <Menu>
          <h5>Support</h5>
          <ul>
            {links.filter(l=>l.group==='company').map(link => <SitemapLink {...link}/>)}
          </ul>
        </Menu>

        <Menu>
          <h5>Account</h5>
          <ul>
            {links.filter(l=>l.group==='account' && l.user === isAuthorized).map(link => <SitemapLink {...link}/>)}
          </ul>
        </Menu>

        <Menu>
          <h5>Social</h5>
          <ul>
            {links.filter(l=>l.group==='social').map(link => <SitemapLink {...link}/>)}
          </ul>
        </Menu>

        
        {/* <Menu style={{opacity: '0.5'}}>
          <AnimatedLogo size="40px" />
          &copy; {year} Art in Res
        </Menu> */}
      
      </SitemapNav>

      <SitemapFooter>
        <span>
        &copy; {year} Art in Res 
        &nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;
        <ul>
          {links.filter(l => l.group === 'footer').map(link => <SitemapLink {...link} />)}
        </ul>
        </span>
      </SitemapFooter>

    </SitemapWrapper>
  )
}

const SitemapFooter = styled('div')({
  ...css.widths.outer,
  maxWidth: '900px',
  // padding: '0 1em',

  color: css.color.gray,
  marginTop: '1em',

  '& span':{
    fontSize: '0.7em',
  },

  '& ul':{
    display: 'inline',
    margin: 0,
    padding: 0,
    '& li':{
      display: 'inline',
      '& a':{
        color: `${css.color.gray} !important`,
      },
    },
  },
})

const Menu = styled('div')({
  margin: '1em 0',

  '&:last-of-type':{
    // textAlign: 'right',
    color: css.color.darkgray,
    [css.breakpoint.m_down]:{
      // textAlign: 'left',
      // order: -1,
    },
  },
})

const SitemapWrapper = styled('footer')({
  fontFamily: css.font.heading,
  background: css.color.white,
  // background: css.color.lightblue,

  borderTop: `1px dotted ${css.color.lightgray}`,
  transition: css.transition.fast,
  padding: '2em 2em 3em',
  // padding: '1em 0em 2em',
  // padding: '2em 0em 2em 4em',
  
  [css.breakpoint.m_down]: {
    padding: '2em 0',
    overflowX: 'scroll',
    WebkitOverflowScrolling: 'touch',
  },
})

const SitemapNav = styled(Grid)({
  ...css.widths.outer,
  maxWidth: '900px',

  [css.breakpoint.m_down]:{
    width: '200%',
  },

  '& h5':{
    margin: '0 0 1em',
    padding: 0,
    fontSize: '0.65em',
    color: css.color.darkgray,
    // borderBottom: `1px dotted ${css.color.darkgray}`,
  },

  '& hr': {
    margin: '0.75em 0',
    border: `0.5px solid ${css.color.lightgray}`,
  },

  '& ul':{
    listStyle: 'none',
    padding: 0,
    margin: 0,
    [css.breakpoint.m_down]: {
      // display: 'grid',
      // gridTemplateColumns: '1fr 1fr',
      // gridGap: 0,
      // fontSize: '0.9em',
    },

    '& li':{
      display: 'block',
      padding: '0.2em 0',
      // textAlign: 'center',
      fontSize: '0.8em',
      [css.breakpoint.m_down]: {
        // display: 'block',
        // padding: '0.5em 0.5em',
      }
    }
  }
})

const NavLink = styled(Link)({
  // margin: `0 ${css.space.r}`,
  transition: css.transition.fast,
  
  // color: css.color.black,
  color: css.color.darkgray,
  textDecoration: 'none',
  // textTransform: 'uppercase',
  // fontSize: css.font.xxs,
  // letterSpacing: '1.5px',

  '& div':{
    display: 'inline-block',
    padding: '0.25em 0 0 0',
    borderBottom: `2px solid transparent`,
  },

  '&:hover': {
    // color: css.color.primary,
    '& div': {
      // color: css.color.primary,
      color: css.color.darkgray,
      borderBottom: `2px solid ${css.color.darkgray}`,
    },
  },

  '&.active': {
    '& span, & div': {
      borderBottomColor: css.color.darkgray,
      color: css.color.darkgray,
    }
  }
}, propStyles({
  feature: {
    background: css.color.blue,
    borderRadius: '2px',
    color: css.color.white + ' !important',
  }
}))

export default Sitemap