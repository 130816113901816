import React, { Component } from 'react';
import * as css from 'styles';
import styled from '@emotion/styled';
import propStyles from 'prop-styles';

// Import Components and Containers
import FieldWrapper from './FieldWrapper';
import Label from './FieldLabel';
import Message from './FieldMessage';

import InputWrapper from './InputWrapper';
import InputNumber from './InputNumber';
import InputSelect from './InputSelect';
import InputButtonSelect from './InputButtonSelect';
import { RadioButton, CheckBox } from './InputCheck';
import TextArea from './InputTextArea';
import File from './InputFile';

// Styles ONLY used in this component
export const style = {
  transition: css.transition.norm,
  overflow: 'hidden',
  width: '100%',
  // minWidth: '100%',
  // maxWidth: '100%',
  height: css.input.height,

  margin: 0,
  padding: `0.1em ${css.space.r}`,
  textAlign: 'center',
  fontFamily: css.font.heading,

  backgroundColor: css.color.white,
  color: css.color.black,
  fontSize: css.font.r,
  // borderRadius: css.border.radius,
  borderRadius: css.font.l,
  border: `1px solid ${css.color.offwhitedark2}`,
  appearance: 'none',

  '&::placeholder': {
    // color: css.color.lightgrayhover,
    color: css.color.gray,
    transition: css.transition.fast,
    [css.breakpoint.m_down]: {
      color: `${css.color.darkgray} !important`,
    },
  },

  // States
  ':hover': {
    borderColor: css.color.gray,
    '&::placeholder': {
      color: css.color.lightgray,
    },
  },
  ':focus': {
    outline: 0,
    borderColor: css.color.primary,
    '&::placeholder': {
      color: css.color.lightgray,
    },
  },
  // ':active': {
  //   borderColor: css.color.black,
  // },
  // '&:placeholder-shown': {
  //   color: css.color.lightgray
  // },

  // Breakpoints
  [css.breakpoint.s_down]: {
    fontSize: css.font.r,
    height: css.input.s_height,
  },
};

const error = {
  borderColor: css.color.red,
  animation: `${css.shakeBig} 0.5s forwards ease`,
  ':hover': {
    borderColor: css.color.red,
  },
};

const success = {
  borderColor: css.color.green,
  ':hover': {
    borderColor: css.color.green,
  },
};

const inline = {
  width: 'auto',
};

const label = {
  // borderTopLeftRadius: css.border.radius,
};

const rounded = {
  borderRadius: css.font.l,
};

const square = {
  borderRadius: '4px',
};

const disabled = {
  backgroundColor: css.color.lightgray,
};

class InputSwitcher extends Component {
  renderLabel = () => this.props.label && <Label htmlFor={this.props.id}>{this.props.label}</Label>;

  switchType = (type) => {
    const { square, rounded, register, input, ...props } = this.props;
    switch (type) {
      // Special input types with extra HTML
      case 'textarea':
        return (
          <div>
            {this.renderLabel()}
            <TextArea {...props} {...input} register={register} />
          </div>
        );
      case 'select':
        return (
          <div>
            {this.renderLabel()}
            <InputSelect {...props} {...input} register={register} />
          </div>
        );
      case 'number':
        return (
          <div>
            {this.renderLabel()}
            <InputNumber {...props} {...input} register={register} />
          </div>
        );
      case 'checkbox': {
        return (
          <div>
            <CheckBox {...props} {...input} ref={register} />
            {this.renderLabel()}
          </div>
        );
      }
      case 'radio':
        return (
          <div>
            <RadioButton {...props} {...input} ref={register} />
            {this.renderLabel()}
          </div>
        );
      case 'button-select':
        return (
          <div>
            <InputButtonSelect {...props} {...input} register={register} />
            {this.renderLabel()}
          </div>
        );
      case 'file':
        return (
          <div>
            {this.renderLabel()}
            <InputWrapper>
              <File {...props} {...input} ref={register} />
            </InputWrapper>
          </div>
        );
      case 'hidden':
        return <input {...props} {...input} ref={register} />;
      // Everything else
      default:
        return (
          <div>
            {this.renderLabel()}
            <InputWrapper>
              <input {...props} {...input} ref={register} />
            </InputWrapper>
          </div>
        );
    }
  };

  render() {
    return this.switchType(this.props.type);
  }
}

const InputWithStyles = styled(InputSwitcher)(
  style,
  propStyles({
    error,
    success,
    inline,
    label,
    rounded,
    square,
    disabled,
  })
);

export class Input extends Component {
  render() {
    const { errors, success, input, ...props } = this.props;
    const hasError = errors && errors[input ? input.name : this.props.name];
    const hasSuccess = success && success[input ? input.name : this.props.name];

    return (
      <FieldWrapper inputType={props.type}>
        <InputWithStyles id={props.name} {...props} error={hasError ? 1 : 0} success={hasSuccess ? 1 : 0} />
        {hasError && <Message error>{hasError.constructor === Array ? hasError[0] : hasError}</Message>}
        {hasSuccess && <Message success>{hasSuccess}</Message>}
      </FieldWrapper>
    );
  }
}

export default Input;
