// Reducer state templates for API requests

export const apiState = (state, overrides) => ({
  ...state, // additional state first, so api request override
  status: 'Idle',
  error: false,
  data: null,
  ...overrides, // state defined in model state last, so developer can override or add more
})

export const apiStatePending = (state, data) => ({
  ...state, // additional state first, so api request override
  data: state.data.constructor === Object ? {...state.data, notice: false} : state.data,
  sideAffect: data,
  status: 'Pending',
  error: false,
})

export const apiStateError = (state, error) => ({
  ...state, // additional state first, so api request override
  status: 'Error',
  error: error,
})

export const apiStateSuccess = (state, data) => ({
  ...state, // additional state first, so api request override
  status: 'Success',
  error: false,
  // data: data, // keep this - this works, the thing below might not
  data: !data ? state.data : state.data.constructor === Array ? data : {...state.data, ...data},
})

export const apiStateResetStatus = (state, data) => ({
  ...state,
  status: 'Idle',
  error: false,
})

export const apiStateUpdateData = (state, data) => ({
  ...state,
  data: data,
})

export const apiReducers = (resetState) => ({
  // handle state changes with pure functions
  initial: (state, payload) => apiState(state, resetState),
  pending: (state, payload) => apiStatePending(state, payload),
  success: (state, payload) => apiStateSuccess(state, payload),
  error:   (state, payload) => apiStateError(state, payload),
  resetStatus:  (state, payload) => apiStateResetStatus(state, payload),
  updateData:   (state, payload) => apiStateUpdateData(state, payload),
})

export default apiReducers
