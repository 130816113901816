// import { dispatch } from '@rematch/core'

const consoleLog = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type.includes('LOG')) {
    console.log(action.type, action.payload);
  }
};

export default consoleLog
