import * as css from 'styles'
import styled from '@emotion/styled'
import propStyles from 'prop-styles'

const FieldMessage = styled('div')({
  color: css.color.gray,
  fontSize: css.font.xs,
  padding: `${css.space.xs} 0`,
  textTransform: 'sentence',
  // position: 'absolute',
  // top: '-26px',
  textAlign: 'center',
  width: '100%',
},propStyles({
  error: { color: css.color.red },
  success: { color: css.color.green },
}))

export default FieldMessage
