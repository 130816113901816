import Settings from 'settings'

export const profile = {
  name: 'profile',
  request: {
    url: `${Settings.api}/users/:id`,
    middleware: {
      checkStore: store => store.location.payload.id === store.profile.data.user.id
    }
  },
  state: {
    data: {
      user: {},
      artist_artworks: [],
      artist_articles: [],
      work_lists: [],
    }
  },
}
