import * as css from 'styles'
import styled from '@emotion/styled'

export const Check = styled('input')({
  opacity: 0,
  position: 'absolute',

  '& + label': {
    margin: `${css.space.r} 0 0.1em`,
    position: 'relative',
    cursor: 'pointer',
    left: '35px',
    color: css.color.black,
    fontSize: css.font.r,
    userSelect: 'none',
  },
  '& + label:before': {
    content: `' '`,
    position: 'absolute',
    left: '-35px',
    top: '-5px',
    width: '25px',
    height: '25px',
    display: 'block',
    background: css.color.white,
    border: `1px solid ${css.color.lightgray}`,
    borderRadius: '1px',
  },
  '& + label:after': {
    content: `' '`,
    position: 'absolute',
    left: '-29px',
    top: '1px',
    width: '15px',
    height: '15px',
    display: 'block',
    zIndex: 1,
    background: css.color.primary,
    // border: `${css.border.form} ${css.color.gray}`,
    borderRadius: '0px',
    // background: 'url('images/tick.svg') no-repeat center center',
    // transition: css.transition.xfast,
    transition: `all ${css.speed[2]}ms cubic-bezier(0.175, 0.885, 0.320, 1.275)`,
    opacity: 0,
    transform: 'scale(0.5)',
  },

  // States
  ':checked + label:after':{
    opacity: 1,
    transform: 'scale(1)',
  },
  ':focus + label:before':{
    borderColor: css.color.primary,
  }
})

export const CheckBox = styled(Check)({
  '& + label:before': {
    borderRadius: '1px !important',
  },
  '& + label:after': {
    borderRadius: '1px !important',
  },
})

export const RadioButton = styled(Check)({
  '& + label:before': {
    borderRadius: '50% !important',
  },
  '& + label:after': {
    borderRadius: '50% !important',
  },
})
