import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import * as css from 'styles'

import Header from './Header'
import HeaderMinimal from './HeaderMinimal'
import Footer from './Footer'
import Sitemap from './Sitemap'
import Notices from 'app/notices/Notices'

const LayoutGrid = styled('div')({})

const Content = styled('main')({
  marginTop: `${css.heights.header}px`,
  minHeight: `calc(100vh - ${css.heights.header}px)`,
  [css.breakpoint.m_down]: {
    marginTop: `${css.heights.header_s}px`,
    minHeight: `calc(100vh - ${css.heights.header_s}px)`,
  },
})

const BaseLayout = ({ 
  minimal = false, 
  showFooter = true, 
  showNotices = true, 
  showSitemap = true, 
  fadeIn = true,
  style, 
  ...props
}) => {

  // Scroll to Top
  const location = useSelector(store => store.location)
  useEffect(() => {location.kind === 'push' && window.scrollTo(0, 0)}, [])

  return (
    <>
    <LayoutGrid>
      {minimal ? <HeaderMinimal {...props}/> : <Header {...props}/>}

      <Content style={style}>
        <FadeOutScreen fadeIn={fadeIn}/>
        {showNotices && <Notices />}
        {props.children}
      </Content>

      {showFooter && <Footer/>}

      {showSitemap && <Sitemap />}
    </LayoutGrid>
    </>
  )
}

export const FadeOutScreen = styled('div')(({ fadeIn = true })=>({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: css.z.header-2,
  background: css.color.offwhite,
  animation: fadeIn ? `${css.fadeOut} ${css.speed[3]}ms linear forwards` : null,
  pointerEvents: 'none',
}))

export default BaseLayout