import { init } from '@rematch/core'
import * as models from './models'

// Plugins
import selectorsPlugin from '@rematch/select'
// import routerPlugin from './plugins/router'

// Model extensions
import { extendModels } from './plugins/extendModel'
import api from './plugins/api/api.model'
import filters from './plugins/filters/filters.model'

// Middleware
import checkStore from './middleware/checkStore'
import consoleLog from './middleware/log'
import apiMiddleware from './plugins/api/api.middleware'
import queryMiddleware from './plugins/router/query.middleware';
import openLoginModal from './middleware/openLoginModal'
import broadcastEvents from './middleware/broadcastEvents'
import prepopulateMiddleware from './plugins/shop/prepopulate.middleware';
import LogRocket from 'logrocket'

// Redux-first-router
import { connectRoutes } from 'redux-first-router'
// import history from './history'
import { routesMap } from './routes'
import queryString from 'querystringify' // needed to enable params from url

const {
  middleware: routesMiddleware, // rename destructured variables for clarity in strore init()
  enhancer: routesEnhancer,
  reducer: routesReducer,
} = connectRoutes(
  // history,
  routesMap,
  {querySerializer: queryString}
) // yes, 3 redux aspects

// Setup store
const store = init({
  models: extendModels( models, [api,filters] ),
  plugins: [
    // routerPlugin,
    selectorsPlugin(),
  ],
  redux: {
    reducers: {
      location: routesReducer,
    },
    enhancers: [routesEnhancer],
    middlewares: [
      openLoginModal,
      routesMiddleware,
      consoleLog,
      checkStore,
      apiMiddleware,
      queryMiddleware,
      prepopulateMiddleware,
      broadcastEvents,

      // Tracking middleware must go last
      LogRocket.reduxMiddleware(),
    ]
  },
})

// Example adding to store after initialization: 
// store.model({name: 'poop', state: {poop: true} })
// store.model({ name: 'countB', state: 99 })

export const { getState, dispatch } = store
export default store // () => store
