import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import formatPrice from 'utils/formatPrice'
import { Modal, Grid, navigate } from 'components'
import FormBuilder from 'components/FormBuilder'

export const RequestsAcceptModal = () => {
  const dispatch = useDispatch()
  const modal = useSelector(store => store.modals['purchase-requests-accept'])
  const { status, data: { collector = {}, artwork = {}, ...transaction } } = useSelector(store => store.payment_plan)

  const handleSuccess = () => {
    dispatch.modals.close({ name: 'purchase-requests-accept' })
    navigate(`/sales/${transaction.id}?type=${transaction.type}`)
  }

  return (
    <Modal name="purchase-requests-accept">
      {status === 'Success' && transaction.type ? (
        <>
        <center>
          <h2 style={{ lineHeight: '1.3em' }}>
            {collector.first_name} would like to buy {artwork.title}
          </h2>

          <div style={{margin: '2em 0', lineHeight: '1.4em'}}>
            {/* Transaction info */}
            {transaction.type==='InstallmentPurchase' ? (
              <p>
                <h5 style={{margin: 0}}>Payment Plan</h5>
                <span style={{fontSize: '1.2em'}}>
                  {transaction.payment_plan.num_installments} payments of {formatPrice(transaction.payment_plan.recurring_charge)}
                  <br/>
                  Charged every {transaction.payment_plan.interval} days.
                </span>
              </p>
            ):(
              <p>
                <h5 style={{margin: 0}}>Outright Purchase</h5>
                <span style={{fontSize: '1.2em'}}>
                  Sale price: {formatPrice(transaction.payment_plan.total_due)}
                </span>
              </p>
            )}

            {/* Delivery info */}
            <p>
              <h5 style={{margin: 0}}>Fulfillment Method</h5>
              <span style={{textTransform: 'capitalize', fontSize: '1.2em'}}>{transaction.fulfillment.method}</span>
            </p>

            <p>Art in Res will follow-up with next steps after you accept your purchase.</p>
          </div>
        </center>
        
        <Grid columns="1fr 1fr" columnsMobile="1fr 1fr">
          <FormBuilder
            method="post"
            endpoint={`/purchases/${transaction.id}/reject`}
            success={handleSuccess}
            submit_text="Decline"
            fit
            secondary
          />
          <FormBuilder
            method="post"
            endpoint={`/purchases/${transaction.id}/accept`}
            success={handleSuccess}
            submit_text="Accept"
            fit
          />
        </Grid>
        </>
      ):null}

    </Modal>
  )
}

export default RequestsAcceptModal