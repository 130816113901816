import React from 'react'
import * as css from 'styles'
import styled from '@emotion/styled'

import { useScript } from 'components'
import { Modal, Link, Grid, Icon } from 'components'

const ChatModal = () => {
  useScript('https://cdn.supersales.io/clients/artinres.com/widget.js')

  const teamCallId = '5b2ab765-8552-4e52-8796-e9a52c30e187'

  const startCall = () => window.SuperSales ?
    window.SuperSales.startCall({ group: teamCallId })
  : null

  return(
    <Modal name="chat">

      <center>
        <h2>Ask us anything!</h2>
        <p>Pricing, delivery, artist info, whatever&nbsp;you&nbsp;need. We're here.</p>
      </center>

      <Grid columns="1fr 1fr" columnsMobile="1fr 1fr" gap="0.5em" mobileGap="0.5em" style={{ padding: '1em 0 0 0', textAlign: 'center' }}>

        <BoxButton>
        <Link to="/chat">
          <div>
            <Icon type="chatsmall" size="30px"/>
            <h3>
              Text Chat
            </h3>
            <small>
              Text in real time. Or leave a message, and we will get&nbsp;back&nbsp;ASAP.
            </small>
          </div>
        </Link>
        </BoxButton>

        <BoxButton>
        <Link onClick={(e) => { e.preventDefault(); startCall(); }}>
          <div>
            <Icon type="video" size="30px"/>
            <h3>
              Video Chat
            </h3>
            <small>
              Our expert will appear on screen. You will just need audio enabled.
            </small>
          </div>
        </Link>
        </BoxButton>
        
      </Grid>

    </Modal>
  )
}

const BoxButton = styled('div')({
  'h3':{
    margin: '0 0 0.5em'
  },

  'small':{
    lineHeight: '1.1em'
  },

  'a':{
    textDecoration: 'none',

    '> div':{
      background: css.color.offwhite,
      borderRadius: '4px',
      height: '100%',
      padding: '1em 1em 0.75em',
      transition: css.transition.fast,
    },

    '&:hover': {
      '> div':{
        background: css.color.offwhitedark
      },
    },
  },
})

export default ChatModal
