// import { dispatch } from '@rematch/core'
import { NOT_FOUND } from 'redux-first-router'
import Cookies from 'js-cookie'

const openLoginModal = ({ getState, dispatch }) => next => action => {
  // If action is route change
  if (action.type.includes('route')) {
    // Test conditions that login should open
    const disallowedRoutes = ['messages', 'conversation', 'account']
    const isDisallowed = disallowedRoutes.some(r => action.type.includes(r))
    const isLoggedIn = Cookies.get('auth_token')
    const pathname = getState().location.pathname

    if (!isLoggedIn && isDisallowed) {
  
      // If people are directly accessing the url from the browser
      if (disallowedRoutes.some(r => pathname.includes(r))) {
        dispatch({ type: NOT_FOUND, redirect: action })

        setTimeout(() => 
          dispatch({ type: 'modals/open', payload: { name: 'login', redirect: action } })
        ,1500)

      // If people have clicked on a url from the app
      } else {
        dispatch({ type: 'modals/open', payload: { name: 'login', redirect: action } }) 
      }

      return
    }
  }

  next(action)
}

export default openLoginModal
