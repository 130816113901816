import React from 'react'
import styled from '@emotion/styled'
import * as css from '../styles'
import propStyles from 'prop-styles'

export const Scrollable = ({ 
  children, 
  gutter = '1em', 
  style,
  axis = 'x',
}) => {
  return (
    <ScrollWrapper gutter={gutter} styles={style} axis={axis}>
      {children}
    </ScrollWrapper>
  )
}

const ScrollWrapper = styled('div')(({ axis, gutter, styles })=>{
  const useGutter = {
    margin: `0 -${gutter}`, 
    padding: `0 ${gutter}`,
  }

  const useXScroll = {
    display: 'flex',
    flexWrap: 'no-wrap',
    overflowX: 'auto',
    overscrollBehavior: 'auto',
    WebkitOverflowScrolling: 'touch',
    '& > *': {
      flex: '0 0 auto',
    }
  }

  const useYScroll = {
    height: '100%',
    overflowY: 'scroll',
    overscrollBehavior: 'auto',
    WebkitOverflowScrolling: 'touch',
  }

  return {
    ...gutter ? useGutter : {},
    ...axis === 'x' ? useXScroll : {},
    ...axis === 'y' ? useYScroll : {},
    ...styles,
  }
})

// const ScrollWrapper = styled('div')({}, 
// ({ gutter }) => ({
//   display: `grid`,
//   gridGap: `calc(${gutter} / 2)`,
//   gridTemplateColumns: `10px`,
//   gridTemplateRows: `minmax(150px, 1fr)`,
//   gridAutoFlow: `column`,
//   gridAutoColumns: `calc(50% - ${gutter} * 2)`,

//   overflowX: `scroll`,
//   scrollSnapType: `x proximity`,
//   paddingBottom: `calc(.75 * ${gutter})`,
//   marginBottom: `calc(-.25 * ${gutter})`,
// }))

export default Scrollable