import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as css from 'styles'

import { Button, Modal, Grid, Link } from 'components'
import CreateAccountForm from './CreateAccountForm'

export const CreateAccountModal = () => {
  const dispatch = useDispatch()
  const modal = useSelector(store => store.modals['createAccount'])
  const isOpen = modal && modal.open

  return (
    <Modal name="createAccount">
      <h2 style={{textAlign: 'center'}}>Create a free account</h2>

      <CreateAccountForm 
        flow="modal"
        login={true} 
        confirmation={true}
        disabled={!isOpen} 
      />

      <br />

      <div style={{ borderTop: `1px dotted ${css.color.gray}`, margin: '1.5em -2em 1em', textAlign: 'center' }}>
        <div style={{ textAlign: 'center', margin: '0 auto', position: 'relative', top: '-0.625em' }}>
          <span style={{ background: css.color.white, padding: '0 10px' }}>Are you an artist?</span>
        </div>
      </div>
      
      <Grid columns="1fr" columnsMobile="1fr" gap="0.5em" mobileGap="0.5em">
        <Link to="/artist-application" onClick={() => dispatch.modal.close({ name: 'create-account' })}>
          <Button tabIndex="-1" fit blue small style={{ textAlign: 'center' }}>Learn more & apply here</Button>
        </Link>
      </Grid>
    </Modal>
  )
}

export default CreateAccountModal