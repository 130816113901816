import React from 'react'
import styled from '@emotion/styled'
import * as css from '../styles'
import propStyles from 'prop-styles'

export const Grid = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
  gridGap: '1em',
  [css.breakpoint.m_down]: {
    gridTemplateColumns: '1fr',
  }
}, ({ gap, mobileGap, columns, columnsMobile, minmax, minmaxMobile })=> {
  const cols = !isNaN(columns) ? Array(columns).fill('1fr').join(' ') : columns
  const colsMobile = !isNaN(columnsMobile) ? Array(columnsMobile).fill('1fr').join(' ') : columnsMobile
  // console.log(cols, colsMobile)
  return {
    gridGap: gap || '1em',
    gridTemplateColumns: 
      minmax ? `repeat(auto-fit, minmax(${minmax}))` :
      columns ? cols : 'repeat(auto-fit, minmax(200px, 1fr))',
    [css.breakpoint.m_down]: {
      gridGap: mobileGap || gap || '1em',
      gridTemplateColumns: 
        minmaxMobile ? `repeat(auto-fit, minmax(${minmaxMobile}))` : 
        columnsMobile ? colsMobile : '1fr'
    }
  }
}, propStyles({
  center: {
    justifyContent: 'center',
    alignItems: 'center',
  }
}))

export const MobileOnly = styled('div')({
  display: 'none',
  [css.breakpoint.m_down]: {
    display: 'block',
  }
}, propStyles({
  inline: {
    display: 'none',
    [css.breakpoint.m_down]: {
      display: 'inline-block',
    }
  }
}))

export const LargerOnly = styled('div')({
  display: 'none',
  [css.breakpoint.m_up]: {
    display: 'block',
  }
}, propStyles({
  inline: {
    display: 'none',
    [css.breakpoint.m_up]: {
      display: 'inline-block',
    }
  }
}))

export default Grid