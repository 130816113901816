import * as css from 'styles'
import styled from '@emotion/styled'
import propStyles from 'prop-styles'

const FieldWrapper = styled('div')((props) => ({
  fontFamily: css.font.main,
  display: props.inputType === "hidden" ? "none" : "inline-block",
  width: '100%',
  position: 'relative',
  margin: css.spacing(),
  marginTop: 0,
  marginLeft: 0,
}),propStyles({
  inline: {width: 'auto'}
}))

export default FieldWrapper
