import { makeVisibleData } from '../plugins/filters/filters.selectors'
import Settings from 'settings'

export const work_list = {
  name: 'work_list',
  request: {
    url: `${Settings.api}/work_lists/:id`,
    middleware: {
      // checkStore: (store) => store.lists.data.length, // check if data is already in the store
    }
  },
  state: {
    data: {
      list: {
        artworks: [],
      },
      author: {
        profile_image: null,
      },
    }, // items needs an empty array, so we override default 'data' here
  },
}
