// Fonts
// - typography, sizes, weights
// - import fonts in 1_typography.css to load concurrently

export const font = {
  // Font Types
  heading: "'Maison Neue Demi', Arial, sans-serif",
  text: "'Mrs Eaves XL', Times, serif",
  numbers: "'Maison Neue Demi', Arial, sans-serif",
  arthunt: 'Jomolhari, Times, serif',

  // Font Sizes
  xxxs: '0.6em',
  xxs: '0.7em',
  xs: '0.8em',
  s: '0.9em',
  r: '1em',
  m: '1.25em',
  l: '1.5em',
  xl: '2em',
  xxl: '3em',
  xxxl: '5em',

  // Font Weights
  xlight: 100,
  light: 200,
  regular: 400,
  bold: 600,
  xbold: 800,
}
