import React from 'react'
import { useSelector } from 'react-redux'
// import * as css from 'styles'

import { Modal, Img, Link } from 'components'
import onboarding_noni from 'images/onboarding-noni.jpg'

export const ConfirmAccountModal = () => {
  const modal = useSelector(store => store.modals['confirmAccount'])
  const isOpen = modal && modal.open

  return (
    <Modal name="confirmAccount">
      <div>
        <h2 style={{ textAlign: 'center' }}>Thanks for joining!</h2>

        {isOpen ?
          <Img key="open" src={onboarding_noni} height="180px" />
          :
          <Img key="closed" height="180px" />
        }

        <h4 style={{marginBottom: '-1em'}}>Now you can</h4>

        <ul style={{padding: '0 0 0 1.5em'}}>
          <li>Save artworks, Message artists.</li>
          <li>Create and share lists.</li>
          <li>Get personal <Link to="/recommendations" style={{textDecoration: 'underline'}}>Recommendations</Link>.</li>
          <li>Get access to <Link to="/how-it-works" style={{textDecoration: 'underline'}}>Pay over time</Link>.</li>
        </ul>

        <p>
          If you have any questions our art experts are <Link to="/chat" style={{ textDecoration: 'underline' }}>here to help</Link>.
        </p>
        
      </div>
    </Modal>
  )
}

export default ConfirmAccountModal