import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import styled from '@emotion/styled'
import * as css from 'styles'

import titleFromSlug from 'utils/titleFromSlug'
import { Grid, Button, Input, FadeIn, Link } from 'components'

const FormBuilder = ({
  endpoint = '/my-endpoint',
  submit_text = 'Submit',
  method = 'post',
  action = false,
  success = false, //(data) => console.log('success:', data),
  fields = [], // used to create inputs
  fields_data = {}, // used to override initial form data
  fetch = false, // can be 'true' 'false' or '/your-endpoint-name'
  disabled = false,
  onSubmit = false,
  onChange = () => { },
  onReset = false,
  submit_warning = false,
  fit = true,
  secondary = false,
  outlined = false,
}) => {
  const dispatch = useDispatch()
  const { error = {}, status } = useSelector(store => store.forms)
  const { register, handleSubmit, reset, getValues } = useForm()

  //console.log('error: ');
  //console.log(error);

  const handleOnChange = (e) => onChange(e, getValues())

  const handleOnSubmit = (form_data) => {
    // console.log('handleSubmit:', form_data)
    const data = onSubmit ? onSubmit(form_data) : form_data
    const submission = {
      endpoint,
      method,
      data,
      success: (data) => {
        if (success) success(data)
        if (fetch) {
          dispatch.forms.resetStatus()
        } else {
          onReset ? reset(onReset(data)) : reset()
        }
      }
    }
    // console.log(submission)
    if (action) {
      action(submission)
    } else {
      dispatch.forms.submit(submission)
    }
  }

  // Populate the form with data
  useEffect(() => {
    if (fetch) {
      dispatch.forms.submit({
        method: 'get',
        endpoint: fetch.constructor === String ? fetch : endpoint,
        success: (data) => {
          reset(data)
          dispatch.forms.resetStatus()
        }
      })
    }
    if (fields_data) {
      reset(fields_data);
    }
  }, [fetch])

  const isOpen = true
  const isDisabled = disabled || !isOpen || status === 'Pending' || status === 'Success'

  const form_button_text = submit_text
  const form_layout = fields.length ? fields : [
    // Template - each new template will be a row.
    // {
    //   title: false,
    //   fieldset: false,
    //   fields: [
    //     { type: 'text', name: '', label: '', placeholder: '', required: true },
    //   ]
    // },
  ]

  return (
    <FormWrapper>
      <form onSubmit={handleSubmit(handleOnSubmit)} onChange={handleOnChange} style={{ position: 'relative' }}>
        <fieldset disabled={isDisabled} style={{ textAlign: 'left !important' }}>

          {form_layout.length ? form_layout.map((group, i) => (
            <div key={`${group.title}-group${i}`} id={group.id}>
              {group.title && <h3 style={{ margin: '1em 0 0.5em' }}>{group.title}</h3>}

              {group.description && group.description.constructor === String ?
                <p style={{ margin: '0 0 1.5em', fontSize: '0.9em', }}>{group.description}</p>
                : group.description ? group.description : null}

              <Grid columns={group.columns || Array(group.fields.length).fill(0).map(i => '1fr').join(' ')} columnsMobile={group.columnsMobile} style={{ marginBottom: '0.5em' }} mobileGap="0.5em">
                {group.fields.length ? group.fields.map(({ label, ...field }, i) => {
                  var constructedLabel = label || titleFromSlug(field.name)
                  if (field.required) constructedLabel += ' *';
                  if (field.type === 'select') {
                    return (
                      <Input key={`${group.title}-group${i}-${constructedLabel}`} register={register} square label={constructedLabel} {...field} errors={error.errors || {}}>
                        {field.options.map((option, i) => (
                          <option key={constructedLabel + option.value} value={option.value}>{option.text}</option>
                        ))}
                      </Input>
                    )
                  } else {
                    return (
                      <div hidden={field.type === 'hidden'}>
                        <Input key={`${group.title}-group${i}-${constructedLabel}`} register={register} square label={constructedLabel} {...field} errors={error.errors || {}} />
                      </div>
                    )
                  }
                }) : null}
              </Grid>
            </div>
          )) : null}

          {/* {form_layout[0] && form_layout[0].fields.length ?  */}
          <Button
            fit={fit}
            small
            type="submit"
            status={status}
            disabled={isDisabled}
            red={submit_warning}
            secondary={secondary}
            outlined={outlined}
          >{form_button_text || 'Submit'}</Button>
          {/* : null} */}

          {error && !error.errors ?
            <div style={{ textAlign: 'center', marginTop: '0.5em', color: css.color.red }}>
              An error occured, <Link to="/chat"><span style={{ color: css.color.red }}>click here to chat</span></Link>.
            </div>
            : null}
        </fieldset>
      </form>
    </FormWrapper>
  )
}

const FormWrapper = styled(FadeIn)({
  maxWidth: '600px',
  margin: '0 auto',

  '& label': {
    textAlign: 'left !important',
  },

  '& input, & textarea': {
    textAlign: 'left !important',
  },

})

export default FormBuilder
