// import apiModel from './api'
// import filtersModel from './filters'
//
// const extensionsImport = [
//   apiModel,
//   filtersModel,
// ]

export const extendModel = (model, extensions) => extensions.reduce((model, extend) => extend(model), model)

export const extendModels = (models, extensions) => {
  return Object.keys(models).reduce((models, model) => {
    return { ...models, [model]: extendModel(models[model], extensions) }
  }, models)
}
