import React, { PureComponent } from 'react'
import settings, { meta, keys } from 'settings'
import Helmet from 'react-helmet'
import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'

import default_image from 'images/onboarding-main.jpg'
import favicon_develop from 'images/favicon-development.png'
import favicon_staging from 'images/favicon-staging.png'

export class Meta extends PureComponent {
  componentDidMount() {
    // Initialize trackers
    !settings.origin.includes('localhost') && ReactGA.pageview(window.location.pathname + window.location.search)
    ReactPixel.pageView()
    this.props.pixel && ReactPixel.track(this.props.pixel)
  }

  render() {
    const { 
      defaults, 
      title, 
      description = meta.description,
      type = 'website',
      url = 'https://artinres.com',
      image = default_image, 
      children,
      useTemplate = true,
    } = this.props
    return (
      <Helmet>
        {/* Primary meta tags */}
        {useTemplate && defaults && <title>{meta.title} - {meta.description}</title>}
        {useTemplate && title && <title>{title} {meta.separator} {meta.title}</title>}
        {!useTemplate && <title>{title}</title>}
        {children}

        {settings.env === 'development' && <link rel="icon" href={favicon_develop} />}
        {settings.env === 'staging' && <link rel="icon" href={favicon_staging} />}

        {/* Cannonical Link */}
        <link rel="cannonical" href={document.location.href}/>

        {/* Open Graph / Facebook */}
        <meta property="og:type" content={type}/>
        <meta property="og:url" content={url}/>
        <meta property="og:title" content={title}/>
        <meta property="og:description" content={description}/>
        <meta property="og:image" content={image}/>

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content={url}/>
        <meta property="twitter:title" content={title}/>
        <meta property="twitter:description" content={description}/>
        <meta property="twitter:image" content={image}/>

        {/* Prerendering */}
        { this.props.fragment && <meta name="fragment" content={this.props.fragment} />}
      </Helmet>
    )
  }
}

export default Meta
