import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import * as css from 'styles'

import { Button, Input, Modal, Spinner, FadeIn, Grid, Link } from 'components'

export const CreateListModal = () => {
  const dispatch = useDispatch()
  const { data, error, status } = useSelector(store => store.forms)
  const { register, handleSubmit, watch, errors, reset } = useForm()
  const onSubmit = data => { 
    const submission = {
      endpoint: '/lists',
      data,
      success: (data) => {
        dispatch.lists.add(data.list)
        dispatch.forms.reset()
        reset()
        if (modal.data && modal.data.artwork_id) {
          dispatch.modals.open({ name: 'save-to-list', data: modal.data })
        } else {
          dispatch.modals.confirm({ name: 'create-list' })
        }
      }
    }
    console.log(submission)
    dispatch.forms.submit(submission)
  }

  const modal = useSelector(store => store.modals['create-list'])
  const isOpen = modal && modal.open
  const isDisabled = !isOpen || status === 'Pending' || status === 'Success'
  const preset_errors = (status==="Error") ? { 'name': 'Please enter a list title.' } : {}

  return (
    <Modal name="create-list">
      <center style={{color: css.color.red }}>{preset_errors.name}</center>
      <form onSubmit={handleSubmit(onSubmit)} style={{ position: 'relative' }}>
        <h2 style={{textAlign: 'center'}}>Create a new list</h2>
        <fieldset disabled={isDisabled} style={{textAlign: 'left !important'}}>
          <Input register={register} square name="name" type="text" placeholder="List Title" />
          <Input register={register} square name="description" type="textarea" placeholder="Description" />
          <Button small fit type="submit" status={status} disabled={isDisabled}>Create List</Button>
        </fieldset>
      </form>
    </Modal>
  )
}

export default CreateListModal