import React from 'react'

import TemporaryNotice from './TemporaryNotice'
import SaveYourLikes from './SaveYourLikes'
import HowItWorks from './HowItWorks'
import FreeShipping from './FreeShipping'
import ArtistDrop from './ArtistDrop'
import Covid19 from './Covid19'
import BlackLivesMatter from './BlackLivesMatter'
import BlackFriday from './BlackFriday'

const Notices = () => (
  <>
    <TemporaryNotice/>
    <SaveYourLikes/>
    {/* <BlackFriday /> */}
    {/* <BlackLivesMatter/> */}
    {/* <FreeShipping/> */}
    {/* <ArtistDrop/> */}
    {/* <HowItWorks/> */}
    {/* <Covid19/> */}
  </>
)

export default Notices
