import React from 'react';
import { default as InternalLink, NavLink } from 'redux-first-router-link';
import { useSelector, useDispatch } from 'react-redux';
import { getState, dispatch } from 'redux/store';
import { redirect, pathToAction } from 'redux-first-router';
import queryString from 'querystringify';
import settings from 'settings';

import * as css from 'styles';

// Abstracts Link, in case library is changed in the future. Only need to update this file.
// Projects can import Link from the usual /components/ folder.
// This component can be used as <Link> or <InternalLink nav>

export const navigate = (url) => {
  // console.log(url)
  const { location } = getState();
  dispatch(pathToAction(url, location.routesMap, queryString));
};

export const Link = ({
  nav,
  children,
  external,
  preventDefault,
  underline,
  exact = true,
  disabled = false,
  ...props
}) => {
  // console.log(props.to, 'artists:', props.artists, 'external:', external)
  const is_guest = useSelector((store) => store.current_user.data.user.is_guest);
  const linkActions = {
    '/create-account': () => (is_guest ? dispatch.modals.open({ name: 'createAccount' }) : navigate('/')),
    '/login': () => (is_guest ? dispatch.modals.open({ name: 'login' }) : navigate('/')),
    '/logout': () => dispatch.auth.logout(),
    '/chat': () => {
      window.$crisp.push(['do', 'chat:show']);
      window.$crisp.push(['do', 'chat:open']);
    },
  };

  const makeLocalExternalUrl = (url) =>
    props.artists
      ? settings.artists_path + url
      : window.location.href.includes('3001') && !props.to.includes('http') && !props.to.match(/^mailto\:/)
      ? 'http://lvh.me:3000' + url
      : url;

  const setScroll = () => dispatch.backurl.update({ position: window.scrollY });

  const onClickInternal = (e) => {
    if (Object.keys(linkActions).includes(props.to)) {
      e.preventDefault();
      // console.log('Open modal:', props.to)
      linkActions[props.to]();
    } else if (props.onClick) {
      props.onClick(e);
    }
    props.setScroll && setScroll();
  };

  const style = {
    ...hoverStyle,
    ...(underline ? underlineStyle : {}),
  };

  if (disabled) {
    return <>{children}</>;
  }

  if (external) {
    return (
      <a href={makeLocalExternalUrl(props.to)} {...props} style={style}>
        {children}
      </a>
    );
  } else if (nav) {
    return (
      <NavLink
        style={style}
        {...props}
        to={props.to || ''}
        activeStyle={props.activeStyle}
        exact={exact}
        shouldDispatch={!preventDefault}
        onClick={onClickInternal}
      >
        {children}
      </NavLink>
    );
  } else {
    return (
      <InternalLink
        style={style}
        {...props}
        to={props.to || ''}
        shouldDispatch={!preventDefault}
        onClick={onClickInternal}
      >
        {children}
      </InternalLink>
    );
  }
};

const hoverStyle = {
  '&:hover': {
    textDecoration: 'underline',
  },
};

const underlineStyle = {
  textDecoration: 'underline',
};

export default Link;
