import Settings from 'settings'
import { dispatch } from 'redux/store'
import { navigate } from 'components'

export const work = {
  name: 'work',
  request: {
    url: `${Settings.api}/works/:id`,
  },
  state: {
    data: {
      artwork: {
        // main_image: {},
      }
    },
  },
  reducers: {
    update: (state, payload) => ({ ...state, ...payload })
  },
  effects: {
    delete: ({ id }, store) => dispatch({
      type: 'likes/fetch',
      payload: {
        url: `${Settings.api}/works/${id}`,
        method: 'delete',
        success: (data) => {
          dispatch.modals.confirm({ name: 'delete-artwork' })
          navigate('/works')
        },
      }
    })
  }
}
