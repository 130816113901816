import * as css from 'styles';
import styled from '@emotion/styled';
import propStyles from 'prop-styles';
import posed from 'react-pose';
import { tween, easing } from 'popmotion';

// window.easing = easing

export const Overlay = styled('div')({
  position: 'fixed',
  zIndex: css.z.modal + 1,

  margin: '0 auto',
  maxWidth: '400px',
  width: '100%',
  minHeight: '300px', // make these the same value - for loading to appear in center

  padding: `${css.space.xl}`,
  borderRadius: css.border.radius,
  background: '#fff !important',

}, propStyles({
  closed: {
    opacity: 0,
    transform: 'translateY(30px)',
  },
  autoheight: {
    minHeight: 'auto',
    height: 'auto',
  },
  noPadding: {
    padding: '0 !important',
    overflow: 'hidden',
  }
}))

const open = {
  opacity: 1,
  y: 0,
  // transform: 'translateY(0px)',
  // pointerEvents: 'default',
  // userSelect: 'default',
}

const closed = {
  opacity: 0,
  y: 50,
  // transform: 'translateY(30px)',
  // pointerEvents: 'none',
  // userSelect: 'none',
}

const confirmed = {
  opacity: 0,
  y: -50,
  // transform: 'translateY(-30px)',
  // pointerEvents: 'none',
  // userSelect: 'none',
}

const transition = (props) => tween({
  ...props,
  ease: easing.easeOut,
  // ease: easing.reversed(easing.cubicBezier(.81, .07, .44, .99)),
  duration: 300,
})

export default posed(Overlay)({
  initialPose: 'closed',
  open: { ...open, transition },
  closed: { ...closed, transition, duration: 150, },
  confirmed: { ...confirmed, transition, duration: 150, },
})
