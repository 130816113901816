import Settings from 'settings'

export const locale = {
  name: 'locale',
  request: {
    url: `${Settings.api}/users`,
    middleware: {
      //checkStore: (store) => store.artists.data.length, // check if data is already in the store
    }
  },
  state: {
    data: {
      locale: {}, 
      artists: [],
    }, // items needs an empty array, so we override default 'data' here
  },
}
