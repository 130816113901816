import { breakpoint } from './breakpoints'
// Theme variables
// - anything specific to the theme,
// - that is not generic to all CSS.

export const margins = {
  page_end: '2em',
  page_side: '2em',
  page: '2em 2em',

  page_mobile_end: '1em',
  page_mobile_side: '1em',
  page_mobile: '1em 1em',
}

export const heights = {
  header: 70, // example - header height
  header_s: 50, // example - header height for s_down breakpoint
}

export const widths = {
  page: '1000px',
  outer: {
    margin: '0 auto',
    maxWidth: '1500px',
    padding: `0 ${margins.page_side}`,
    [breakpoint.m_down]: {
      padding: `0 ${margins.page_mobile_side}`,
    },
  },
  content: {
    margin: '0 auto',
    maxWidth: '1000px',
    // padding: margins.page,
    padding: `0 ${margins.page_side}`,
    [breakpoint.m_down]: {
      padding: `0 ${margins.page_mobile_side}`,
    },
  },
}

export const shadows = {
  artwork: 'drop-shadow(5px 5px 7px rgba(134, 134, 134, 0.7))',
}