import { NOT_FOUND } from 'redux-first-router'
// import { dispatch } from './store' // circular import - can't require dispatch before routes are importedi
import Cookies from 'js-cookie'
import { breakpoints } from 'styles'

// 1. Action type <-> url path.
// 2. :id is dynamic route parameter
// 3. Possible to add thunks to send another action on route loading.
// 4. Weird behaviour - rematch dispatch functions need to be awaited before called.
// 5. When getting data based on route - containers used on other routes will not show data.

export const routesMap =  {
  [NOT_FOUND]: '/not-found',

  // Index pages
  'route/works': { 
    path: '/works',
    thunk: (dispatch, getState) => {
      dispatch({ type: 'works/reset' })
      dispatch({ type: 'works/fetch', payload: { search: 'limit=400&' + getState().location.search } })
      dispatch({ type: 'work_lists/fetch' })
    }
  },
  'route/works-new':{
    path: '/works/new',
    thunk: (dispatch, getState) => {
      dispatch({ type: 'work/reset' })
      dispatch({ type: 'work/fetch', payload: { id: 'new' } })
      // dispatch({ type: 'forms/reset' })
      // dispatch({ type: 'forms/submit', payload: { method: 'get', endpoint: `/works/new` } })
    }
  },
  'route/works-edit':{
    path: '/works/:id',
    thunk: (dispatch, getState) => {
      dispatch({ type: 'work/reset' })
      dispatch({ type: 'work/fetch', payload: { id: getState().location.payload.id } })
      // dispatch({ type: 'forms/reset' })
      // dispatch({ type: 'forms/submit', payload: { method: 'get', endpoint: `/works/${getState().location.payload.id}`} })
    }
  },

  'route/work-lists':{
    path: '/work-lists',
    thunk: (dispatch, getState) => {
      dispatch({ type: 'work_lists/reset' })
      dispatch({ type: 'work_lists/fetch' })
    }
  },

  'route/work-lists-new':{
    path: '/work-lists/new',
    thunk: (dispatch, getState) => {
      dispatch({ type: 'work_list/reset' })
      // dispatch({ type: 'list/fetch', payload: { id: 'new' } })
      // dispatch({ type: 'forms/reset' })
      // dispatch({ type: 'forms/submit', payload: { method: 'get', endpoint: `/works/new` } })
    }
  },
  'route/work-lists-edit':{
    path: '/work-lists/:id',
    thunk: (dispatch, getState) => {
      dispatch({ type: 'work_list/reset' })
      dispatch({ type: 'work_list/fetch', payload: { id: getState().location.payload.id } })
      // dispatch({ type: 'forms/reset' })
      // dispatch({ type: 'forms/submit', payload: { method: 'get', endpoint: `/works/${getState().location.payload.id}`} })
    }
  },

  'route/home': {
    path: '/',
    thunk: (dispatch, getState) => {
      dispatch({ type: 'artworks/reset' })
      dispatch({ type: 'artworks/fetch', payload: { search: 'limit=8&' + getState().location.search } })

      dispatch({ type: 'artists/reset' })
      dispatch({ type: 'artists/fetch', payload: { search: 'limit=3&' + getState().location.search } })

      dispatch({ type: 'articles/reset' })
      dispatch({ type: 'articles/fetch', payload: { search: 'limit=2&' + getState().location.search } })

      dispatch({ type: 'list/reset' })
      dispatch({ type: 'list/fetch', payload: { search: 'limit=8', id: 'art-in-res-staff-our-favorite-new-artworks' } })
      
      dispatch({ type: 'backurl/update', payload: { type: getState().location.search ? 'Search' : 'Collection', url: '/artworks?' + getState().location.search } })
    }
  },


  'route/artworks': { 
    path: '/artworks',
    thunk: (dispatch, getState) => {
      dispatch({ type: 'artworks/reset' })
      dispatch({ type: 'artworks/fetch', payload: { search: getState().location.search } })
      dispatch({ type: 'backurl/update', payload: { type: getState().location.search ? 'Search' : 'Collection', url: '/artworks?' + getState().location.search} })
    }
  },

  'route/likes': { 
    path: '/likes',
    thunk: (dispatch, getState) => {
      dispatch({ type: 'artworks/reset' })
      dispatch({ type: 'artworks/fetch', payload: { search: 'list=likes&' + getState().location.search } })
      dispatch({ type: 'backurl/update', payload: {type: 'Likes', url: '/likes?' + getState().location.search} })
    }
  },

  'route/history': { 
    path: '/history',
    thunk: (dispatch, getState) => {
      dispatch({ type: 'artworks/reset' })
      dispatch({ type: 'artworks/fetch', payload: { search: 'list=history&' + getState().location.search } })
      dispatch({ type: 'backurl/update', payload: {type: 'History', url: '/history?' + getState().location.search} })
    }
  },

  'route/recommendations': {
    path: '/recommendations',
    thunk: (dispatch, getState) => {
      dispatch({ type: 'artworks/reset' })
      dispatch({ type: 'recommendations/reset' })
      dispatch({ type: 'recommendations/wizard' })
    }
  },

  'route/recommendations-results': {
    path: '/recommendations/results',
    thunk: (dispatch, getState) => {
      // dispatch({ type: 'artworks/reset' })
      // dispatch({ type: 'artworks/fetch', payload: { search: 'list=recommendations&' + getState().location.search } })
      dispatch({ type: 'recommendations/reset' })
      dispatch({ type: 'recommendations/results', payload: { search: getState().location.search } })
    }
  },

  'route/articles': {
    path: '/articles',
    thunk: (dispatch, getState) => {
      dispatch({ type: 'articles/reset' })
      dispatch({ type: 'articles/fetch', payload: { search: 'limit=10&' + getState().location.search } })
    }
  },

  'route/articles-guides': {
    path: '/guides',
    thunk: (dispatch, getState) => {
      dispatch({ type: 'articles/reset' })
      dispatch({ type: 'articles/fetch', payload: { search: 'limit=10&category=guides&' + getState().location.search } })
    }
  },

  'route/articles-curations': {
    path: '/curations',
    thunk: (dispatch, getState) => {
      dispatch({ type: 'articles/reset' })
      dispatch({ type: 'articles/fetch', payload: { search: 'limit=10&category=weekly-picks&' + getState().location.search } })
    }
  },

  'route/articles-inside-the-studio': {
    path: '/inside-the-studio',
    thunk: (dispatch, getState) => {
      dispatch({ type: 'articles/reset' })
      dispatch({ type: 'articles/fetch', payload: { search: 'limit=10&category=inside-the-studio&' + getState().location.search } })
    }
  },

  // Single pages 
  'route/article': {
    path: '/articles/:id',
    thunk: (dispatch, getState) => {
      dispatch({ type: 'article/reset' })
      dispatch({ type: 'article/fetch', payload: { id: getState().location.payload.id } })
    }
  },

  'route/artwork': {
    path: '/artworks/:id',
    thunk: (dispatch, getState) => {
      dispatch({ type: 'artwork/reset' })
      dispatch({ type: 'artwork/fetch', payload: { id: getState().location.payload.id } })
    }
  },

  'route/artist-profile': {
    path: '/artists/:id',
    thunk: (dispatch, getState) => {
      dispatch({ type: 'profile/reset' })
      dispatch({ type: 'profile/fetch', payload: { id: getState().location.payload.id } })
      dispatch({ type: 'backurl/update', payload: {type: 'Artist', url: '/artists/' + getState().location.payload.id} })
    }
  },

  'route/artists': {
    path: '/artists',
    thunk: (dispatch, getState) => {
      dispatch({ type: 'backurl/update', payload: { type: 'Artists', url: '/artists' } })
      // dispatch({ type: 'artists/reset' })
      dispatch({ type: 'artists/fetch', payload: { search: getState().location.search } })
    }
  },
  
  'route/artists-list': {
    path: '/artists-list',
    thunk: (dispatch, getState) => {
      dispatch({ type: 'backurl/update', payload: { type: 'Artists', url: '/artists' } })
      dispatch({ type: 'artists/fetch', payload: { search: getState().location.search } })
    }
  },
  
  'route/locale': {
    path: '/city/:id',
    thunk: (dispatch, getState) => {
      let idBebe = getState().location.payload.id;
      let queryBebe = 'locale='+idBebe;

      // JF: 
      // This is obviously redundant -- I couldn't figure out how to
      // get it to work without doing it this way.
      dispatch({ type: 'locale/reset' })
      dispatch({ type: 'artists/reset' })
      dispatch({ type: 'locale/fetch',  payload: { search: queryBebe } })
      dispatch({ type: 'artists/fetch', payload: { search: queryBebe } })
    }
  },

  'route/following': {
    path: '/following',
    thunk: (dispatch, getState) => {
      dispatch({ type: 'backurl/update', payload: { type: 'ArtistsFollowing', url: '/following' } })
      dispatch({ type: 'followed_artists/fetch' })
    }
  },

  'route/login': {
    path: '/login',
    thunk: (dispatch, getState) => {
      dispatch({ type: 'modals/open', payload: { name: 'login' } })
    }
  },

  'route/logout': {
    path: '/logout',
    thunk: (dispatch, getState) => {
      dispatch({ type: 'auth/logout' })
    }
  },

  'route/intro': {
    path: '/intro/:id',
    thunk: (dispatch, getState) => {
      // if (Cookies.get('auth_token') && Cookies.get('haspass')) dispatch({ type: 'route/artworks' }))
    }
  },

  'route/welcome': {
    path: '/welcome/:id',
    thunk: (dispatch, getState) => {
      const location = getState().location
      const auth_token = location.query ? location.query.code : false

      if (location.payload.id == "1") { // eslint-disable-line 
        const settings = { domain: `.${window.location.hostname}` }
        Cookies.set('auth_token', auth_token, settings)
        dispatch({ type: 'auth/checkToken', payload: { token: auth_token } })
      }
    }
  },

  'route/personalized':{
    path: '/hello',
    thunk: (dispatch, getState) => {
      dispatch({ type: 'artworks/reset' })
      dispatch({ type: 'artworks/fetch' })
    }
  },

  'route/personal-intro':{
    path: '/hello/:id',
    thunk: (dispatch, getState) => {
    }
  },


  // 'route/rentals':{
  //   path: '/rentals',
  //   thunk: (dispatch, getState) => {
  //     dispatch({ type: 'rentals/reset' })
  //     dispatch({ type: 'rentals/fetch' })
  //   }
  // },

  // 'route/rental':{
  //   path: '/rentals/:id',
  //   thunk: (dispatch, getState) => {
  //     dispatch({ type: 'rental/reset' })
  //     dispatch({ type: 'rental/fetch', payload: { id: getState().location.payload.id } })
  //   }
  // },

  // 'route/purchases': {
  //   path: '/purchases',
  //   thunk: (dispatch, getState) => {
  //     dispatch({ type: 'purchases/reset' })
  //     dispatch({ type: 'purchases/fetch' })
  //   }
  // },
 
  // 'route/purchase':{
  //   path: '/purchases/:id',
  //   thunk: (dispatch, getState) => {
  //     dispatch({ type: 'purchase/reset' })
  //     dispatch({ type: 'purchase/fetch', payload: { id: getState().location.payload.id } })
  //   }
  // },

  
  'route/payment-plans': {
    path: '/payment-plans',
    thunk: (dispatch, getState) => {
      dispatch({ type: 'payment_plans/reset' })
      // dispatch({ type: 'purchases/fetch' })
      dispatch({ type: 'payment_plans/fetch', payload: { success: (data) => {
        // const existingPurchases = [...getState().payment_plans.data.collector_transactions, ...getState().payment_plans.data.artist_transactions]
        // const loadedPurchases = [...data.collector_transactions, ...data.artist_transactions]
        // if (window.innerWidth > breakpoints.m && !existingPurchases.length) {
        //   console.log('hello')
        //   if (loadedPurchases[0] && loadedPurchases[0].id) {
        //     dispatch({ type: 'route/payment_plan', payload: { id: loadedPurchases[0].id } })
        //   }
        // }
      }} })
    }
  },

  'route/payment-plan': {
    path: '/payment-plans/:id',
    thunk: (dispatch, getState) => {
      dispatch({ type: 'payment_plan/reset' })
      dispatch({ type: 'payment_plan/fetch', payload: { id: getState().location.payload.id } })
      dispatch({ type: 'payment_plans/reload' })
    }
  },

  'route/sales': {
    path: '/sales',
    thunk: (dispatch, getState) => {
      dispatch({ type: 'payment_plans/reset' })
      dispatch({ type: 'payment_plans/fetch', payload: { search: 'type=sales' }})
    }
  },

  'route/sale': {
    path: '/sales/:id',
    thunk: (dispatch, getState) => {
      dispatch({ type: 'payment_plan/reset' })
      dispatch({ type: 'payment_plan/fetch', payload: { id: getState().location.payload.id, 
        success: (data) => {
          if (data.acceptance_state === 'initial') dispatch({ type: 'modals/open', payload: { name: 'purchase-requests-accept' } })
        }
      } })
      dispatch({ type: 'payment_plans/reload', payload: { search: 'type=sales' }})
    }
  },

  'route/purchases': {
    path: '/purchases',
    thunk: (dispatch, getState) => {
      dispatch({ type: 'payment_plans/reset' })
      dispatch({ type: 'payment_plans/fetch', payload: { search: 'type=purchases' }})
    }
  },

  'route/purchase': {
    path: '/purchases/:id',
    thunk: (dispatch, getState) => {
      dispatch({ type: 'payment_plan/reset' })
      dispatch({ type: 'payment_plan/fetch', payload: { id: getState().location.payload.id } })
      dispatch({ type: 'payment_plans/reload', payload: { search: 'type=purchases' }})
    }
  },


  // Messages
  'route/messages': {
    path: '/messages',
    thunk: (dispatch, getState) => {
      dispatch({ type: 'conversations/reset' })
      dispatch({ type: 'conversations/fetch', payload: { success: (data) => {
        if (window.innerWidth > breakpoints.m && !getState().conversation.data.interactions.length) {
          if (data.conversations[0] && data.conversations[0].user.id) {
            dispatch({ type: 'route/conversation', payload: { id: data.conversations[0].user.slug || data.conversations[0].user.id } })
          }
        }
      }} })
    }
  },

  'route/old-conversation': {
    path: '/messages/conversations/:id',
    thunk: (dispatch, getState) => {
      dispatch({ type: 'route/conversation', payload: { id: getState().location.payload.id } })
    }
  },

  'route/conversation': {
    path: '/messages/:id',
    thunk: (dispatch, getState) => {
      dispatch({ type: 'conversation/reset' })
      dispatch({ type: 'conversation/fetch', payload: { id: getState().location.payload.id } })
      dispatch({ type: 'conversations/reload' })
    }
  },

  'route/fans': {
    path: '/fans',
    thunk: (dispatch, getState) => {
      dispatch({ type: 'fans/reset' })
      dispatch({ type: 'fans/fetch' })
    }
  },

  'route/lists': {
    path: '/lists',
    thunk: (dispatch, getState) => {
      dispatch({ type: 'lists/reset' })
      dispatch({ type: 'lists/fetch' })
      dispatch({ type: 'saves/fetch' })
    }
  },

  'route/list': {
    path: '/lists/:id',
    thunk: (dispatch, getState) => {
      dispatch({ type: 'list/reset' })
      dispatch({ type: 'list/fetch', payload: { id: getState().location.payload.id } })
      dispatch({ type: 'saves/fetch' })
    }
  },

  'route/fan-conversation': {
    path: '/fans/:id',
    thunk: (dispatch, getState) => {
      dispatch({ type: 'conversation/reset' })
      dispatch({ type: 'conversation/fetch', payload: { id: getState().location.payload.id } })
      dispatch({ type: 'conversations/reload' })
    }
  },

  // Transactions
  'route/cart': {
    path: '/cart',
    thunk: (dispatch, getState) => {
      dispatch({ type: 'cart/fetch', payload: {
        success: (data) => dispatch({ type: 'checkout/addCartData', payload: data })
      } })
      // dispatch({ type: 'artworks/fetch', payload: { search: 'limit=2&' + getState().location.search } })
    }
  },
  'route/checkout': '/checkout',
  'route/checkout-shipping': '/checkout/shipping',
  'route/checkout-payment': '/checkout/payment',
  'route/checkout-review': '/checkout/review',
  'route/checkout-confirmation': '/checkout/confirmation',

  'route/order':{
    path: '/orders/:id',
    thunk: (dispatch, getState) => {
      dispatch({ type: 'order/reset' })
      dispatch({ type: 'order/fetch', payload: { id: getState().location.payload.id } })
    }
  },

  'route/invites':{
    path: '/invites',
    thunk: (dispatch, getState) => {
      dispatch({ type: 'forms/reset' })
    }
  },

  'route/account':{
    path: '/account',
    thunk: (dispatch, getState) => {
      dispatch({ type: 'account/reset' })
      dispatch({ type: 'account/fetch' })
    }
  },
  'route/account-profile': '/account/profile',
  'route/account-billing': '/account/billing',
  'route/account-shipping': '/account/shipping',
  'route/account-transactions': '/account/transactions',
  'route/account-transfer': '/account/transfer',
  'route/account-settings': '/account/settings',
  'route/account-invites': '/account/invites',
  'route/account-gift-cards': '/account/gift-cards',
  'route/account-notifications': '/account/notifications',

  // Invites
  'route/invite': '/invite',
  'route/redeem-invite': {
    path: '/i/:id',
    thunk: (dispatch, getState) => {
      dispatch({ type: 'forms/fetch', payload:{
        endpoint: `/email_invites/${getState().location.payload.id}`,
        success: () => dispatch({ type: 'forms/resetStatus' }),
      }})
    }
  },

  // Unsorted
  'route/search': '/search',
  'route/request': '/request',
  'route/roadmap': '/roadmap',
  'route/discover': '/discover',
  'route/artist-application': '/artist-application',
  'route/artist-application-form': '/artist-application-form',
  'route/start-your-own-art-business': '/start-your-own-art-business',
  'route/how-it-works': '/how-it-works',
  'route/faq': '/faq',
  'route/gift-cards': '/gift-cards',
  'route/test': '/test',
  
  'route/animations': '/animations',
  'route/arthunter': '/arthunter',
}
