import React, { useState, useEffect } from 'react';
import * as css from 'styles'
import styled from '@emotion/styled';

const ProgressBarWrapper = styled('div')(({ background }) => ({
  margin: '0.25em 0',
  borderRadius: '4px',
  height: '8px',
  background: background || css.color.white,
  overflow: 'hidden',
  position: 'relative',
  '& .progress-bar-fill': {
    height: '100%',
    width: '0%',
    background: css.color.black,
    transition: css.transition.xslow,
  },
  '& .progress-bar-line': {
    height: '100%',
    width: '2px',
    background: css.color.offwhitedark,
    position: 'absolute',
  },
}))

export const ProgressBar = ({ 
  percent = 0, 
  trackColor = css.color.white, 
  height = '8px',
  color = css.color.black,
  status = 'Success',
  steps = 0,
  stepGap = '2px',
  animated = false,
}) => {

  const [value, setValue] = useState(animated ? 0 : percent)

  useEffect(()=>{
    if (animated) {
      setTimeout(()=>{
        setValue(percent)
      },500)
    }
  },[])

  return (
    <ProgressBarWrapper background={trackColor} style={{height}}>
      {steps > 1 && status === 'Success' ? Array(steps).fill(0).map((s,i)=>
        <div key={i} className="progress-bar-line" style={{marginLeft: `calc(${(100/steps) * i}% - ${stepGap})`, width: stepGap}} />
      ):null}
      <div className="progress-bar-fill" style={{ width: `${status==='Success' ? parseFloat(value) : 0}%`, background: color }} />
    </ProgressBarWrapper>
  )
}

export default ProgressBar