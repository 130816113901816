import React, { PureComponent, useRef, useEffect, useState, useCallback } from 'react'
import * as css from '../styles'
import styled from '@emotion/styled'
import propStyles from 'prop-styles'
import throttle from 'utils/throttle'
import Carousel from 're-carousel'
import IndicatorDots from '../components/IndicatorDots'

import { LargerOnly, MobileOnly, IconButton } from 'components'
import Loading from '../components/Loading/Loading'
import { Waypoint } from 'react-waypoint'

// import { useSpring, animated } from 'react-spring'
// import { useDrag, useMove } from 'react-use-gesture'

const ImgWrapper = styled('div')({
  width: '100%',
  // height: 'auto !important',
  position: 'relative',
  overflow: 'hidden',
  backgroundColor: css.color.xlightgray,
  transition: css.transition.norm,
}, ({ styles }) => ({
  ...styles,
}), propStyles({
  square: {
    paddingTop: '100%',
    height: '0 !important',
  },
  circle: {
    paddingTop: '100%',
    height: '0 !important',
    borderRadius: '50%',
    overflow: 'hidden',
  }
}))

const absolute = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
}

const ImgStyle = styled('img')({
  width: '100%',
  maxWidth: '100%',
  height: 'auto',
  display: 'block',
  ...absolute,
  opacity: 0,
  transition: `opacity ${css.speed[2]}ms linear`,
}, ({ styles })=>({
  ...styles,
}), propStyles({
  loaded: {
    opacity: 1,
  },
}))

export class Img extends PureComponent {
  state = {
    status: 'Pending',
    inView: false,
  }

  loadSrc = () => {
    let downloadingImage = new Image()
    downloadingImage.onload = () =>  this.setState({status: 'Success'})
    downloadingImage.onerror = () => this.setState({status: 'Error'})
    downloadingImage.src = this.props.src;
    return downloadingImage
  }

  onEnter = () => {
    if (!this.state.loaded && !this.state.failed) {
      this.timer = setTimeout(()=>{
        this.props.src && this.loadSrc()
      }, this.props.timer || 350)
    }
  }

  onLeave = () => {
    this.timer && clearTimeout(this.timer)
  }

  tryAgain = () => {
    if (this.props.retry && this.state.failed) {
      this.setState({status: 'Pending'}) // here not in loadSrc due to onEnter trigger
      this.loadSrc()
    }
  }

  render2() {
    return (
      <ImgStyle
        src={this.props.src}
        width={this.props.width}
        height={this.props.height}
      />
    )
  }

  render() {
    // const ratio = this.props.width ? (this.props.height / this.props.width) * 100 : null;
    const wrapperStyle = {
      background: this.props.background || this.props.style?.background,
      height: this.props.height || this.props.style?.height,
      minHeight: this.props.minHeight || this.props.style?.minHeight,
      maxHeight: this.props.minHeight || this.props.style?.maxHeight,
      [css.breakpoint.m_down]: {
        height: this.props.style && this.props.style[css.breakpoint.m_down] && this.props.style[css.breakpoint.m_down].height,
        minHeight: this.props.style && this.props.style[css.breakpoint.m_down] && this.props.style[css.breakpoint.m_down].minHeight,
        maxHeight: this.props.style && this.props.style[css.breakpoint.m_down] && this.props.style[css.breakpoint.m_down].maxHeight,
      }
    }

    return (
      <Waypoint 
        onEnter={this.onEnter} 
        onLeave={this.onLeave}
        topOffset="-200%"
        bottomOffset="-200%"
      >
      <ImgWrapper
          onClick={this.tryAgain}
          transparent={this.props.transparent}
          square={this.props.square}
          circle={this.props.circle}
          styles={wrapperStyle}
        >
          <Loading
            medium
            status={this.state.status}
            errormessage={this.props.errormessage || "Image not found"}
            autoheight
            nospinner={this.props.nospinner}
            style={absolute}
          >
            <LargerOnly>
              <ImgStyle
                src={this.props.src}
                width={this.props.width}
                height={this.props.height}
                loaded={this.state.status === 'Success'}
                styles={{
                  ...this.props.style,
                  background: 'transparent',
                }}
              />
              {this.props.magnify &&
                <MagnifyImage
                  props={this.props}
                  state={this.state}
                />
              }
            </LargerOnly>
            
            <MobileOnly style={{...absolute}}>
              {this.props.carousel ? (
              <Carousel 
                // loop
                widgets={[IndicatorDots]}>
                <ImgStyle
                  src={this.props.src}
                  width={this.props.width}
                  height={this.props.height}
                  loaded={this.state.status === 'Success'}
                  styles={{
                    ...this.props.style,
                    position: 'relative',
                    background: 'transparent',
                  }}
                />
                <div
                  style={{
                    // ...absolute,
                    backgroundColor: css.color.offwhitedark,
                    backgroundImage: `url(${this.props.src})`,
                    backgroundRepeat: 'no-repeat',
                    width: '100%',
                    height: '100%',
                    // opacity: opacity,
                    transition: 'opacity 400ms ease, background-position 100ms ease',
                    backgroundPositionX: this.props.position_x || '25%',
                    backgroundPositionY: this.props.position_y || '25%',
                    backgroundSize: '250%',
                  }}
                />
                <div
                  style={{
                    // ...absolute,
                    backgroundColor: css.color.offwhitedark,
                    backgroundImage: `url(${this.props.src})`,
                    backgroundRepeat: 'no-repeat',
                    width: '100%',
                    height: '100%',
                    // opacity: opacity,
                    transition: 'opacity 400ms ease, background-position 100ms ease',
                    backgroundPositionX: this.props.position_x || '80%',
                    backgroundPositionY: this.props.position_y || '80%',
                    backgroundSize: '300%',
                  }}
                />
              </Carousel>
              ) : (
                <ImgStyle
                  src={this.props.src}
                  width={this.props.width}
                  height={this.props.height}
                  loaded={this.state.status === 'Success'}
                  styles={{
                    ...this.props.style,
                    background: 'transparent',
                  }}
                />
              )} 
            </MobileOnly>
           
          </Loading>
      </ImgWrapper>
      </Waypoint>
    )
  }
}

const MagnifyImage = ({ props, state }) => {
  const image = props.artwork || props
  const [position, setPosition] = useState({x: '50%', y: '50%', size: '200% 200%'})
  const [opacity, setOpacity] = useState(0)
  const [timer, setTimer] = useState(null)
  const useHover = props.useHover || false

  // const throttled = useRef(throttle((e) => handleMouseMove(e)), 100)
  // // const throttleMove = useCallback(throttle((e) => handleMouseMove(e)), 100).current
  // const throttler = e => throttled(e)
  // useEffect(() => throttled.current(position), [position])

  function handleClick(e) {
    !useHover && setOpacity(opacity===0 ? 1 : 0)
  }

  function handleMouseEnter(e) {
    // console.log(e.nativeEvent.offsetX)
    // handleMouseMove(e)
    useHover && setTimer(setTimeout(() => setOpacity(1), 200))
  }

  function handleMouseLeave() {
    clearTimeout(timer)
    setTimer(null)
    setOpacity(0)
  }

  const handleMouseMoveThrottle = throttle(e => {
    calculatePosition(e)
  }, 100)

  const handleMouseMove = e => {
    e.persist()
    handleMouseMoveThrottle(e)
  }

  const calculatePosition = (e) => {
    const margin = 0 // in percent
    const width = e.currentTarget.offsetWidth
    const height = e.currentTarget.offsetHeight
    const x = e.nativeEvent.offsetX
    const y = e.nativeEvent.offsetY
    const percentX = ((100+(margin*2))/width) * x - margin
    const percentY = ((100+(margin*2))/height) * y - margin
    // console.log(width, height, x, y, percentX, percentY);

    const img_width = image.main_image_width || image.width
    const img_height = image.main_image_height || image.height
    const largest_dimension = width > height ? img_width : img_height
    const largest_dimension_type = width > height ? width : height
    const multiplier = (largest_dimension_type / largest_dimension) * 2
    const imageWidth = multiplier * img_width
    const imageHeight = multiplier * img_height
    // console.log(largest_dimension, largest_dimension_type, multiplier, imageWidth, imageHeight)

    setPosition({
      x: `${percentX}%`,
      y: `${percentY}%`,
      size: `${imageWidth}px ${imageHeight}px`,
    })
    // console.log(position)
  }

  return (
    <>
    <div
      style={{
        ...absolute,
        zIndex: 5,
        backgroundColor: css.color.offwhitedark,
        backgroundImage: `url(${props.src})`,
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100%',
        opacity: opacity,
        transition: 'opacity 400ms ease, background-position 100ms ease',
        backgroundPositionX: position.x,
        backgroundPositionY: position.y,
        backgroundSize: position.size,
        borderRadius: props.circle ? '50%' : 0,
        overflow: props.circle ? 'hidden' : 'default',
        cursor: useHover ? 'inherit' : opacity ? 'zoom-out' : 'zoom-in',
      }}
      onClick={handleClick}
      onMouseMove={handleMouseMove}
      // onMouseMove={throttler}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    />
      {/* <MobileOnly>
        <div style={{ position: 'asbolute', zIndex: 100, top: '1em', left: '1em' }}>
          <IconButton type="zoom" onClick={opacity ? handleMouseLeave : handleMouseEnter} />
        </div>
      </MobileOnly> */}
    </>
  )
}


export default Img
