const removeAllProps = (state) => {
  var newState = Object.assign({}, state);
  var keys = Object.keys(newState)
  for (var i = 0; i < keys.length; i++) {
    delete newState[keys[i]]
  }
  return newState
}

export default removeAllProps
