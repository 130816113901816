import Settings from 'settings'

export const fans = {
  name: 'fans',
  request: {
    url: `${Settings.api}/fans`,
    middleware: {
      // checkStore: (store) => store.fans.data.fans.length, // check if data is already in the store
    }
  },
  state: {
    data: {
      fans: [],
    },
  },
}
