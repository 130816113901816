// Border - if you regularly use the same border CSS, define it here.

export const border_weight = 2

export const border = {
  // Basic
  solid: `${border_weight}px solid`,
  dashed: `${border_weight}px dashed`,
  dotted: `${border_weight}px dotted`,
  form: `2px solid`,
  radius: '2px',

  // Negavtive border offsets - useful when borders are out of line.
  offset_margin: `0 -${border_weight}px -${border_weight}px -${border_weight}px`,
  offset_margin_sides: `0 -${border_weight}px`,
  offset_margin_topleft: `-${border_weight}px 0 0 -${border_weight}px`,
  offset_padding: `0 ${border_weight}px ${border_weight}px ${border_weight}px`,
}
