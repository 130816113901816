import React, { useEffect } from 'react';

// Hook
export const usePrerenderReady = (status = 'Idle') => {
  useEffect(() => {
    if (status === 'Success') window.prerenderReady = true;
  }, [status])

  return window.prerenderReady
}

export default usePrerenderReady
