import Settings from 'settings'
import Cookies from 'js-cookie'
import { dispatch } from 'redux/store'
import { navigate } from 'components'
import ReactPixel from 'react-facebook-pixel'

export const current_user = {
  name: 'current_user',
  request: {
    url: `${Settings.api}/current_user:endpoint`,
  },
  state: {
    data: {
      user: {
        first_name: '',
        last_name: '',
        is_artist: false,
        is_guest: true,
        slug: null,
      }
    },
  },
}
