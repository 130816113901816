import React from 'react'
import * as css from 'styles'
import styled from '@emotion/styled'
import propStyles from 'prop-styles'

const Notice = ({ show, children, background, position, style }) => {
  return show ? (
    <NoticeWrapper 
      styles={style}
      background={background}
      position={position}
    >
      {children}
    </NoticeWrapper>
  ) : null
}

const NoticeWrapper = styled('div')({
  padding: '0.75em 1em',
  animation: `${css.fadeIn} 1s ease`,
  textAlign: 'center',
  background: css.color.offwhitedark,
  zIndex: css.z.header - 1,

  '& h5':{
    // margin: '0',
    lineHeight: '1.4em',
  },

}, ({ background, styles, position })=>{

  const theme = background ? {
    background: background,
    color: css.color.white,
    '& a':{
      color: css.color.white,
      textDecoration: 'none !important',
      borderBottom: `1px solid ${css.color.white}`,
    },
  } : {}
  
  const pos = {
    inline: {
    },
    'inline-rounded': {
      borderRadius: '4px',
    },
    top: {
      position: 'fixed',
      top: `${css.heights.header}px`,
      width: '100%',
      animation: `${css.fadeDown} 0.75s ease`,
      boxShadow: `2px 2px 10px rgba(0,0,0,0.025)`,
      [css.breakpoint.m_down]:{
        top: `${css.heights.header_s}px`,
      },
    },
    bottom: {
      position: 'fixed',
      bottom: `0px`,
      width: '100%',
      animation: `${css.fadeUp} 0.75s ease`,
    },
    float: {
      position: 'fixed',
      bottom:  '2em',
      left: '1em',
      right: '1em',
      // width: '100%',
      maxWidth: '400px',
      margin: '0 auto',
      padding: '0.5em 0.25em',
      borderRadius: '4px',
      animation: `${css.fadeUp} 0.3s ease`,
      boxShadow: `0px 0px 15px rgba(0,0,0,0.3)`,
      [css.breakpoint.m_down]: {
        bottom: '1em',
      },
    },
  }[position || 'inline']

  return {
    ...theme,
    ...pos,
    ...styles,
  }
})

export default Notice