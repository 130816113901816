import Settings from 'settings'

export const followed_artists = {
  name: 'followed_artists',
  request: {
    url: `${Settings.api}/users?list=following`,
    middleware: {
      checkStore: (store) => store.followed_artists.data.length, // check if data is already in the store
    }
  },
  state: {
    data: {
      followed_artists: [],
    }, // items needs an empty array, so we override default 'data' here
  },
}