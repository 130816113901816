import React from 'react'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import * as css from 'styles'
import propStyles from 'prop-styles'

import Layout from './Base'
import { FadeIn, Grid, Sticky } from 'components'

export const LayoutGrid = styled('div')({
  ...css.widths.outer,
  paddingTop: '1em',
  paddingBottom: '2em',
  [css.breakpoint.m_down]: {
    padding: '0.5em 1em 1em',
  }
},propStyles({
  noEndPadding: {
    paddingTop: '0 !important',
  }
}))

const DefaultLayout = ({showFooter, showSitemap, style, background = 'transparent', ...props}) => {
  const { location } = useSelector(store => store)
  return (
    <div style={{background: background}}>
    <Layout showFooter={showFooter} showSitemap={showSitemap} style={style}>
      <LayoutGrid noEndPadding style={props.style}>
        {props.children}
      </LayoutGrid>
    </Layout>
    </div>
  )
}

export default DefaultLayout
