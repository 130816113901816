import { makeVisibleData } from '../plugins/filters/filters.selectors'
import Settings from 'settings'
import { dispatch } from 'redux/store'

export const follows = {
  name: 'follows',
  request: {
    url: `${Settings.api}/follows`,
    middleware: {
      checkStore: (store) => store.follows.data.length, // check if data is already in the store
    }
  },
  state: {
    data: [], // items needs an empty array, so we override default 'data' here
  },
  reducers: {
    add: (state, payload) => ({ ...state, data: [...state.data, payload] }),
    remove: (state, payload) => ({ ...state, data: [...state.data.filter(item => item.followed_id !== parseInt(payload))] }),
  },
  effects: {
    update: ({ id, success }, store) => dispatch({
      type: 'likes/fetch',
      payload: {
        url: `${Settings.api}/follows`,
        method: 'post',
        sideAffect: true,
        data: { followed_id: id },
        success: (data) => {
          if (data.followed) {
            dispatch({ type: 'follows/add', payload: { followed_id: id } })
            if (success) success()
          } else {
            dispatch({ type: 'follows/remove', payload: id })
          }
        },
      }
    })
  },
}
