import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import * as css from '../styles'

export const FullHeight = ({ 
  children,
  style = {},
}) => {
  
  const calculateHeights = () => {
    const height = document.documentElement.clientHeight
    return {
      // Calculate (height - nav hight)
      desktopHeight: height - css.heights.header,
      mobileHeight: height - css.heights.header_s,

      // Get 1% of the innerHeight
      desktopPercent: (height - css.heights.header) * 0.01,
      mobilePercent: (height - css.heights.header_s) * 0.01,
    }
  }

  const [heights, setHeights] = useState(calculateHeights())
  const updateHeights = () => setHeights(calculateHeights())

  useEffect(()=>{
    window.addEventListener('resize', updateHeights)
    return () => window.removeEventListener('resize', updateHeights)
  },[])

  return (
    <FullHeightWrapper {...heights} innerStyle={style}>
      {/* Relative wrapper is needed to reset position, so elements inside can use 'absolute' 'sticky' etc */}
      <div style={{ width: '100%', height: '100%', position: 'relative' }}>
        {children}
      </div>
    </FullHeightWrapper>
  )
}

const FullHeightWrapper = styled('div')(({
  desktopHeight,
  mobileHeight,
  innerStyle,
})=>({
  // Fix the position
  position: 'fixed',
  left: 0,
  right: 0,
  bottom: 0,
  width: '100%',

  // Set the height
  top: `${css.heights.header}px`,
  height: `${desktopHeight}px`,
  [css.breakpoint.m_down]: {
    top: `${css.heights.header_s}px`,
    height: `${mobileHeight}px`,
  },

  // Innerstyle
  '> div':{
    ...innerStyle
  }
}))

export default FullHeight