// import { dispatch } from '@rematch/core'

const checkStore = ({ getState, dispatch }) => next => action => {
  const skip = action.payload && action.payload.sideAffect

  // Check if this is an API request with 'checkStore' middleware - and check ignore checkStore
  if (!skip && action.request && action.request.middleware && action.request.middleware.checkStore && !action.reload) {

    const test = action.request.middleware.checkStore(getState())
    
    if (test) {
      // Data is already loaded in the store - tell redux
      dispatch({ type: action.type+'/from-store' })
      // Stop next action being processed
      return
    }
  }

  next(action)
}

export default checkStore
