import React from 'react';
import * as css from 'styles';
import styled from '@emotion/styled';
import propStyles from 'prop-styles';

import FadeIn from './Animated/FadeIn';
import Spinner from './Loading/Spinner';
import { Icon } from './Icons';

const ButtonComponent = styled('button')(
  {
    position: 'relative',
    transition: css.transition.norm,
    overflow: 'hidden',
    // margin: css.spacing(),
    // marginTop: 0,
    // marginLeft: 0,
    marginRight: css.space.s,
    padding: `${css.space.xs} ${css.space.s}`,

    backgroundColor: css.color.primary,
    color: css.color.white,
    fontSize: css.font.l,
    fontFamily: css.font.heading,
    // borderRadius: css.border.radius,
    border: `${css.border.form} ${css.color.primary}`,
    borderRadius: '1.5em',

    // States
    '&:hover': {
      backgroundColor: `${css.color.darkblack}`,
      borderColor: css.color.darkblack,
      color: css.color.white,
    },
    '&:focus': {
      outline: 0,
      backgroundColor: css.color.primary,
      borderColor: css.color.darkgray,
    },
    '&:active': {
      backgroundColor: `${css.color.black}`,
      borderColor: css.color.black,
    },

    // Breakpoints
    // [css.breakpoint.s_down]: {
    //   fontSize: css.font.m
    // },
  },
  propStyles({
    active: {
      backgroundColor: `${css.color.black} !important`,
      borderColor: `${css.color.black} !important`,
    },

    fit: {
      width: '100%',
    },

    secondary: {
      border: `${css.border.form} ${css.color.lightgray}`,
      backgroundColor: css.color.lightgray, //css.color.white,
      color: css.color.black,

      // States
      '&:hover, &:focus, &:active': {
        backgroundColor: `${css.color.lightgrayhover} !important`,
        borderColor: css.color.lightgrayhover,
        color: css.color.primary,
      },
    },

    gray: {
      border: `${css.border.form} ${css.color.gray2}`,
      backgroundColor: css.color.gray2, //css.color.white,
      color: css.color.white,

      // States
      '&:hover, &:focus, &:active': {
        backgroundColor: `${css.color.darkgray} !important`,
        borderColor: css.color.darkgray,
        color: css.color.white,
      },
    },

    blue: {
      border: `${css.border.form} ${css.color.blue}`,
      backgroundColor: css.color.blue, //css.color.white,
      color: css.color.white,

      // States
      '&:hover, &:focus, &:active': {
        backgroundColor: `${css.color.darkblue} !important`,
        borderColor: css.color.darkblue,
        color: css.color.white,
      },
    },

    red: {
      border: `${css.border.form} ${css.color.red}`,
      backgroundColor: css.color.red, //css.color.white,
      color: css.color.white,

      // States
      '&:hover, &:focus, &:active': {
        backgroundColor: `${css.color.darkred} !important`,
        borderColor: css.color.darkred,
        color: css.color.white,
      },
    },

    outlined: {
      border: `${css.border.form} ${css.color.black}`,
      backgroundColor: 'transparent', //css.color.white,
      color: css.color.black,

      // States
      '&:hover, &:focus, &:active': {
        backgroundColor: `${css.color.white} !important`,
        borderColor: css.color.primary,
        color: css.color.primary,
      },
    },

    outlinedgray: {
      border: `${css.border.form} ${css.color.lightgray}`,
      backgroundColor: 'transparent', //css.color.white,
      color: css.color.darkgray,

      // States
      '&:hover, &:focus, &:active': {
        backgroundColor: `${css.color.white} !important`,
        borderColor: css.color.gray,
        color: css.color.darkgray,
      },
    },

    outlineddisabled: {
      border: `${css.border.form} ${css.color.gray}`,
      backgroundColor: 'transparent', //css.color.white,
      color: css.color.gray,

      // States
      '&:hover, &:focus, &:active': {
        backgroundColor: `transparent !important`,
        borderColor: css.color.gray,
        color: css.color.gray,
      },
    },

    text: {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      color: css.color.primary,

      // States
      '&:hover, &:focus, &:active': {
        backgroundColor: 'transparent !important',
        borderColor: 'transparent !important',
        color: `${css.color.darkblack} !important`,
      },

      '&:disabled': {
        backgroundColor: 'transparent !important',
        borderColor: 'transparent !important',
        color: `${css.color.darkblack} !important`,
        opacity: 0.2,
      },
    },

    disabled: {
      backgroundColor: css.color.gray,
      borderColor: css.color.gray,
      cursor: 'not-allowed !important',

      // States
      '&:hover, &:focus, &:active': {
        backgroundColor: `${css.color.gray} !important`,
        borderColor: `${css.color.gray} !important`,
      },
    },

    small: {
      padding: `${css.space.xs} ${css.space.s}`,
      fontSize: css.font.r,
      // margin: 0,
    },

    xsmall: {
      padding: `${css.space.xs} ${css.space.s}`,
      fontSize: css.font.xs,
      // margin: 0,
      // [css.breakpoint.s_down]:{
      //   fontSize: css.font.r,
      // }
    },

    radius: {
      borderRadius: '1.5em',
    },

    radiusSmall: {
      borderRadius: '2px',
    },

    nomargin: {
      margin: 0,
    },

    nopadding: {
      padding: 0,
    },
  }),
  ({ status = 'Idle' }) => {
    const state = {
      Idle: {},
      Pending: { color: 'transparent !important' },
      Success: { color: 'transparent !important' },
    }[status];
    return state || {};
  }
);

export const Button = ({ status = 'Idle', children, ...props }) => {
  const icon_size = props.xsmall
    ? '20px'
    : props.small
    ? '25px'
    : '30px';
  return (
    <ButtonComponent
      // disabled={status==='Pending' || status==='Success'}
      {...props}
      status={status}
    >
      {children}
      {status === 'Pending' && (
        <FadeIn
          inline
          style={{ position: 'absolute', left: 0, right: 0 }}
        >
          <Spinner
            small
            inline
            style={{
              borderLeftColor: css.color.white,
              width: icon_size,
              height: icon_size,
              marginTop: '-3px',
            }}
          />
        </FadeIn>
      )}
      {status === 'Success' && (
        <FadeIn
          inline
          style={{ position: 'absolute', left: 0, right: 0 }}
        >
          <Icon
            type="tick"
            inline
            stroke={css.color.white}
            size={icon_size}
          />
        </FadeIn>
      )}
    </ButtonComponent>
  );
  // return {
  //   'Idle': <ButtonComponent {...props}>{children}</ButtonComponent>,
  //   'Error': <ButtonComponent {...props}>{children}</ButtonComponent>,
  //   'Pending': <ButtonComponent {...props}>{children}<FadeIn><Spinner xsmall style={{ display: 'inline-block', position: 'absolute' }} /></FadeIn></ButtonComponent>,
  //   'Success': <ButtonComponent {...props}>Success</ButtonComponent>,
  // }[status]
};

export default Button;
