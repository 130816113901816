import { makeVisibleData } from '../plugins/filters/filters.selectors'
import Settings from 'settings'
// import { removeItem } from './saves'

const addOrAppendList = (lists, list) => {
  const listExists = lists.filter(l => l.slug === list.slug)
  
  if (listExists) {
    return [ ...lists.filter(l => l.slug !== list.slug), list ]
  } else {
    return [ ...lists, list ]
  }
}

// NOTE - Currently not working
const removeItemFromList = (state, payload) => {
  const lists = state.filter(l => l.id !== payload.list_id)
  const list = state.filter(l => l.id === payload.list_id)[0]

  // console.log(list, payload)
  const newList = {
    ...list, 
    // NOTE - this was disabled in rails - i don't remember why - could be that i use 'saves' rather than nested saves in lists as the source of truth
    // saves: [...list.saves.filter(s => s.artwork_id !== payload.artwork_id)], 
    artworks: [...list.artworks.filter(a => a.id !== payload.artwork_id)],
  } 
  const newState = [...lists, newList]
  return newState
}

const addItemToList = (state, payload) => {
  // TODO
}

export const lists = {
  name: 'lists',
  request: {
    url: `${Settings.api}/lists`,
    middleware: {
      // checkStore: (store) => store.lists.data.length, // check if data is already in the store
    }
  },
  state: {
    data: [], // items needs an empty array, so we override default 'data' here
  },
  reducers: {
    // add: (state, payload) => ({ ...state, data: [...state.data, payload] }),
    add: (state, payload) => ({ ...state, data: addOrAppendList(state.data, payload) }),
    remove: (state, payload) => ({ ...state, data: [...state.data.filter(item => item.slug !== parseInt(payload))] }),
    removeItem: (state, payload) => ({ ...state, data: removeItemFromList(state.data, payload) })
  },
}
