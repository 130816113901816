import React from 'react'
import * as css from 'styles'
import styled from '@emotion/styled'

import { CenterContent } from 'components'

const LogoScreenWrapper = styled('div')({
  transition: css.transition.xfast,
  background: css.color.white,
  position: 'fixed',
  zIndex: css.z.modal-2,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  opacity: 1,
}, ({status})=>{
  return status === 'Success' || status === 'Error' ? {
    opacity: 0,
    pointerEvents: 'none',
    userSelect: 'none',
  } : {}
})

export const LogoScreen = ({ status = 'Pending' }) => {  
  const statusCheck = (['checkout'].some(s => window.location.pathname.includes(s))) ? 'Success' : status
  const size = 130

  return (
    <LogoScreenWrapper status={statusCheck}>
      <CenterContent height="100vh">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={size}
          height={size}
          viewBox="0 0 192 192"
        >
          <path
            fill="transparent"
            d="M0 0H192V192H0z"
          ></path>
          <g id="group-b" className="group" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
            <path
              id="arrow1-b"
              className="arrow1"
              fill="#231F20"
              d="M43.6773773 148.322623L43.6773773 110.677377 34 101 34 158 91 158 81.3226227 148.322623z"
            ></path>
            <path
              id="arrow2-b"
              className="arrow2"
              fill="#231F20"
              d="M151.322623 40.6773773L151.322623 78.3226227 161 88 161 31 104 31 113.677377 40.6773773z"
            ></path>
            <path
              id="line-b"
              className="line"
              stroke="#231F20"
              strokeLinecap="square"
              strokeWidth="10"
              d="M127.75 64.75L65.25 127.25"
            ></path>
          </g>
        </svg>
      </CenterContent>
    </LogoScreenWrapper>
  )
}


export default LogoScreen