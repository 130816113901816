// Turn a-slug-like-this into: A Title Like This
export default function makeTitle(slug) {
  // var words = slug.toString().split('-');
  var words = slug.toString().split(/[ .-_]+/);
  for (var i = 0; i < words.length; i++) {
    var word = words[i];
    words[i] = word.charAt(0).toUpperCase() + word.slice(1);
  }
  return words.join(' ');
}
