import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import * as css from 'styles'

import {  Link, Button, Modal, Grid } from 'components'

const RedeemInviteModal = () => {
  const dispatch = useDispatch()
  const { data: { referral_code, referral_amount, referrer_name }, status } = useSelector(store=>store.forms)
  const close = () => dispatch.modals.close({ name: 'invite-redeemed' })
  return (
    <Modal name="invite-redeemed">
      <center>
        <h2>You've got credit</h2>
        <h3 style={{fontSize: '2.5em', margin: 0, padding: '0.25em 0'}}><span style={{transform: 'scale(-1, 1)', display: 'inline-block'}}>🎉</span> ${referral_amount} 🎉</h3>
      </center>
      <p style={{padding: '0 1em'}}>It can be used towards anything on Art in Res, and will be applied in the checkout when you purchase.</p>

      <Grid>
        <Link to="/artworks" onClick={close}>
          <Button fit small tabIndex="-1">View the art</Button>
        </Link>

        <Link to="/how-it-works" onClick={close}>
          <Button fit small outlined tabIndex="-1">Learn how it works</Button>
        </Link>
      </Grid>
    </Modal>
  )
}

export default RedeemInviteModal