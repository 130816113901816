import Settings from 'settings'

export const rental = {
  name: 'rental',
  request: {
    url: `${Settings.api}/rentals/:id`,
    middleware: {
      // checkStore: store => store.location.payload.id === store.rentals.data.rental.id
    }
  },
  state: {
    data: {
      rental: {},
      artwork: {
        main_image: {},
      },
    },
  },
}
