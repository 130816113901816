// import { makeVisibleData } from '../plugins/filters/filters.selectors'
import Settings from 'settings'

export const artists = {
  name: 'artists',
  request: {
    // url: `${Settings.api}/artists`,
    url: `${Settings.api}/users`,
    // url: `${Settings.api}/artists?ids=1820,935,732,1721,314,1440,1770,453,730,590,1193,726,985,1690,743,1804,1055,1796,1581,1213,591,441,1448,1056,1412,644,1654,554,1497,1743,1103,1435,1661,1660,751,366,557,402,1416,601,1215,531,804,1487,1864,463,1117,740,1337,1757,964,1017,636,1548,1585,396,990,826,1718,639,927,1713,1888,916,1534,1763,281,1588,795,1766,1556,307,947,320,1600,1100,308,1211,1672,1164,1433,334,1134,1189,1369,1007,1041,1765,1281,742,1886,1893,1525,1599,1506,1043,1646,1377,593,401`,
    middleware: {
      checkStore: (store) => store.artists.data.length, // check if data is already in the store
    }
  },
  state: {
    data: {
      artists: [],
    }, // items needs an empty array, so we override default 'data' here
  },
  // filters: {
  //   filter: (item, test) => true,
  //   cat: (item, test) => item.categories.includes(test),
  //   // gender: (item, test) => item.gender.toLowerCase() === test.toLowerCase(),
  //   // search: (item, test) => `${item.first_name}${item.last_name}`.toLowerCase().includes(test.toLowerCase())
  // },
  // urlQuery: true, // e.g: /artists?gender=male
}

// export const getVisibleData = makeVisibleData(artists.filters)
