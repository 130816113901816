const transitionsOn = true

export const speed = {
  // Speed in miliseconds
  1: 75,
  2: 150,
  3: 300,
  4: 500,
  5: 1000,
}

export const transition = {
  // Basic transition css values - Don't forget to add the unit of time.
  // Can turn all transitions off in settings - helpful for debugging.
  xfast: transitionsOn ? `all ${speed[1]}ms ease` : 'none',
  fast:  transitionsOn ? `all ${speed[2]}ms ease` : 'none',
  norm:  transitionsOn ? `all ${speed[3]}ms ease` : 'none',
  slow:  transitionsOn ? `all ${speed[4]}ms ease` : 'none',
  xslow: transitionsOn ? `all ${speed[5]}ms ease` : 'none',
}
