import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route } from 'react-router-dom'
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux'
import store from './redux/store'
import history from './redux/history'

import './styles/0_normalize.css'
import './styles/1_typography.css'
import './styles/search.css'
import App from './App'

// Enable redux with the router
const render = () => {
  return ReactDOM.render(
      <Provider store={store}>
        <Router history={history}>
             <Route component={App} />
        </Router>
      </Provider>
    ,document.getElementById('root'),
  )
}

// Test if components should re-render - disable if console is going crazy
// if (process.env.NODE_ENV === 'development') {
//   const whyDidYouRender = require('@welldone-software/why-did-you-render');
//   whyDidYouRender(React, {
//     trackAllPureComponents: true,
//   });
// }

// Render React in public/index.html
render()


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();