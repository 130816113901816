import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import * as css from 'styles'
import styled from '@emotion/styled'
import propStyles from 'prop-styles'

import arraySortBy from 'utils/arraySortBy'
import { Modal, Icon, Grid, Link, FadeIn, Button, Loading } from 'components'

// type: 'modals/open', payload: {name: 'save-to-list'}

const SaveSelect = ({ sendSave, close, icon }) => {
  const dispatch = useDispatch()
  const { status: listsStatus, data: lists } = useSelector(store => store.work_lists)
  const { status: saveStatus, data: saves } = useSelector(store => store.saves)
  const modals = useSelector(store => store.modals)
  const data = modals['save-to-work-list'] ? modals['save-to-work-list'].data : null
  const artwork_id = data ? data.artwork_id : null

  const saveToList = (list) => {
    const submission = {
      data: {
        list_id: list.id,
        artwork_id: artwork_id,
      }
    }
    console.log('submission:', submission)
    // sendSave(list.id)
    dispatch.saves.sendSave(submission.data)
  }

  return (
    <Modal name="save-to-work-list" XnoPadding>
      <div style={{position: 'relative'}}>

        <h2 style={{ paddingBottom: '0', marginBottom: '0', textAlign: 'center' }}>
          Save to a work list
          {/* - {artwork_id} */}
        </h2>

        <Loading status={listsStatus} height="200px">
        <VerticalScroll>
        <SaveSelectWrapper>
          {lists.sort(arraySortBy('name','asc')).map((list,i)=>{
            const active = saves.filter(s => s.artwork_id === artwork_id && s.list_id === list.id).length
            return (
              <li key={i} onClick={() => saveToList(list)} className={`ellipsis ${active && 'active'}`}>
                <Icon
                  type={list.type === 'WorkList' ? 'work' : 'detail'}
                  inline
                /> &nbsp; {list.name}

                <span className="add-to-list">
                  <Icon
                    type="tick"
                    stroke={active ? css.color.black : css.color.lightgray}
                    inline
                  />
                </span>
                {active ? 
                  <span className="remove-from-list">
                    <Icon
                      type="close"
                      stroke={css.color.black}
                      inline
                    />
                  </span>
                : null }
              </li>
            )
          })}
        </SaveSelectWrapper>
        </VerticalScroll>
        </Loading>

        <Grid columns="1fr" columnsMobile="1fr" style={{
          // bottom: 'calc(-0.5em - 1px)',
          // bottom: 0,
          // position: 'sticky',
        }}>
          <div>
            <Link to="/work-lists/new">
              <Button xsmall fit onClick={() => dispatch.modals.close({ name: 'save-to-work-list' })}>New List +</Button>
            </Link>
          </div>
          {/* <div>
            <Link to="/lists"><Button xsmall fit outlined tabIndex="-1" onClick={() => dispatch.modals.close({ name: 'save-to-list' })}>View Lists</Button></Link>
          </div> */}
        </Grid>

      </div>
    </Modal>
  )
}

const VerticalScroll = styled('div')({
  maxHeight: '300px',
  overflowY: 'scroll',
  WebkitOverflowScrolling: 'touch',
})

const SaveSelectWrapper = styled('ul')({
  minHeight: '140px',
  lineHeight: '1.3em',

  background: css.color.white,
  padding: 0,
  textAlign: 'left',
  fontFamily: css.font.heading,
  fontSize: '0.85em',
  listStyle: 'none',

  '& li':{
    padding: '0.75rem 0.75rem',
    // margin: '0 0 2px !important',
    borderBottom: `1px dotted ${css.color.lightgray}`,
    // color: css.color.gray,
    cursor: 'pointer',

    '&.active': {
      color: css.color.black,
    },

    '&:last-child':{
      border: 0,
    },
    '& .add-to-list': {
      transition: css.transition.xfast,
      opacity: 1,
      float: 'right',
    },
    '& .remove-from-list': {
      transition: css.transition.xfast,
      // opacity: 0,
      display: 'none',
      float: 'right',
    },
    '&:hover':{
      // color: css.color.darkgray,
      background: css.color.offwhite,
      '& .remove-from-list':{
        display: 'block',
        opacity: 1,
      },
      '&.active .add-to-list': {
        display: 'none',
      },
    },
  },

  '& a':{
    textDecoration: 'none !important',
  }
})

export default SaveSelect