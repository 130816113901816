import Settings from 'settings'
import { dispatch } from 'redux/store'
import { navigate } from 'components'

export const api = {
  name: 'api',
  request: {
    url: `${Settings.api}:endpoint`,
  },
  state: {
    data: {}
  },
  effects: {
    send: ({endpoint, ...payload}, store) => {
      // console.log('submit', endpoint, payload, payload.data)
      dispatch({
        type: 'api/fetch',
        payload: {
          url: `${Settings.api}${endpoint}`,
          method: payload.method || 'post',
          data: payload.data,
          success: (return_payload) => {
            // console.log(return_payload)
            dispatch({ type: 'api/resetStatus' })
            if (payload.success) payload.success(return_payload)
            if (payload.redirect) navigate(payload.redirect)
          },
        }
      })
    }
  }
}
