export const backurl = {
  name: 'backurl',
  state: {
    type: '',
    url: '',
    position: 0,
  },
  reducers: {
    update: (state, payload) => ({...state, ...payload}),
  }
}