import * as css from 'styles';
import styled from '@emotion/styled';
import propStyles from 'prop-styles';

export const Screen = styled('div')({
  position: 'fixed',
  zIndex: css.z.modal,

  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: '100%',
  height: '100%',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  background: `hsla(0,0%,0%,0.75)`,
  padding: css.space.l,

  [css.breakpoint.s_down]:{
    padding: css.space.r,
  },

  // transition: css.transition.xfast,
  transition: '100ms',

  pointerEvents: 'default',
  userSelect: 'default',
  // animation: `${fadeIn} ${css.speed[2]}ms linear both running`,
},
({ opacity })=>({
  backgroundColor: `hsla(0,0%,0%,${opacity ? opacity : 0.75})`,
}), propStyles({
  closed: {
    opacity: 0,
    // backgroundColor: `hsla(0,0%,0%,0)`,
    pointerEvents: 'none',
    userSelect: 'none',
    // animation: `${fadeIn} ${css.speed[2]}ms linear reverse both`,
  }
}))

export default Screen