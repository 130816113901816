import Settings from 'settings'

export const artwork = {
  name: 'artwork',
  request: {
    url: `${Settings.api}/artworks/:id`,
    middleware: {
      checkStore: store => store.location.payload.id === store.artwork.data.artwork.id
    }
  },
  state: {
    data: {
      artwork: {
        main_image: {},
      }
    },
    showSize: false,
  },
  reducers: {
    toggleSize: (state, payload) => ({...state, showSize: !state.showSize})
  }
}
