import React, { useEffect, Suspense, lazy } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { NOT_FOUND } from 'redux-first-router'

// Preload layout components
import LogoScreen from 'app/browse/LogoScreen'
import Layout from 'layouts/Base'

// Components needed at app start (mostly modals)
import MetaButton from 'app/auth/MetaButton'
import LoginModal from 'app/auth/Login'
import CreateAccountModal from 'app/auth/CreateAccountModal'
import ConfirmAccountModal from 'app/auth/ConfirmAccountModal'
import CreateListModal from 'app/lists/CreateListModal'
import SaveToListModal from 'app/lists/SaveToListModal'
import SaveToWorkListModal from 'app/lists/SaveToWorkListModal'
import TermsOfServiceModal from 'app/account/TermsOfServiceModal'
import RedeemInviteModal from 'app/onboarding/RedeemInviteModal'
import ChatModal from 'app/browse/ChatModal'
import RequestsModal from 'app/transactions/RequestsModal'
import RequestsAcceptModal from 'app/transactions/RequestsAcceptModal'

// Components needed only on route changes
// Test page
const Test = lazy(() => import('app/browse/filters/FiltersModal'))

// Onboarding / Sales
const IntroFlow = lazy(() => import('app/onboarding/IntroFlow'))
const WelcomeFlow = lazy(() => import('app/onboarding/WelcomeFlow'))
const ArtistApplication = lazy(() => import('app/onboarding/ArtistApplication'))
const ArtistApplicationForm = lazy(() => import('app/onboarding/ArtistApplicationForm'))
const HowItWorks = lazy(() => import('app/onboarding/HowItWorks'))
const FAQ = lazy(() => import('app/onboarding/FAQ'))
const GiftCards = lazy(() => import('app/onboarding/GiftCards'))
const WhiteLabelPitch = lazy(() => import('app/onboarding/WhiteLabelPitch'))

// Articles
const Articles = lazy(() => import('app/articles/Articles'))
const Article = lazy(() => import('app/articles/Article'))

// Browse
const NotFound = lazy(() => import('app/browse/NotFound'))
const Home = lazy(() => import('app/browse/Home'))
const Artworks = lazy(() => import('app/browse/Artworks'))
const Artwork = lazy(() => import('app/browse/Artwork'))
const ArtistProfile = lazy(() => import('app/artists/ArtistProfile'))
const CollectorProfile = lazy(() => import('app/browse/CollectorProfile'))
const Artists = lazy(() => import('app/artists/Artists'))
const Personalized = lazy(() => import('app/lists/Personalized'))
const Discover = lazy(() => import('app/browse/Discover'))
const Recommendations = lazy(() => import('app/recommendations/Recommendations'))
const Results = lazy(() => import('app/recommendations/Results'))
const Locale = lazy(() => import('app/locales/Locale'))

// Activity
const MyStuffNav = lazy(() => import('app/lists/MyStuffNav'))
const Likes = lazy(() => import('app/lists/Likes'))
const Following = lazy(() => import('app/lists/Following'))
const Messages = lazy(() => import('app/messages/Messages'))
const Fans = lazy(() => import('app/messages/Fans'))
const Lists = lazy(() => import('app/lists/Lists'))
const List = lazy(() => import('app/lists/List'))

// Artist Management
const Works = lazy(() => import('app/works/Works'))
const Work = lazy(() => import('app/works/WorkEditor'))
const WorkList = lazy(() => import('app/works/WorkListEditor'))

// Transactions
const PaymentPlans = lazy(() => import('app/transactions/PaymentPlans'))
const Cart = lazy(() => import('app/cart/Cart'))
const Checkout = lazy(() => import('app/checkout/Checkout'))
const OrderConfirmation = lazy(() => import('app/checkout/OrderConfirmation'))

// Account
const Account = lazy(() => import('app/account/Account'))

// Other
const Roadmap = lazy(() => import('app/browse/Roadmap'))
const Landing = lazy(() => import('./arthunter/Landing'))
const Animations = lazy(() => import('app/browse/LogoAnimations'))

// Search
const Search = lazy(() => import('app/search/Search'))
const Invites = lazy(() => import('app/onboarding/Invites'))
const RedeemInvite = lazy(() => import('app/onboarding/RedeemInvite'))

// Request
const RequestArt = lazy(() => import('app/request/RequestArt'))

const whenAppStarts = (dispatch, location, userIsLoaded) => {
  // NOTE - this condition helps development be faster - stops reloading data if the user is already in the store
  if (!userIsLoaded) {
    dispatch.current_user.fetch({
      endpoint: '/all',
      success: (data) => {
        if (data.requests > 0 && location.type !== 'route/sale') dispatch.modals.open({ name: 'purchase-requests' })

        if (data.user.is_bot === false) {
          dispatch.cart.fetch()
          dispatch.follows.fetch()
          dispatch.likes.fetch()
          dispatch.dislikes.fetch()
          dispatch.saves.fetch()
          dispatch.lists.fetch()
        }
      }
    })
  }

  // Log referrals
  const referrer = document.referrer
  const reggie = new RegExp('^https?://' + window.location.hostname)
  const params = location.query || {}
  if (!reggie.test(referrer)) {
    dispatch.api.send({
      endpoint: '/referrer',
      data: {
        referrer,
        ...params,
      }
    })
  }

  // NOTE - this might cause people to complain they can't press 'back' to get out of the site.
  // Remove utm_params from URL after successful referrer submission.
  // if (location.query) {
  //   // var url = window.location.href.replace(/(?<=&|\?)utm_.*?(&|$)/igm, "") // NOTE - this doesn't work on iOS due to lookbehind not being implemented
  //   var url = window.location.href.replace(/(\?)utm[^&]*(?:&utm[^&]*)*&(?=(?!utm[^\s&=]*=)[^\s&=]+=)|\?utm[^&]*(?:&utm[^&]*)*$|&utm[^&]*/gi, '$1')
  //   var newurl = url.endsWith('?') ? url.slice(0, -1) : url
  //   window.history.pushState({ path: newurl }, '', newurl)
  // }

  // NOTE - REMOVE AFTER TESTING
  // dispatch.modals.open({name: 'save-to-list', data: { artwork_id: 2269 }})
}

// Render
const App = () => {
  const dispatch = useDispatch()
  const { location, current_user } = useSelector(store => store)
  const set = location.query && location.query.set ? location.query.set : 0
  const userIsLoaded = current_user.data.user.first_name

  useEffect(() => whenAppStarts(dispatch, location, userIsLoaded), [])

  const showLogoScreen = !userIsLoaded && (current_user.status === 'Idle' || current_user.status === 'Pending') && (location.type !== 'route/test')

  return (
    <>
      <LogoScreen status={showLogoScreen ? 'Pending' : 'Success'} />

      {/* Temporary Components = Pages */}
      <Suspense fallback={<Layout fadeIn={false} />}>
        {{
          // Test
          'route/test': <Test />,

          // Onboarding / Sales
          'route/intro': <IntroFlow />,
          'route/login': <IntroFlow />,
          'route/welcome': <WelcomeFlow />,
          'route/artist-application': <ArtistApplication />,
          'route/artist-application-form': <ArtistApplicationForm />,
          'route/how-it-works': <HowItWorks />,
          'route/faq': <FAQ />,
          'route/gift-cards': <GiftCards />,
          'route/start-your-own-art-business': <WhiteLabelPitch />,

          // Browse
          'route/search': <Search />,
          'route/home': <Home />,
          'route/discover': <Discover />,
          'route/artworks': <Artworks />,
          'route/artwork': <Artwork key={'artwork' + location.payload.id} />,
          'route/artists-list': <Artists />,
          'route/artists': <Artists />,
          'route/artist-profile': <ArtistProfile key={'profile' + location.payload.id} />,
          'route/collector-profile': <CollectorProfile key={'profile' + location.payload.id} />,

          // Articles
          'route/articles': <Articles />,
          'route/articles-guides': <Articles />,
          'route/articles-curations': <Articles />,
          'route/articles-inside-the-studio': <Articles />,
          'route/article': <Article key={'article' + location.payload.id} />,
          'route/locale': <Locale />,

          // Activity
          'route/likes': <Artworks message="When you like artworks they will be shown here." nav={MyStuffNav} />,
          'route/history': <Artworks message="When you view artworks your history will be shown here." nav={MyStuffNav} />,
          'route/following': <Following />,
          'route/lists': <Lists />,
          'route/list': <List key={'list' + location.payload.id} />,
          'route/personalized': <Personalized />,

          // Recommendations
          'route/recommendations': <Recommendations key={`recs-set-${parseInt(set || 0)}`} />,
          'route/recommendations-results': <Results key={`recs-set-${parseInt(set || 0)}`} />,
          'route/recommendations-artworks': <Results key={`recs-set-${parseInt(set || 0)}`} />,
          'route/recommendations-artists': <Results key={`recs-set-${parseInt(set || 0)}`} />,
          'route/recommendations-lists': <Results key={`recs-set-${parseInt(set || 0)}`} />,
          'route/recommendations-feedback': <Results key={`recs-set-${parseInt(set || 0)}`} />,

          // Request
          'route/request': <RequestArt />,

          // Messaging
          'route/messages': <Messages />,
          'route/conversation': <Messages />,
          'route/fans': <Fans />,
          'route/fan-conversation': <Fans />,

          // My Art
          'route/works': <Works />,
          'route/works-new': <Work key="works-new" />,
          'route/works-edit': <Work key={'works' + location.payload.id} />,
          'route/work-lists': <Works />,
          'route/work-lists-new': <WorkList key="work-list-new" />,
          'route/work-lists-edit': <WorkList key={'work-list' + location.payload.id} />,

          // Purchases & Sales
          'route/purchases': <PaymentPlans type="purchases" />,
          'route/purchase': <PaymentPlans type="purchases" />,
          'route/sales': <PaymentPlans type="sales" />,
          'route/sale': <PaymentPlans type="sales" />,

          // Transactions
          'route/cart': <Cart />,
          'route/checkout': <Checkout />,
          'route/checkout-shipping': <Checkout />,
          'route/checkout-payment': <Checkout />,
          'route/checkout-review': <Checkout />,
          'route/checkout-confirmation': <OrderConfirmation />,
          'route/order': <OrderConfirmation />,

          // Account
          'route/account': <Account />,
          'route/account-profile': <Account />,
          'route/account-billing': <Account />,
          'route/account-shipping': <Account />,
          'route/account-transactions': <Account />,
          'route/account-transfer': <Account />,
          'route/account-settings': <Account />,
          'route/account-invites': <Account />,
          'route/account-gift-cards': <Account />,
          'route/account-notifications': <Account />,

          // Other
          'route/invite': <Invites />,
          'route/redeem-invite': <RedeemInvite />,
          'route/roadmap': <Roadmap />,
          'route/arthunter': <Landing />,
          'route/animations': <Animations />,
          [NOT_FOUND]: <NotFound />,
        }[location.type]}
      </Suspense>

      {/* Persistent Components = Modals, Tools */}
      {/* <MetaButton /> */}
      <LoginModal />
      <CreateAccountModal />
      <ConfirmAccountModal />
      <CreateListModal />
      <SaveToListModal />
      <SaveToWorkListModal />
      <RedeemInviteModal />
      <TermsOfServiceModal />
      <ChatModal />
      <RequestsModal />
      <RequestsAcceptModal />
    </>
  )
}

export default App
