import { makeVisibleData } from '../plugins/filters/filters.selectors'
import Settings from 'settings'
import Cookies from 'js-cookie'
import axios from 'axios'
import { dispatch } from 'redux/store'

export const removeItem = (state, payload) => {
  // Filter is internally applying && conditions as if they are sequential filters - no matter how the test is constructed i.e: brackets wrapping the test
  // - using keepItem returns a binary result so filter is only applied once.
  const keepItem = (item) => item.artwork_id === parseInt(payload.artwork_id) && item.list_id === parseInt(payload.list_id) ? false : true
  const newState = state.filter(item => keepItem(item))
  // console.log(payload); console.table(state); console.table(newState);
  return [...newState]
}

export const saves = {
  name: 'saves',
  request: {
    url: `${Settings.api}/saves`,
    middleware: {
      checkStore: (store) => store.saves.data.length, // check if data is already in the store
    }
  },
  state: {
    data: [], // items needs an empty array, so we override default 'data' here
  },
  reducers: {
    add: (state, payload) => ({ ...state, data: [...state.data, payload] }),
    remove: (state, payload) => ({ ...state, data: removeItem(state.data, payload) }),
  },
  effects: {
    changePosition: ({ list_id, artwork_id, rank, success = false }, store) => {
      dispatch({
        type: 'likes/fetch',
        payload: {
          url: `${Settings.api}/likes`,
          method: 'patch',
          sideAffect: true,
          data: { artwork_id, list_id, rank },
          success,
        }
      })
    },
    sendSave: async ({ list_id, artwork_id, success = false }, store) => {
      // if (e) e.preventDefault();
      // setSave({ status: 'Pending' })

      // await setTimeout(false, 1000)
      const auth_token = Cookies.get('auth_token')
      const { data } = await axios({
        method: 'post',
        url: `${Settings.api}/saves`,
        headers: auth_token && { Authorization: auth_token },
        data: {
          artwork_id,
          list_id,
        },
      })
      // setSave({ status: data.saved ? 'Saved' : 'NotSaved' })
      // console.log(data)
      if (data.saved) {
        dispatch({ type: 'saves/add', payload: { artwork_id: data.artwork_id, list_id: data.list_id } })
        // setOpen(false)
      } else {
        dispatch({ type: 'saves/remove', payload: { artwork_id: data.artwork_id, list_id: data.list_id } })
        // NOTE - Below needs to work but broke for some reason???
        dispatch({ type: 'lists/removeItem', payload: { artwork_id: data.artwork_id, list_id: data.list_id } })
      }
      if (success) success(data)
    }
  }
}
