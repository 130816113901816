// import { dispatch } from '@rematch/core'
import { dispatch } from 'redux/store'

// 1. To create a link that will prepopulate format as:
// 2. /cart?sku1=2  is equivalent to product ID=1 and quantity=2

const prepopulateFromUrl = (store) => next => async action => {
  next(action)

  // Check action contains meta.query
  if (action.type.includes('/cart') && action.meta && action.meta.query) {

    // Get items from url query - filter if key includes 'sku'
    const queryKeys = Object.keys(action.meta.query)
    const items = queryKeys.filter(key=>key.includes('sku')).reduce((items, id)=>{
      items[parseInt(id.replace('sku',''))] = parseInt(action.meta.query[id])
      return items
    }, {})

    // Get personalised message
    const message = action.meta.query.msg

    // Get email to populate checkout
    const email = action.meta.query.email

    // Dispatch populate - needs to be async await - rematch takes a while to get function on load
    await dispatch;
    dispatch.cart.populate({ items, message })
    dispatch.user.hasAccount({ email })
  }
}

export default prepopulateFromUrl
