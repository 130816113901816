// ES6:
// function updateState(state, prop, item) {
//     return Object.assign({}, state, {[prop]: item});
// }

// With Object Spread:
export default function updateState(state, prop, item) {
  return {
     ...state,
     [prop]: item
  };
}
