import Settings from 'settings';
import { dispatch } from 'redux/store';

const initialState = () => ({
  data: {
    apply_credit: false,
    message: null,
    items: [],
    artworks: [],
    totals: {
      subtotal: 0,
      shipping: 0,
      tax: 0,
      total: 0,
    },
    new_totals: {
      subtotal: 0,
      shipping: 0,
      tax: 0,
      fees: 0,
      total: 0,
    },
  },
});

export const cart = {
  name: 'cart',
  request: {
    url: `${Settings.api}/cart`,
  },
  state: initialState(),
  reducers: {
    empty: (state, payload) => ({ ...initialState() }),
  },
  effects: {
    addItem: (payload, store) =>
      dispatch({
        type: 'cart/fetch',
        payload: {
          url: `${Settings.api}/cart/item`,
          method: 'post',
          data: payload,
          success: () => {
            if (payload.success) {
              payload.success();
            } else {
              dispatch({ type: 'route/cart' });
            }
          },
        },
      }),
    updateItem: (payload, store) =>
      dispatch({
        type: 'cart/fetch',
        payload: {
          url: `${Settings.api}/cart/item/${payload.id}`,
          method: 'patch',
          data: payload.data,
        },
      }),
    removeItem: (payload, store) =>
      dispatch({
        type: 'cart/fetch',
        payload: {
          url: `${Settings.api}/cart/item/${payload.id}`,
          method: 'delete',
          success: () => {
            if (payload.success) {
              payload.success();
            } else {
              dispatch({ type: 'route/cart' });
            }
          },
        },
      }),
    // Apply Credit
    applyCredit: (payload, store) =>
      dispatch({
        type: 'cart/fetch',
        payload: {
          url: `${Settings.api}/cart/apply_credit`,
          success: (data) => {
            // dispatch({ type: 'cart/fetch' });
          },
          error: (error) => {
            // Navigate to whichever panel is incorrect, highlight errors
          },
        },
      }),
  },
};
