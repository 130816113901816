import styled from '@emotion/styled'
import * as css from 'styles'
import { fadeRight, fadeLeft } from 'styles/animations'
import propStyles from 'prop-styles'

export const FadeUp = styled('div')({
  opacity: 0,
  animation: `${fadeRight} ${css.speed[3]}ms ease forwards`,
}, ({delay}) => ({
  animationDelay: delay || 0,
}), propStyles({
  reverse: {
    animation: `${fadeLeft} ${css.speed[3]}ms ease forwards`,
  }
}))

export default FadeUp