import Settings from 'settings'

export const conversation = {
  name: 'conversation',
  request: {
    url: `${Settings.api}/conversations/:id`,
    middleware: {
      // checkStore: store => store.location.payload.id === store.conversation.data.conversation.id
    }
  },
  state: {
    data: {
      user: {},
      converser: {},
      interactions: [],
      user_likes: [],
      converser_likes: [],
    },
  },
  reducers: {
    add: (state, payload) => ({ 
      ...state, 
      data: {
        ...state.data, 
        interactions: [...state.data.interactions, payload],
      }
    }),
  }
}
