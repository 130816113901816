import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'

import { Button, Input, Spinner, FadeIn } from 'components'

export const CreateAccountForm = ({ 
  disabled = false, 
  submit_text = 'Get Started', 
  success = false,
  showPassword = true,
  showName = false,
  blue = true,
  login = false,
  invite_code = false,
  flow = 'default',
  confirmation = false,
}) => {
  const dispatch = useDispatch()
  const { status, data: { user, valid }, error: { errors = {} } } = useSelector(store => store.auth)
  const { register, handleSubmit, reset } = useForm() // watch, errors

  const onSubmit = data => { 
    dispatch.auth.createAccount({
      data,
      success: (data) => {
        reset()
        if (success) success(data)
      },
      login,
      flow,
      confirmation,
    })
  }

  return (
    <form key="emailOnlyCreateAccount" onSubmit={handleSubmit(onSubmit)} style={{position: 'relative'}} disabled={disabled}>
      <fieldset disabled={disabled}>
        {showName && <Input register={register} name="full_name" type="text" placeholder="Enter your full name" required={true} errors={errors}/>}
        <Input register={register} name="email" type="email" placeholder="Enter email address" required={true} errors={errors}/>
        {showPassword && <Input register={register} name="password" type="password" placeholder="Enter password" required={true} errors={errors} />}
        {invite_code && <input ref={register} name="invite_code" type="hidden" value={invite_code} required={true} />}
        <Button small fit blue={blue} type="submit" status={status} disabled={status==='Pending'}>{submit_text}</Button>
      </fieldset>
    </form>
  )
}

export default CreateAccountForm
