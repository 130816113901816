// import { dispatch } from '@rematch/core'
import { dispatch } from 'redux/store'

const queryFromUrl = (store) => next => async action => {
  next(action)

  // // Check action contains meta.query
  // if (!action.type.includes('/cart') && action.meta && action.meta.query) {
  //   // Dispatch setQuery from action type - needs to be async await - rematch takes a while to get function on load
  //   await dispatch;
  //   const setQuery = dispatch[action.type.replace('route/','')].updateQuery
  //   setQuery && setQuery(action.meta.query)
  // }
}

export default queryFromUrl
