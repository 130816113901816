// import { dispatch } from '@rematch/core'
import { dispatch } from 'redux/store'
import addProp from 'utils/objAddProp';
import deleteProp from 'utils/objDeleteProp';
import removeAllProps from 'utils/objDeleteAllProps'

const isFilterAlreadySet = (query, {filter, value}) => query[filter] === value

export const filtersState = () => ({
  query: {}
})

export const filtersReducers = (resetState) => ({
  updateFilter: (state, payload) => ({...state, query: addProp(state.query, payload.filter, payload.value) }),
  removeFilter: (state, payload) => ({...state, query: deleteProp(state.query, payload.filter) }),
  noFilters: (state, payload) => ({...state, query: removeAllProps(state.query) }),
  updateQuery: (state, payload) => ({...state, query: payload }),
})

export const filtersActions = (name) => ({
  resetFilters: () => dispatch[name].noFilters(),
  setFilter: (payload, store) => dispatch[name].updateFilter(payload),
  // setFilter: (payload, store) => 
  //   isFilterAlreadySet(store[name].query, payload) ?
  //     dispatch[name].removeFilter(payload) :
  //     dispatch[name].updateFilter(payload),
})
