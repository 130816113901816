import Settings from 'settings'

export const article = {
  name: 'article',
  request: {
    url: `${Settings.api}/articles/:id`,
    middleware: {
      // checkStore: store => store.location.payload.id === store.article.data.article.id
    }
  },
  state: {
    data: {
      article: {
        featured_image: {},
        category: {},
      },
      author: {},
      artworks: [],
    },
  },
}
