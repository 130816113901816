import React from 'react';

// 1. HOC component that adds keypress events to a component
// 2. keymap is an object with key numbers and functions

// Example - keymap takes a key number, and a function as the value
// const keymap = {
//   27: () => { this.close() } // escape
// }

const withKeys = MyComponent => class extends React.Component {

  state = { keymap: {} }

  componentDidMount() {
    document.addEventListener( 'keydown', this.keydown.bind(this) )
  }

  componentWillUnmount() {
    document.removeEventListener( 'keydown', this.keydown.bind(this) )
  }

  keydown = (e) => {
    // console.log(e.keyCode);
    // console.log(this.state);
    if (e.keyCode in this.state.keymap) this.state.keymap[e.keyCode]()
  }

  setKeymap = (keys) => {
    this.setState({ keymap: keys })
  }

  render() {
    return (
      <MyComponent
        {...this.props}
        keymap={this.setKeymap}
      />
    )
  }
}

export default withKeys
