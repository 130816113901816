import { redirect, NOT_FOUND } from '@rematch/core'
import { dispatch, getState } from 'redux/store'
import axios from 'axios'
import Cookies from 'js-cookie'
import replaceUrlParams from 'utils/replaceUrlParams'
axios.defaults.withCredentials = true // Neccessary for guest users - not sure why

// Using dispatch from 'redux/store' instead of 'react-redux'
// because it has namespace access to 'success()' and 'error()' for each apiFactory model.

const apiMiddleware = (store) => (next) => (action) => {
  next(action);

  // Check if this is an API request.
  if (action.request && action.request.url) {
    const { name, request, payload } = action;

    // Setup Request
    const setup = {
      method: payload.method || request.method || 'get',
      headers: request.headers || {},
      data: request.data || payload.data || false, // ? JSON.stringify(payload.body) : undefined,
      withCredentials: true,
    }

    // Add authorization
    const auth_token = Cookies.get('auth_token')
    if (auth_token) setup.headers['Authorization'] = auth_token

    // Add meta user authorization
    const meta_auth_token = Cookies.get('meta_auth_token')
    if (meta_auth_token) setup.headers['Meta-Authorization'] = meta_auth_token

    // Parse the URL for any params - e.g: ':id' or ':name'
    const { location } = getState()
    const query = payload.search || location.search
    const url = replaceUrlParams(payload.url || request.url, payload, query)

    // Request started - dispatch to redux - set loading state
    if (!action.reload) dispatch[name].pending(payload.sideAffect ? payload.data : null)

    // Do API request
    axios.request({url, ...setup})
      .then(({ data, ...otherData }) => {
        // console.log('api success', otherData)

        // Request successful - dispatch data to redux - set data, remove loading state
        if (payload.nodata || data) {
          dispatch[name].success(payload.sideAffect ? null : data) // default - replace data in model
          if (payload.success) payload.success(data) // side-effect - do something else with data

          // If { conversion: ...} is set in Rails response, dispatch action to get processed by 'middleware/events.js'
          if (data && data.conversion) dispatch({ type: 'event/conversion', payload: data.conversion })
          // Multiple conversions
          if (data && data.conversions) data.conversions.forEach(c => dispatch({ type: 'event/conversion', payload: c }))
          // If there is a notice
          if (data && data.notice) dispatch.forms.update({ notice: data.notice })

        } else {
          if (name === 'api') {
            // Return if general API send - NOT expecting a return value.
            dispatch[name].success()
            return
          } else {
            // Else trigger data not found error - redirect if established in action 
            dispatch[name].error({ message: `${name} not found` })
            if (action.redirect) dispatch(redirect({ type: NOT_FOUND }))
          }
        }
      })
      .catch(error => {
        // console.log('api error', error)
        
        // Request error - dispatch error to redux - set error, remove loading state
        dispatch[name].error(error.response && error.response.data)

        if (action.redirect) dispatch(redirect({ type: NOT_FOUND }))
        
        if (payload.error) payload.error(error.response && error.response.data) // side-effect - do something else with data

        if (error.response) {
          if (error.response.status === 401) {
            dispatch({ type: 'modals/open', payload: {name: 'login'} })
          }
        }
      })
  }
};

export default apiMiddleware
