const replaceUrlParams = (url, payload, query) => {
  // Works the same as React Router urls - '/posts/:id' becomes '/posts/123'
  // Uses the template url in model request, and the payload object - {id: 123}
  var newUrl = url
  for (var item in payload) {
    if (item !== 'body' && payload.hasOwnProperty(item)) {
      newUrl = newUrl.replace(`:${item}`, `${payload[item]}`)
    }
  }
  return `${newUrl}${query ? '?' + query.replace('&undefined', '') : ''}`
  // return newUrl
}

export default replaceUrlParams
