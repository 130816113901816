import { createSelector } from 'reselect'
import filterByQuery from 'utils/filterByQuery'

const getData = state => state.data
const getQuery = state => state.query

export const filterData = ({ query, data }, filters) => {
  // console.log(query, data, filters)
  // NOTE - this test is needed because it doesn't detect any state structure initially? it's weird - might be from updating after react-boiler
  return data && data.constructor === Array ? data.filter(filterByQuery(query, filters)) : data
}

export const makeVisibleData = (filters, customData, customQuery) => createSelector(
  // [getQuery, getData],
  [customQuery || getQuery, customData || getData],
  (query, data) => filterData({query, data}, filters)
)
