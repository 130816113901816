import Settings from 'settings'
import { dispatch } from 'redux/store'

export const recommendations = {
  name: 'recommendations',
  request: {
    url: `${Settings.api}/recommendations`,
  },
  state: {
    data: {
      artworks: [],
      artists: [],
      lists: [],
      articles: [],
      likes: [],
    },
  },
  effects: {
    wizard: (payload, store) => dispatch({
      type: 'recommendations/fetch',
      payload: {
        url: `${Settings.api}/recommendations/wizard`,
        ...payload,
      },
    }),
    results: (payload, store) => dispatch({
      type: 'recommendations/fetch',
      payload: {
        url: `${Settings.api}/recommendations/results`,
        ...payload,
      },
    }),
  },
}
