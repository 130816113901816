import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as css from 'styles'
import styled from '@emotion/styled'

import { Button, Modal, Link, Icon, navigate } from 'components'

export const OptionsModal = ({
  name = 'options-modal', // Must be unique
  titleText = 'Filter',
  buttonText = 'Update Results',
  options = [
    { key: 'key', value: 'value', icon: null, title: 'Default Value', default: true },
  ],
  onSubmit = false, // accepts an override function
}) => {
  const dispatch = useDispatch()
  const location = useSelector(store => store.location)
  const [query, setQuery] = useState(location.query || {})

  const handleClose = () => dispatch.modals.close({ name: name })

  const handleClick = (e, filter, value) => {
    if (e) e.preventDefault()

    // Create the new query
    const newQuery = filter === false ? {} : {
      ...query,
      [filter]: value,
    }
    setQuery(newQuery)
  }

  const handleSubmit = (e) => {
    if (e) e.preventDefault()
    if (onSubmit) {
      // Override if onSubmit present
      onSubmit(query)
    } else {
      // Create filters url
      const urlparams = Object.entries(query)
        .filter(key => key[0] !== 'page') // reset the page 
        .filter(([key, val]) => val) // if blank remove
        .map(([key, val]) => `${key}=${val}`)
        .join('&')

      // Navigate to url 
      navigate(`${location.pathname}?${urlparams}`)
    }

    handleClose()
  }

  return (
    <Modal name={name}>

      <h2 style={{ textAlign: 'center' }}>{titleText}</h2>

      {options.length ? (
        <ListWrapper>
          {options.map((item, i) => {
            const isSelected = Object.keys(query).length ?
              item.value === query[item.key] :
              item.default

            return item.break ? (
              <br key={i}/>
            ):(
              <li key={i}>
                <Link
                  to={`${location.pathname}${item.url}`}
                  onClick={(e) => handleClick(e, item.key, item.value)}
                >
                  <div>
                    {item.icon ? (
                      <span style={{ paddingRight: '0.5em' }}>
                        <Icon inline type={item.icon} />
                      </span>
                    ) : null}

                    {item.title}

                    {isSelected ? (
                      <span style={{ float: 'right' }}>
                        <Icon inline type="tick" />
                      </span>
                    ) : null}
                  </div>
                </Link>
              </li>
            )
          })}
        </ListWrapper>
      ) : null}

      <Button small fit type="submit" onClick={handleSubmit}>{buttonText}</Button>

    </Modal>
  )
}

const ListWrapper = styled('ul')({
  minHeight: '140px',
  lineHeight: '1.3em',

  background: css.color.white,
  padding: 0,
  textAlign: 'left',
  fontFamily: css.font.heading,
  fontSize: '0.85em',
  listStyle: 'none',

  '& li': {
    // margin: '0 0 2px !important',
    borderBottom: `1px dotted ${css.color.lightgray}`,
    color: css.color.gray,
    cursor: 'pointer',

    '> a > div': {
      padding: '0.75rem 0.75rem',
    },

    '&.active': {
      color: css.color.black,
    },

    '&:last-child': {
      border: 0,
    },
    '& .remove-from-list': {
      transition: css.transition.xfast,
      opacity: 0,
      float: 'right',
    },
    '&:hover': {
      // color: css.color.darkgray,
      background: css.color.offwhite,
      '& .remove-from-list': {
        opacity: 1,
      },
    },
  },

  '& a': {
    textDecoration: 'none !important',
  }
})

export default OptionsModal