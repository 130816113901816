import React from 'react'

const Logo = ({ height, stroke = "#231F20" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    // width="369"
    // height="58"
    // width="auto"
    height={height}
    viewBox="0 0 369 58"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill={stroke} fillRule="nonzero">
            <path d="M347.1.85c9.65 0 17.73 4.74 19.36 15l-7.77 1.71c-1.24-5.41-4.04-9.41-11.59-9.41-6.69 0-9.79 3.39-9.79 7.39 0 3.73 1.69 5.25 13.69 9.25 13.9 4.67 17.4 8.71 17.42 16.73 0 8.87-6.93 16.1-19.29 16.1-12.85 0-20.29-6.76-21.85-19.76l8.16-1.63c1.41 9.26 4.36 14.08 13.77 14.08 7.61 0 11.51-3.66 11.51-8.32s-1.96-6.81-14.78-10.81c-11.83-3.74-16.41-8.16-16.41-15.63 0-7.7 6.37-14.7 17.57-14.7zM78.38 2.08c10.97 0 18.35 5.75 18.35 16.56 0 9.57-4.89 14-12.73 15.71l11.92 22.19h-8.56L75.86 35h-11v21.51h-7.78V2.08zm-50.64 0l19.59 54.43h-8.24l-3.58-10H12.19l-3.5 10H.6L20.28 2.08h7.46zm116.07 0v7.23h-16.57v47.2h-7.78V9.31h-16.48V2.08h40.83zm110-.07c10.97 0 18.35 5.75 18.35 16.56 0 9.57-4.9 14-12.7 15.71l11.89 22.16h-8.56l-11.5-21.54h-11v21.54h-7.78V2.01zm63.32 0v7.22h-25.5v15.79h23.32v6.92h-23.32v17.35h25.5v7.15h-33.28V2.01h33.28zM23.94 13.28h-.16l-9.26 26.51h18.66l-9.24-26.51zm165.81-11.2l10.1 18.11h.07V2.08h5.38v27.61h-5.25l-10.22-18.1h-.08v18.1h-5.32V2.08h5.32zm-13.4 0v27.6h-5.33V2.08h5.33zM77.68 9.16H64.86v18.89h12.91c6.23 0 11.11-2.49 11.11-9.41s-4.6-9.48-11.2-9.48zm175.43-.08h-12.82v18.9h12.87l.03.02c6.23 0 11.12-2.52 11.12-9.44s-4.6-9.48-11.2-9.48z"></path>
        </g>
    </g>
  </svg>
)

export default Logo