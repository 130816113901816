import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import * as css from '../styles'
import propStyles from 'prop-styles'
import Cookies from 'js-cookie'

import { Link, Grid, FadeIn, Button } from 'components'
import CreateAccountForm from 'app/auth/CreateAccountEmailOnlyForm'

const Footer = (props) => {
  const is_guest = useSelector(store => store.current_user.data.user.is_guest)
  const likes = useSelector(store => store.likes.data.length)

  return(
    <>
      <FooterWrapper hidden={!is_guest}>
        <FooterNav>

          <Grid columns="1fr 1fr" center>
            <div>
              <h1 style={{ margin: 0, lineHeight: '1.1em' }}>
                Be the first to know about new artists, new artworks, events, and offers.
              </h1>
              
            </div>

            <FormWrapper>
              <CreateAccountForm 
                flow="footer"
                login={true}
                confirmation={true}
              />
              <div style={{ fontFamily: css.font.heading, fontSize: '0.7em', margin: '1em 0 0', color: css.color.darkgray, textAlign: 'center', Xpadding: '0 3em' }}>
                You agree to our <Link to="/articles/terms-of-service" target="_blank" style={{ textDecoration: 'underline' }}>TOS</Link> when creating&nbsp;an&nbsp;account.
              </div>
            </FormWrapper>

          </Grid>

          {/* <div style={{margin: '2em 0 -2em'}}>
            <Link to="/artist-application">
              <Button fit text small tabIndex="-1" style={{ height: '100%' }}>Apply as an artist &rarr;</Button>
            </Link>
          </div> */}

          {/* <Grid columns="1fr">
          <div>
            <div style={{textAlign: 'center'}}>
              <h1 style={{margin: 0, lineHeight: '1.1em'}}>Join our community</h1>
              <div style={{fontFamily: css.font.text, fontSize: '1.2em', marginTop: '0.5em', lineHeight: '1.3em'}}>
                When&nbsp;you&nbsp;join you&nbsp;get&nbsp;access&nbsp;to:
              </div>
              <ul style={{ fontFamily: css.font.text }}>
                <li>New artworks</li>
                <li>New artists</li>
                <li>Pay over time</li>
                <li>Message artists</li>
                <li>Follow artists</li>
                <li>Save your {likes ? <Link to="/likes"><span style={{ color: css.color.blue }}>{likes}&nbsp;likes</span></Link> : 'likes'}</li>
              </ul>
            </div>
          </div>
          <div>
            <Link to="/create-account">
              <Button fit blue tabIndex="-1">Create Account</Button>
            </Link>
            <br />
            <br />
            <Link to="/artist-application">
              <Button fit text small tabIndex="-1" style={{ height: '100%' }}>Apply as an artist</Button>
            </Link>
          </div>
          </Grid> */}

        </FooterNav>
      </FooterWrapper>
    </>
  )
}

const FooterWrapper = styled('footer')({
  fontFamily: css.font.heading,
  background: css.color.white,
  transition: css.transition.fast,
})

const FooterNav = styled('div')({
  ...css.widths.outer,
  // display: 'grid',
  // gridTemplateColumns: '1fr 1fr',
  alignItems: 'center',
  // justifyContent: 'center',
  maxWidth: '900px',
  // maxWidth: '900px',
  height: '100%',
  paddingTop: '5em',
  paddingBottom: '3em',
  [css.breakpoint.m_down]:{
    paddingTop: '4em',
    paddingBottom: '2em',
  },

  '& hr': {
    margin: '0.75em 0',
    border: `0.5px solid ${css.color.lightgray}`,
  },

  '& ul':{
    listStyle: 'none',
    padding: 0,
    [css.breakpoint.m_down]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridGap: 0,
      // fontSize: '0.9em',
    },

    '& li':{
      display: 'inline-block',
      padding: '0.5em 1em',
      textAlign: 'center',
      [css.breakpoint.m_down]: {
        display: 'block',
        padding: '0.5em 0.5em',
      }
    }
  }
})

const FormWrapper = styled('div')({
  '& input':{
    paddingTop: '1.1em',
    paddingBottom: '1.1em',
    borderColor: `${css.color.gray} !important`,
    '&:focus':{
      borderColor: `${css.color.black} !important`,
    }
  },
  '& button':{
    marginTop: '0.5em',
  },
  '& input, & button':{
    fontSize: '1.4em',
  }
})

export default Footer