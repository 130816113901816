import React, { useState, useEffect } from 'react';

// Hook
export function useKey(key, callback, propsThatChange) {
  // Keep track of key state
  const [pressed, setPressed] = useState(false)

  // Does an event match the key we're watching?
  const match = event => (event.key && key.constructor === String) ? key.toLowerCase() === event.key.toLowerCase() : false

  // Event handlers
  const onDown = event => {
    if (match(event)) setPressed(true)
  }
  
  const onUp = event => {
    if (match(event)) {
      setPressed(false); 
      if (callback) callback();
    }
    // console.log(event)
  }

  // Bind and unbind events
  useEffect(() => {
    window.addEventListener("keydown", onDown)
    window.addEventListener("keyup", onUp)
    return () => {
      window.removeEventListener("keydown", onDown)
      window.removeEventListener("keyup", onUp)
    }
  }, [key, propsThatChange, onDown, onUp])

  return pressed
}

export default useKey
