import React, { useState } from 'react';
import * as css from 'styles';
import styled from '@emotion/styled';
import propStyles from 'prop-styles';

import { Grid, Icon, FadeIn, Button, Spinner } from 'components';

const ButtonSelect = styled('input')(
  {
    opacity: 0,
    position: 'absolute',

    '& + label': {
      transition: css.transition.fast,
      border: `1px solid ${css.color.offwhitedark2}`,
      borderRadius: '4px',
      // margin: `0 0 ${css.space.r}`,
      position: 'relative',
      cursor: 'pointer',
      // left: '35px',
      padding: '0.75em',
      color: css.color.darkgray,
      fontSize: css.font.r,
      userSelect: 'none',
      fontFamily: css.font.heading,
    },

    // States
    ':checked + label': {
      color: css.color.black,
      borderColor: css.color.black,
    },
    ':hover + label': {
      borderColor: css.color.darkgray,
    },
    ':focus + label': {
      borderColor: css.color.black,
    },

    // '&:first-of-type + label': { borderRadius: '4px 0 0 4px' },
    // '&:last-of-type + label':{ borderRadius: '0 4px 4px 0' },
  },
  propStyles({
    checked: {
      '& + label': {
        color: css.color.black,
        borderColor: css.color.black,
      },
    },
  })
);

const ButtonSelectWrapper = ({ type, register, ...props }) => <ButtonSelect type="radio" ref={register} {...props} />;

export const ButtonSelectGroup = ({ name, options = [], defaultValue, register }) => {
  const [selected, setSelected] = useState(defaultValue);
  const onChange = (value) => setSelected(value);

  return (
    <Grid minmax="140px, 1fr" minmaxMobile="140px, 1fr" gap="0.25em" mobileGap="0.25em">
      {options.map((option, i) => (
        <>
          <ButtonSelectWrapper
            name={name}
            register={register}
            id={name + option.value}
            value={option.value}
            defaultChecked={defaultValue === option.value}
            checked={selected === option.value}
            onChange={() => onChange(option.value)}
          />
          <label htmlFor={name + option.value}>
            {option.detail && <div style={{ fontSize: '0.7em' }}>{option.detail}</div>}
            {option.text}
            {defaultValue === option.value && (
              <FadeIn>
                <Icon
                  type="tick"
                  style={{
                    position: 'absolute',
                    top: '0.75em',
                    right: '0.75em',
                  }}
                />
              </FadeIn>
            )}
          </label>
        </>
      ))}
    </Grid>
  );
};

const ButtonNew = styled(Button)(
  {
    transition: css.transition.fast,
    background: 'transparent !important',
    textAlign: 'left',
    border: `1px solid ${css.color.offwhitedark2}`,
    borderRadius: '4px',
    margin: 0,
    position: 'relative',
    cursor: 'pointer',
    padding: '0.75em',
    color: css.color.darkgray,
    fontSize: css.font.r,
    userSelect: 'none',
    fontFamily: css.font.heading,
    outline: 'none !important',

    '&:hover': {
      borderColor: `${css.color.darkgray} !important`,
      color: `${css.color.darkgray}`,
    },
    '&:focus': {
      borderColor: `${css.color.black} !important`,
      color: `${css.color.darkgray}`,
    },
  },
  propStyles({
    checked: {
      color: `${css.color.black} !important`,
      borderColor: css.color.black,
    },
  })
);

export const ButtonGroup = ({
  name,
  options = [],
  defaultValue,
  register,
  status = 'Idle',
  onSubmit = () => {},
  columns = false,
}) => {
  const minmax = '140px, 1fr';
  return (
    <Grid
      columns={columns}
      minmax={columns ? false : minmax}
      minmaxMobile={columns ? false : minmax}
      gap="0.25em"
      mobileGap="0.25em"
    >
      {options.map((option, i) => (
        <>
          <ButtonNew
            name={name}
            register={register}
            id={name + option.value}
            value={option.value}
            defaultChecked={defaultValue === option.value}
            checked={defaultValue === option.value}
            onClick={(e) => {
              e.preventDefault();
              onSubmit({ [name]: option.value });
            }}
            // status={status !== 'Pending' ? 'Idle' : status}
          >
            {option.detail && <div style={{ fontSize: '0.7em', marginBottom: '0.5em' }}>{option.detail}</div>}
            {option.text}
            <div
              style={{
                position: 'absolute',
                bottom: '0.50em',
                right: '0.75em',
              }}
            >
              {/* {status==='Pending' ? <Spinner inline small/> : ( */}
              {/* <> */}
              {defaultValue === option.value && (
                <FadeIn>
                  <Icon type="tick" style={{}} />
                </FadeIn>
              )}
              {/* </> */}
              {/* )} */}
            </div>
          </ButtonNew>
        </>
      ))}
    </Grid>
  );
};

export default ButtonSelectWrapper;
