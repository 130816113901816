// Colors - best in HSLA
export const color = {
  // Shades
  white: 'hsla(0, 0%, 100%, 1.0)',

  darkblack: 'hsla(0, 0%, 0%, 1.0)',
  black: 'hsla(0, 2%, 10%, 1.0)',
  blackhover: 'hsla(0, 2%, 15%, 1.0)',
  lightblack: 'hsla(0, 2%, 25%, 1.0)',
  
  darkgray: 'hsla(120, 1%, 55%, 1.0)',
  gray2: 'hsla(120, 1%, 65%, 1.0)',
  gray: 'hsla(120, 1%, 75%, 1.0)',

  lightgrayhover: 'hsla(120, 1%, 85%, 1.0)',
  lightgray: 'hsla(0, 0%, 92%, 1.0)',
  superlightgray: 'hsla(0, 0%, 96%, 1.0)',
  superlightgraytransparent: 'hsla(0, 0%, 96%, 0.75)',

  offwhite: 'hsla(30, 14%, 97%, 1.0)',
  offwhite2: 'hsla(30, 14%, 94.5%, 1.0)',
  offwhitedark: 'hsla(30, 14%, 93%, 1.0)',
  offwhitedark2: 'hsla(30, 14%, 91%, 1.0)',
  offwhitedark3: 'hsla(30, 10%, 80%, 1.0)',
  offwhitedark4: 'hsla(30, 10%, 70%, 1.0)',
  // offwhitedark: 'hsla(30, 10%, 80%, 1.0)',
  
  // Primaries
  darkred: 'hsla(356, 77%, 48%, 1.0)',
  red: 'hsla(356, 77%, 53%, 1.0)',
  lightred: 'hsla(356, 77%, 58%, 1.0)',

  darkgreen: 'hsla(140, 41%, 40%, 1.0)',
  green: 'hsla(140, 41%, 48%, 1.0)',
  lightgreen: 'hsla(140, 36%, 67%, 1.0)',
  superlightgreen: 'hsla(140, 36%, 77%, 1.0)',
  
  yellow: 'hsl(43, 100%, 50%)',
  lightyellow: 'hsla(36, 94%, 80%, 1.0)',
  superlightyellow: 'hsla(36, 94%, 90%, 1.0)',
  
  verydarkblue: 'hsla(219, 49%, 34%, 1.0)',
  darkblue: 'hsla(219, 47%, 40%, 1.0)',
  blue: 'hsla(219, 47%, 50%, 1.0)',
  lightblue: 'hsla(219, 47%, 55%, 1.0)',
  verylightblue: 'hsla(219, 47%, 65%, 1.0)',
  superlightblue: 'hsla(219, 47%, 90%, 1.0)',

  // Theme specific
  primary: 'hsla(0, 2%, 10%, 1.0)',
  primarydark: 'hsla(0, 0%, 0%, 1.0)',
  primarylight: 'hsla(0, 2%, 25%, 1.0)',
}
