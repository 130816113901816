import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import * as css from 'styles'

import { Button, Input, Modal, Spinner, FadeIn, Grid, Link } from 'components'

export const Login = ({ disabled = false }) => {
  const dispatch = useDispatch()
  const { data: { user }, error } = useSelector(store => store.auth)
  const { status } = useSelector(store => store.auth)
  const { register, handleSubmit, watch, errors, reset } = useForm()

  const onSubmit = data => { 
    dispatch.auth.login({
      data,
      success: () => {
        setTimeout(() => {
          reset()
          dispatch({ type: 'modals/confirm', payload: { name: 'login' } })
        }, 1000)
      },
    })
  }

  const modal = useSelector(store => store.modals['login'])
  const isOpen = modal && modal.open

  const preset_errors = (status==="Error") ? { 'email': 'Email or password are incorrect.' } : {}

  return (
    <Modal name="login">

      <center style={{color: css.color.red }}>{preset_errors.email}</center>

      <form onSubmit={handleSubmit(onSubmit)} style={{ position: 'relative' }}>
        <h2 style={{textAlign: 'center'}}>Login to continue</h2>
        <fieldset disabled={!isOpen}>
          <Input register={register} name="email" type="email" placeholder="Email address" disabled={status==='Pending'} />
          <Input register={register} name="password" type="password" placeholder="Password" disabled={status==='Pending'} />
          <Button small fit type="submit" status={status} disabled={status==='Pending' || status==='Success'}>Login</Button>
        </fieldset>
        <br/>
      </form>

      <Grid columns="1fr" columnsMobile="1fr" gap="0.5em">
        <a href="/reset_password" style={{ XgridColumn: 'span 2' }}>
          <Button text xsmall fit tabIndex="-1" style={{lineHeight: '1.4em'}}>Forgot Password</Button>
        </a>
      </Grid>

      <div style={{ borderTop: `1px dotted ${css.color.gray}`, margin: '2em -2em 0.5em', textAlign: 'center' }}>
        <div style={{ textAlign: 'center', margin: '0 auto', position: 'relative', top: '-0.625em' }}>
          <span style={{ background: css.color.white, padding: '0 10px'}}>Don't have an account yet?</span>
        </div>
      </div>

      <Link to="/create-account">
        <Button tabIndex="-1" fit blue small style={{ textAlign: 'center' }}>Create Account</Button>
      </Link>

    </Modal>
  )
}

export default Login