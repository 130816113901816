import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Link, Modal, navigate } from 'components'
import FormBuilder from 'components/FormBuilder'

const TermsOfServiceModal = () => {
  const dispatch = useDispatch()
  const data = useSelector(store => store.forms.data)
  const thisModal = useSelector(store => store.modals['terms-of-service'])
  const isOpen = thisModal ? thisModal.open : false

  useEffect(() => {
    // dispatch.forms.submit({ method: 'get', endpoint: '/tos_acceptance' })
    if (isOpen) dispatch.forms.submit({ method: 'get', endpoint: '/tos_acceptance/current' })
  }, [isOpen])

  return (
    <Modal name="terms-of-service" noPadding>
      {/* <center><h3>Terms of Service</h3></center>   */}
      <div style={{
        overflowY: 'scroll',
        maxHeight: '60vh',
        padding: '2em',
      }}>
        <div dangerouslySetInnerHTML={{ __html: data.tos_html }} />
      </div>
      <div style={{ padding: '0 1em 1em 1em' }}>
        <FormBuilder
          endpoint="/tos_acceptance"
          submit_text="Accept"
          success={() => dispatch.modals.close({ name: 'terms-of-service' })}
          fields={[
            {
              fields: [
                { type: 'hidden', name: 'I have read the Terms of Service', label: 'I have read the Terms of Service' },
              ]
            },
          ]}
        />
        <center style={{ paddingTop: '0.5em' }}>
          <Link external underline target="_blank" to="https://artinres.com/terms-of-service">Read in a new tab</Link>
        </center>
      </div>
    </Modal>
  )
}

export default TermsOfServiceModal