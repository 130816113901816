import Settings from 'settings'

export const purchases = {
  name: 'purchases',
  request: {
    url: `${Settings.api}/purchases`,
    middleware: {
      // checkStore: store => store.location.payload.id === store.purchases.data.purchase.id
    }
  },
  state: {
    data: {
      collector_transactions: [],
      artist_transactions: [],
      artworks: [],
      phases: [],
      states: {},
    },
  },
}
