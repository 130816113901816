import styled from '@emotion/styled'
import * as css from 'styles'
import propStyles from 'prop-styles'

import { fadeIn, fadeUp, fadeDown, fadeLeft, fadeRight, fadeZoom } from 'styles/animations'

export const FadeIn = styled('div')({
  opacity: 0,
  animation: `${fadeIn} ${css.speed[3]}ms linear forwards`,
  animationIterationCount: 1,
  willChange: 'opacity',
  // transform: 'transform3d(0,0,0)',
}, ({delay}) => ({
  animationDelay: delay || 0,
}),propStyles({
  up: { animationName: fadeUp, animationTimingFunction: 'ease' },
  down: { animationName: fadeDown, animationTimingFunction: 'ease' },
  left: { animationName: fadeLeft, animationTimingFunction: 'ease' },
  right: { animationName: fadeRight, animationTimingFunction: 'ease' },
  zoom: { animationName: fadeZoom, animationTimingFunction: 'ease' },
  inline: { display: 'inline-block' },
  fast: { animationDuration: `${css.speed[2]}ms` },
}))

export default FadeIn