import Settings from 'settings'

export const payment_plans = {
  name: 'payment_plans',
  request: {
    url: `${Settings.api}/payment_plans`,
    middleware: {
      // checkStore: store => store.location.payload.id === store.installment_purchases.data.installment_purchase.id
    }
  },
  state: {
    data: [],
  },
}
