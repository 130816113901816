// ES6:
function deleteProperty(state, prop) {
    var newState = Object.assign({}, state);
    delete newState[prop];
    return newState;
}
//
// // With Object Spread:
// function deleteProperty(state, prop) {
//     let  {[prop]: deleted, ...newState} = state;
//     return newState;
// }
//
// // Or even shorter as helper function:
// function deleteProperty({[prop]: deleted, ...newState}, prop) {
//     return newState;
// }
//
// // Simplest
// function deleteProperty(state, prop) {
//   console.log(state, prop);
//     return (({[prop]: deleted, ...state}) => state)(state);
// }

// Or inline:
export default deleteProperty
