import React, { useEffect, useRef } from 'react'
import * as css from 'styles'
import styled from '@emotion/styled'

var autoExpand = function (field) {
  // console.log('autoExpand',field)
  // Reset field height
  field.style.height = 'inherit';

  // Get the computed styles for the element
  var computed = window.getComputedStyle(field);

  // Calculate the height
  var height = parseInt(computed.getPropertyValue('border-top-width'), 10)
    // + parseInt(computed.getPropertyValue('padding-top'), 10)
    + field.scrollHeight
    // + parseInt(computed.getPropertyValue('padding-bottom'), 10)
    + parseInt(computed.getPropertyValue('border-bottom-width'), 10)

  field.style.height = height + 'px';
  // field.style.maxHeight = '300px';
};

document.addEventListener('input', function (event) {
  if (event.target.tagName.toLowerCase() !== 'textarea') return;
  autoExpand(event.target);
}, false);

const TextArea = styled('textarea')({
  paddingTop: `${css.space.s} !important`,
  paddingBottom: `${css.space.s} !important`,
  // height: 'auto !important',
  minHeight: '5em',
  lineHeight: '1.2em',
  transition: `height ${css.speed[4]}ms ease`,
  maxWidth: '100%',
})

const TextAreaWrapper = ({register, ...props}) => {
  const ref = useRef()

  useEffect(() => {
    autoExpand(ref.current)
  },[props])

  return (
    <TextArea 
      {...props}
      ref={(e)=>{
        register(e)
        ref.current = e
      }}
      // onChange={(e) => autoExpand(e.target)}
    />
  )
}

export default TextAreaWrapper
