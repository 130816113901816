import React from 'react'
import styled from '@emotion/styled'
import * as css from '../styles'

import FadeIn from './Animated/FadeIn'

export const CenterContent = ({ children, height = '70vh', textAlign = 'center' }) => (
  <FadeIn>
    <div style={{ height: height, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div style={{ textAlign: textAlign }}>
        {children}
      </div>
    </div>
  </FadeIn>
)

export default CenterContent