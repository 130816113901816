import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as css from 'styles'

import pluralize from 'utils/pluralize'
import { Button, Modal, Link, CenterContent } from 'components'

export const RequestsModal = () => {
  const dispatch = useDispatch()
  const modal = useSelector(store => store.modals['purchase-requests'])
  const { unread_messages = 0, requests = 0 } = useSelector(store => store.current_user.data)

  return (
    <Modal name="purchase-requests">
      <CenterContent height="200px">
        <h2 style={{ lineHeight: '1.3em', marginTop: '1.5em' }}>You have <span style={{ color: css.color.blue }}>{requests}</span> new purchase&nbsp;{pluralize(Number(requests), 'request')} 🎉</h2>
        <Link to="/sales" onClick={() => dispatch.modals.close({ name: 'purchase-requests' })}>
          <Button tabIndex="-1" small>View my requests</Button>
        </Link>
      </CenterContent>
    </Modal>
  )
}

export default RequestsModal