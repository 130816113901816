import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as css from 'styles'
import Notice from './Notice'

import { usePrevious } from 'components'

const TemporaryNotice = () => {
  const dispatch = useDispatch()
  const { forms } = useSelector(store=>store)
  const prevText = usePrevious(forms.data.notice)
  const text = forms.data.notice
  const [showNotice, setShowNotice] = useState(false)
  const [fadeOut, setFadeOut] = useState(false)

  useEffect(()=>{
    if (text && (prevText !== text)) {
      setShowNotice(true)
      setTimeout(()=>{
        setFadeOut(true)
        setTimeout(()=>{
          setFadeOut(false)
          setShowNotice(false)
          dispatch.forms.update({notice: null})
        },1100)
      }, 3000)
    }
  },[text]) 

  return (
    <Notice 
      show={showNotice} 
      background={css.color.blue} 
      position="float" 
      style={fadeOut ? { animation: `${css.fadeOut} 300ms linear forwards`, zIndex: css.z.modal + 1 } : { zIndex: css.z.modal + 1} }
    >
      {text}
    </Notice>
  )
}

export default TemporaryNotice