import Settings from 'settings'

export const purchase = {
  name: 'purchase',
  request: {
    url: `${Settings.api}/purchases/:id`,
    middleware: {
      // checkStore: store => store.location.payload.id === store.purchases.data.purchase.id
    }
  },
  state: {
    data: {
      purchase: {},
      artwork: {
        main_image: {},
      },
    },
  },
}
