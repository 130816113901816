import deleteProp from 'utils/objDeleteProp';

const closed = () => ({
  open: false,
  pose: 'closed',
})

const open = (data) => ({
  open: true,
  pose: 'open',
  data,
})

const confirmed = () => ({
  open: false,
  pose: 'confirmed',
})

const newState = (open, pose) => ({ open, pose })

const closeOtherModals = (state) => {
  const modal_keys = Object.keys(state)
  let closed_modals = {}
  for (let i = 0; i < modal_keys.length; i++) {
    const key = modal_keys[i];
    closed_modals[key] = closed()
  }
  return closed_modals
}

export const modals = {
  name: 'modals',
  state: {}, // blank object
  reducers: {
    register: (state, payload) => ({...state, [payload.name]: closed() }),
    unregister: (state, payload) => ({ ...deleteProp(state, payload.name) }),

    close: (state, payload) => ({...state, [payload.name]: closed() }),
    open: (state, payload) => ({...closeOtherModals(state), [payload.name]: open(payload.data) }),
    confirm: (state, payload) => ({...state, [payload.name]: confirmed() }),
    set: (state, payload) => ({...state, [payload.name]: newState(payload.open, payload.pose)}),
  }
}
