import React, { useState, useEffect } from 'react'
import * as css from 'styles'
import styled from '@emotion/styled'
import propStyles from 'prop-styles'

import InputWrapper from './InputWrapper'

// Dumb components
const Input = styled('input')({
  width: '90% !important',
  maxWidth: '90% !important',
  height: '100%',
  lineHeight: 'inherit',
  display: 'inline-block',
  border: 0,
  textAlign: 'right',
  padding: `0 ${css.space.s}`,

  borderBottomRightRadius: `0 !important`,
  borderTopRightRadius: `0 !important`,

  // States
  // ':hover':{}
  ':focus': {
    outline: 0,
  },

  // Media Queries
  [css.breakpoint.s_down]: {
    width: '80%',
    maxWidth: '80% !important',
  },
})

const IncrementWrapper = styled('div')({
  overflow: 'hidden',
  width: '10%',
  height: 'inherit',
  maxHeight: '100%',
  display: 'block',
  float: 'right',
  border: `1px ${css.color.gray}`,
  borderLeft: `0 !important`,
  borderBottomRightRadius: `${css.border.radius} !important`,
  borderTopRightRadius: `${css.border.radius} !important`,

  // Media Queries
  [css.breakpoint.s_down]: {
    width: '20%',
  },
})

const Increment = styled('div')({
  cursor: 'pointer',
  userSelect: 'none',
  overflow: 'hidden',
  width: '100%',
  height: '50%',
  display: 'block',

  border: 0,
  borderBottom: `1px ${css.color.gray}`,
  // color: css.color.gray,
  backgroundColor: 'rgba(0,0,0,0.05)',

  textAlign: 'center',
  fontSize: '1.2em',
  lineHeight: '1.2em',

  // States
  ':hover': {
    color: css.color.black,
    backgroundColor: 'rgba(0,0,0,0.1)',
  },
  ':active': {
    backgroundColor: css.color.lightgray,
  },
  ':focus': {
    outline: 0,
  },

  // Media Queries
  [css.breakpoint.s_down]: {
    fontSize: '1em',
    lineHeight: '1em',
  },
}, propStyles({
  noborder: {
    borderBottom: 0,
  }
}))

const InputNumber = (props) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(props.value || props.min || 0);
  }, [props.value, props.min]);

  const input = (e) => {
    let newValue = parseFloat(e.target.value);
    let max = props.max;
    let min = props.min;
    setValue((newValue <= min) ? min : (newValue >= max) ? max : newValue);
  }

  const add = () => {
    let increment = parseFloat(value) + 1;
    let incrementMax = (props.max >= increment) ? increment : props.max;
    setValue(props.max ? incrementMax : increment);
  }

  const subtract = () => {
    let decrement = parseFloat(value) - 1;
    let decrementMin = (props.min <= decrement) ? decrement : props.min;
    setValue(props.min ? decrementMin : decrement);
  }

  return (
    <InputWrapper>
      <Input
        {...props}
        ref={props.register}
        id={props.name}
        name={props.name}
        type="number"
        pattern={props.pattern || `[0-9]`}
        value={value}
        onChange={input}
        max={props.max}
        min={props.min}
      />
      {/* <IncrementWrapper>
        <Increment onClick={add}>+</Increment>
        <Increment onClick={subtract} noborder>–</Increment>
      </IncrementWrapper> */}
    </InputWrapper>
  );
}

export default InputNumber
