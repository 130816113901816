import React from 'react';
import withKeys from 'components/withKeys';
import { connect } from 'react-redux';

import Screen from './Screen';
import Overlay from './Overlay';
import { IconButton } from 'components'

// const Modal = ({ open, close, children }) => (
class ModalComponent extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      open: props.open,
      pose: 'closed',
    }
  }

  componentDidMount() {
    this.props.keymap({
      27: () => this.props.open && this.close(), // escape

      // Testing controls - delete
      // 49: () => this.open(), // 1
      // 50: () => this.confirmed(), // 2
      // 51: () => this.toggle(), // 3
    })

    this.props.name && this.props.register({ name: this.props.name })
  }

  componentWillUnmount() {
    this.props.name && this.props.unregister({ name: this.props.name })
  }

  close = (event, check = true) => {
    if (check) {
      if (event && (event.target !== event.currentTarget)) return
      this.props.close && this.props.close(event)
    }
    // this.setState({open: false, pose: 'closed' })
    this.props.closeModal({name: this.props.name})
  }

  open = (event) => {
    // this.setState({open: true, pose: 'open'})
    this.props.openModal({name: this.props.name})
  }

  confirmed = (event) => {
    // this.setState({open: false, pose: 'confirmed'})
    this.props.confirmModal({name: this.props.name})
  }

  toggle = (event) => {
    // this.setState({ open: !this.state.open, pose: this.state.open ? 'closed' : 'open' })
    this.props.open ? this.close() : this.open()
  }

  render() {
    const { close, children, autoheight, noPadding = false, showClose = true } = this.props
    return (
      <Screen
        onClick={this.close}
        closed={!this.props.open}
        >
        <Overlay
          // closed={!this.state.open}
          pose={this.props.pose}
          autoheight={autoheight}
          noPadding={noPadding}
          >
          {/* {this.props.open && children} */}
          {/* <div hidden={!this.props.open}> */}
            {children}
          {/* </div> */}
          {showClose && 
            <div style={{position: 'absolute', top: '0.9em', right: '0.75em' }}>
              <IconButton type="close" onClick={(e) => this.close(e, false)} noHover/>
            </div>
          }
        </Overlay>
      </Screen>
    )
  }
}
// )

const mapState = (state, props) => ({
  open: state.modals[props.name] ? state.modals[props.name].open : false,
  pose: state.modals[props.name] ? state.modals[props.name].pose : 'closed',
})

const mapDispatch = dispatch => ({
  register: dispatch.modals.register,
  unregister: dispatch.modals.unregister,
  closeModal: dispatch.modals.close,
  openModal: dispatch.modals.open,
  confirmModal: dispatch.modals.confirm,
})

export const Modal = connect(mapState, mapDispatch)(withKeys(ModalComponent))

export default Modal
