import Settings from 'settings'

export const payment_plan = {
  name: 'payment_plan',
  request: {
    url: `${Settings.api}/payment_plans/:id`,
    middleware: {
      // checkStore: store => store.location.payload.id === store.installments.data.installment.id
    }
  },
  state: {
    data: {
      artist: {},
      collector: {},
      purchase: {},
      payment_plan: {},
      fulfillment: {},
      artwork: {
        main_image: {},
      },
    },
  },
}
