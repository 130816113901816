import React from 'react'
import styled from '@emotion/styled'
import * as css from '../styles'

const Parent = styled('div')({
  position: 'relative',
  [css.breakpoint.m_up]:{
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
  },
  [css.breakpoint.m_down]:{
    position: 'initial !important',
  }
})

const Child = styled('div')({
  position: 'sticky',
  width: '100%',
  [css.breakpoint.m_down]: {
    position: 'initial !important',
  },
},({ offset })=>({
  top: offset,
  [css.breakpoint.m_down]: {
    top: 'initial',
  },
}))

export const Sticky = ({ children, offset = '0em', style }) => (
  <Parent style={style}>
    <Child offset={offset}>
      {children}
    </Child>
  </Parent>
)

export default Sticky