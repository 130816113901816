import apiActions from './api.actions'
import apiMiddleware from './api.middleware'
import { apiReducers, apiState } from './api.reducers'

const apiModel = (model) => {
  if (model.name && model.request) {
    // Cache initial states - so they can be referenced, not recreated - ??? not sure if this works
    const apiInitialState = apiState(false, model.state) // pass in state to override {data: null} if needed

    // Add additional structure to model
    model.state = { ...model.state, ...apiInitialState }
    model.reducers = { ...model.reducers, ...apiReducers(apiInitialState) }
    model.effects = { ...model.effects, ...apiActions({ name: model.name, request: model.request }) }
  }
  return model
}

export default apiModel
