export const space = {
  // Basic spacing used throughout all styles
  none: '0',
  xxxs: '0.125em',
  xxs: '0.25em',
  xs: '0.5em',
  s: '0.75em',
  r: '1em',
  m: '1.25em',
  l: '1.5em',
  xl: '2em',
  xxl: '3em',
}

// Function that provides css ready spacing.  Use with margin, and padding:
//   e.g: margin: css.spacing(topBottom, space.r) -> margin: '1em 0';
// This looks like a long way of writing a simple css value.
// BUT it enforces consistency across all styles.
export const spacing = (
  position = 'all',
  this_space = space.r,
) => {
  const measure = space[this_space] || this_space
  const measures = {
    all:    measure,

    top:    `${measure} 0 0 0`,
    right:  `0 ${measure} 0 0`,
    bottom: `0 0 ${measure} 0`,
    left:   `0 0 0 ${measure}`,

    topBottom: `${measure} 0`,
    leftRight: `0 ${measure}`,

    ends: `${measure} 0`,
    sides: `0 ${measure}`,
  }
  return measures[position]
}
