import Settings from 'settings'

export const order = {
  name: 'order',
  request: {
    url: `${Settings.api}/orders/:id`,
  },
  state: {
    data: {
      user: {
        email: null,
        first_name: null,
        last_name: null,
      },
      shipping_address_id: null,
      billing_address_id: null,
      payment_method: null,
      cards: [],
      addresses: [],
    },
  },
  reducers: {},
  effects: {},
}