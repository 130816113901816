import Settings from 'settings'

export const rentals = {
  name: 'rentals',
  request: {
    url: `${Settings.api}/rentals`,
    middleware: {
      // checkStore: store => store.location.payload.id === store.rentals.data.rental.id
    }
  },
  state: {
    data: {
      collector_transactions: [],
      artist_transactions: [],
      artworks: [],
      phases: [],
      states: {},
    },
  },
}
