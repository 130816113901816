// import { keyframes } from '@emotion/styled'
import { keyframes } from '@emotion/core'

export const fadeIn = keyframes({
  '0%': { opacity: 0, },
  '100%': { opacity: 1, },
})

export const fadeOut = keyframes({
  '0%': { opacity: 1, },
  '100%': { opacity: 0, },
})

export const fadeRight = keyframes({
  '0%': { opacity: 0, transform: 'translateX(20px)' },
  '100%': { opacity: 1, transform: 'translateX(0px)' },
})

export const fadeLeft = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-20px)' },
  '100%': { opacity: 1, transform: 'translateX(0px)' },
})

export const fadeUp = keyframes({
  '0%': { opacity: 0, transform: 'translateY(20px)' },
  '100%': { opacity: 1, transform: 'translateY(0px)' },
})

export const slideUp = keyframes({
  '0%': { transform: 'translateY(100vh)' },
  '100%': { transform: 'translateY(0)' },
})

export const fadeDown = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-50px)' },
  '100%': { opacity: 1, transform: 'translateY(0px)' },
})

export const fadeZoom = keyframes({
  '0%': { opacity: 0, transform: 'scale(0.8)' },
  '100%': { opacity: 1, transform: 'scale(1)' },
})

export const fadeZoomShort = keyframes({
  '0%': { opacity: 0, transform: 'scale(0.95)' },
  '100%': { opacity: 1, transform: 'scale(1)' },
})

export const spin = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' },
})

export const flip = keyframes({
  '0%': { transform: 'rotateY(0deg)' },
  '10%': { transform: 'rotateY(0deg)' },
  '50%': { transform: 'rotateY(180deg)' },
  '100%': { transform: 'rotateY(360deg)' },
})

export const flipHalf = keyframes({
  '0%': { transform: 'rotateY(0deg)' },
  '100%': { transform: 'rotateY(180deg)' },
})

export const downUp = keyframes({
  '0%': { transform: 'translateY(0px)' },
  '50%': { transform: 'translateY(5px)' },
  '100%': { transform: 'translateY(0px)' },
})

export const shake = keyframes({
  '25%, 75%': { transform: 'translate3d(-2px, 0, 0) rotate(-25deg)', },
  '50%': { transform: 'translate3d(2px, 0, 0) rotate(25deg)', },
})

export const shakeBig = keyframes({
  '10%, 90%': { transform: 'translate3d(-1px, 0, 0)', },
  '20%, 80%': { transform: 'translate3d(2px, 0, 0)', },
  '30%, 50%, 70%': { transform: 'translate3d(-4px, 0, 0)', },
  '40%, 60%': { transform: 'translate3d(4px, 0, 0)', },
})

