import React from 'react'
import * as css from 'styles'
import styled from '@emotion/styled'
import propStyles from 'prop-styles'

import FadeIn from 'components/Animated/FadeIn'
import Spinner from './Spinner'

const LoadingWrapper = styled('div')({
  // padding: css.space.xl,
  width: '100%',
  height: '100%', // height: '400px',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  textAlign: 'center',
  fontSize: css.font.r,
  color: css.color.primary,
  overflow: 'hidden',
},
  ({ height }) => ({
    height: height || '100%',
  }),
  propStyles({
    inline: { display: 'inline-block',}
  })
)

const Message = styled('div')({
  marginTop: css.space.s,
  color: css.color.gray,
})


export const Loading = ({ 
  status = 'Pending',
  children, 
  message, 
  errormessage = 'Failed to load', 
  height, 
  small, 
  medium, 
  inline, 
  nospinner,
  style
}) => {
  return {
    Idle: null,
    Pending: (
      <LoadingWrapper height={height} inline={inline} style={style}>
        <FadeIn delay="500ms">
          {!nospinner &&
            <Spinner
              small={small}
              medium={medium}
            />
          }
          {message &&
            <FadeIn delay="200ms">
              <Message>{message}</Message>
            </FadeIn>
          }
        </FadeIn>
      </LoadingWrapper>
    ),
    Success: (
      <FadeIn>
        {children}
      </FadeIn>
    ),
    Error: (
      <LoadingWrapper height={height} inline={inline} style={style}>
        <FadeIn>
          <Message color={css.color.gray}>{errormessage}</Message>
        </FadeIn>
      </LoadingWrapper>
    )
  }[status]
}

export default Loading
