import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import * as css from 'styles'

import { Button, Input, FadeIn, Link } from 'components'

export const CreateAccountForm = ({ 
  login = false, 
  disabled = false, 
  success = false,
  submit_text = 'Get Started',
  flow = 'default',
  confirmation = false,
}) => {
  const dispatch = useDispatch()
  const { data: { user, valid }, error: { errors } } = useSelector(store => store.auth)
  const { status } = useSelector(store => store.auth )
  const { register, handleSubmit } = useForm() // watch, errors

  const onSubmit = data => { 
    dispatch.auth.createAccount({ 
      data,
      success,
      login,
      flow,
      confirmation,
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{position: 'relative'}} disabled={disabled}>
      <fieldset disabled={disabled}>
        {/* <Input register={register} name="full_name" type="text" placeholder="Full name" required={true} errors={errors}/> */}
        <Input register={register} name="email" type="email" placeholder="Email address" required={true} errors={errors}/>
        <Input register={register} name="password" type="password" placeholder="Password" required={true} errors={errors}/>
        <Button small fit type="submit" status={status} disabled={status==='Pending'}>{submit_text}</Button>
        <div style={{fontFamily: css.font.heading, fontSize: '0.7em', margin: '1em 0 0', color: css.color.darkgray, textAlign: 'center', padding: '0 3em'}}>
          You agree to our <Link to="/articles/terms-of-service" target="_blank" style={{ textDecoration: 'underline' }}>Terms of Service</Link> when creating an account.
        </div>
      </fieldset>
    </form>
  )
}

export default CreateAccountForm
