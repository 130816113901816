// Set Breakpoints - used below.
export const breakpoints = {
  xs: 320,
  s: 650,
  m: 800,
  l: 1200,
  xl: 1400,
}

// All media breakpoint in CSS used across the app.
//   X_only = between these two breakpoints
//   X_down = this breakpoint and smaller screens
//   X_up = this breakpoint and larger screens
const makeMediaQueries = (breakpoints) => {
  const queries = {}
  const keys = Object.keys(breakpoints)
  for (var i = 0; i < keys.length; i++) {
    queries[`${keys[i]}_down`] = `@media only screen and (max-width: ${breakpoints[keys[i]]}px)`
    queries[`${keys[i]}_up`]   = `@media only screen and (min-width: ${breakpoints[keys[i]] + 1}px)`
    queries[`${keys[i]}_only`] = `@media only screen and (min-width: ${breakpoints[keys[i-1]]+1 || 0}px) and (max-width: ${breakpoints[keys[i]]}px)`
    if (i===0) { delete queries[`${keys[i]}_`]; }
    if (i===keys.length-1) {}
  }
  return queries
}

export const breakpoint = makeMediaQueries(breakpoints)
