import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import * as css from '../styles'
import propStyles from 'prop-styles'
import settings from 'settings'

import { Link, FadeIn, Icon } from 'components'
import Logo from 'images/Logo'

const Header = ({ left_links, right_links }) => {
  // Header State
  const [state, setState] = useState({
    menuClosed: true,
    scrolling: false,
  })

  return(
    <>
      <HeaderWrapper 
        menuOpen={!state.menuClosed} 
        // scrolling={state.scrolling}
      >

        <DesktopNav>
          <div>
            {left_links}
          </div>

          <div style={{ textAlign: 'center' }}>
            <div style={{ position: 'relative', top: '1px' }}><Logo height="14px" /></div>
          </div>

          <div style={{ textAlign: 'right' }}>
            {right_links}
          </div>
        </DesktopNav>

        <MobileNav>
          <div>
            {left_links}
          </div>

          <div style={{textAlign: 'center'}}>
            <div style={{ position: 'relative', top: '3px' }}><Logo height="14px"/></div>
          </div>

          <div style={{ textAlign: 'right' }}>
            {right_links}
          </div>
        </MobileNav>

      </HeaderWrapper>
    </>
  )
}

const HeaderWrapper = styled('header')({
  position: 'fixed',
  zIndex: css.z.header,
  top: 0,
  left: 0,
  right: 0,
  fontFamily: css.font.heading,
  boxShadow: `2px 2px 10px rgba(0,0,0,0.025)`,
  transition: css.transition.fast,
  background: css.color.white,

  height: `${css.heights.header}px`,
  // lineHeight: `${css.heights.header}px`,
  [css.breakpoint.m_down]: {
    height: `${css.heights.header_s}px`,
    lineHeight: `${css.heights.header_s}px`,
  },
}, propStyles({
  scrolling: {
  },
  menuOpen: {
    [css.breakpoint.m_down]: {
      background: css.color.white,
    }
  },
}))

const DesktopNav = styled('div')({
  ...css.widths.outer,
  display: 'grid',
  gridTemplateColumns: '1fr 130px 1fr',
  alignItems: 'center',
  // justifyContent: 'center',
  height: '100%',
  lineHeight: '1em',

  [css.breakpoint.m_down]: {
    display: 'none',
  },
}, propStyles({
  twoColumns: {
    gridTemplateColumns: '220px 1fr',
  }
}))

const MobileNav = styled('div')({
  // position: 'relative',
  ...css.widths.outer,
  // margin: css.margins.page_mobile,
  fontSize: css.font.s,
  lineHeight: `${css.heights.header_s}px`,

  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',

  [css.breakpoint.m_up]: {
    display: 'none',
  },
})


export default Header
