import { makeVisibleData } from '../plugins/filters/filters.selectors'
import Settings from 'settings'

export const works = {
  name: 'works',
  request: {
    url: `${Settings.api}/works`,
  },
  state: {
    data: {
      works: [],
    }, // items needs an empty array, so we override default 'data' here
  },
  filters: {
    cat: (item, test) => item.categories.includes(test),
    search: (item, test) => [item.title, item.year, item.width, item.height].join().toLowerCase().includes(test.toLowerCase()),
  },
  // urlQuery: true, // e.g: /works?gender=male
}

export const getVisibleData = makeVisibleData(works.filters, ({ data }) => data ? data.works : [])