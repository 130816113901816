import * as css from 'styles'
import styled from '@emotion/styled'
// import propStyles from 'prop-styles'

const InputWrapper = styled('div')({
  width: `100%`,
  height: css.input.height,
  position: 'relative',

  // Breakpoints
  [css.breakpoint.s_down]: {
    height: css.input.s_height,
  },
})

export default InputWrapper
