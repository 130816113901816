import Settings from 'settings';
import Cookies from 'js-cookie';
import { dispatch } from 'redux/store';
import { navigate } from 'components';

// TODO add amplitude.getInstance().setUserId('USER_ID');

export const checkout = {
  name: 'checkout',
  request: {
    url: `${Settings.api}/checkout:endpoint`,
  },
  state: {
    data: {
      user: {
        email: null,
        first_name: null,
        last_name: null,
      },
      shipping_address_id: null,
      billing_address_id: null,
      payment_method: null,
      cards: [],
      addresses: [],
    },
  },
  reducers: {
    // Update data in store
    addCardToList: (state, data) => ({
      ...state,
      data: { ...state.data, cards: data.constructor === Object ? [...state.data.cards, data] : [...data] },
    }),
    addAddressToList: (state, data) => ({
      ...state,
      data: { ...state.data, addresses: data.constructor === Object ? [...state.data.addresses, data] : [...data] },
    }),
    addCartData: (state, data) => ({ ...state, data: { ...state.data, ...data } }),

    // Add references to selected card/address in store
    selectShippingAddress: (state, data) => ({ ...state, data: { ...state.data, shipping_address_id: data } }),
    selectPaymentMethod: (state, data) => ({ ...state, data: { ...state.data, payment_method: data } }),
    selectDefaultPaymentMethod: (state, data) => ({ ...state, data: { ...state.data, default_payment_method: data } }),
  },
  effects: {
    // Account Info
    loginOrCreateAccount: (payload, store) =>
      dispatch({
        type: 'auth/login',
        payload: {
          data: payload.data,
          error: (error) => {
            console.log('error from loginOrCreateAccount');
          },
        },
      }),

    // Addresses
    getAddresses: (payload, store) =>
      dispatch({
        type: 'checkout/fetch',
        payload: {
          url: `${Settings.api}/addresses`,
          success: () => dispatch({ type: 'checkout/resetStatus' }),
        },
      }),
    createAddress: (payload, store) =>
      dispatch({
        type: 'checkout/fetch',
        payload: {
          url: `${Settings.api}/addresses`,
          method: 'post',
          data: payload.data,
          success: (data) => {
            dispatch({ type: 'checkout/setShippingAddress', payload: data.address_id });
            // dispatch({ type: 'checkout/selectShippingAddress', payload: data.address_id })
            // navigate('/checkout/payment')
            // dispatch({ type: 'checkout/resetStatus' })
          },
        },
      }),
    setShippingAddress: (payload, store) =>
      dispatch({
        type: 'checkout/fetch',
        payload: {
          url: `${Settings.api}/cart/address/shipping`,
          method: 'post',
          data: { id: payload },
          success: (data) => {
            dispatch({ type: 'checkout/selectShippingAddress', payload: payload });
            navigate('/checkout/payment');
            dispatch({ type: 'checkout/resetStatus' });
          },
        },
      }),

    // Payment Methods
    getPaymentMethods: (payload, store) =>
      dispatch({
        type: 'checkout/fetch',
        payload: {
          url: `${Settings.api}/payment_methods`,
          success: () => dispatch({ type: 'checkout/resetStatus' }),
        },
      }),
    createPaymentMethod: (payload, store) =>
      dispatch({
        type: 'checkout/fetch',
        payload: {
          url: `${Settings.api}/payment_methods`,
          method: 'post',
          data: { stripe_token: payload.stripe_token.id },
          success: (data) => {
            dispatch({
              type: payload.default ? 'checkout/setDefaultPaymentMethod' : 'checkout/setPaymentMethod',
              payload: { card_id: data.payment_method },
            });
            if (payload.success) payload.success();
          },
        },
      }),
    setPaymentMethod: (payload, store) =>
      dispatch({
        type: 'checkout/fetch',
        payload: {
          url: `${Settings.api}/cart/payment`,
          method: 'post',
          data: { card_id: payload.card_id },
          success: (data) => {
            dispatch({ type: 'checkout/selectPaymentMethod', payload: payload.card_id });
            dispatch({ type: 'checkout/resetStatus' });
            navigate('/checkout/review');
          },
        },
      }),
    setDefaultPaymentMethod: (payload, store) =>
      dispatch({
        type: 'checkout/fetch',
        payload: {
          url: `${Settings.api}/payment_methods`,
          method: 'patch',
          data: { card_id: payload.card_id },
          success: (data) => {
            dispatch({ type: 'checkout/selectDefaultPaymentMethod', payload: payload.card_id });
            dispatch({ type: 'checkout/resetStatus' });
          },
        },
      }),

    // Create Order
    confirmOrder: (payload, store) =>
      dispatch({
        type: 'checkout/fetch',
        payload: {
          url: `${Settings.api}/cart/confirm`,
          success: (data) => {
            navigate(`/orders/${data.id}`);
            dispatch({ type: 'cart/reset' });
            dispatch({ type: 'checkout/reset' });
            dispatch({ type: 'current_user/fetch', payload: { endpoint: '/all' } });
          },
          error: (error) => {
            // Navigate to whichever panel is incorrect, highlight errors
          },
        },
      }),
  },
};
