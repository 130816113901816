import * as css from 'styles'
import styled from '@emotion/styled'
// import propStyles from 'prop-styles'

const FieldLabel = styled('label')({
  color: css.color.darkgray,
  fontFamily: css.font.heading,
  fontSize: '0.7em',
  // textAlign: 'center',
  display: 'block',
  marginTop: '-0.5em',
})

export default FieldLabel
