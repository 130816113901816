import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'
import amplitude from 'amplitude-js'
import LogRocket from 'logrocket'

// const events = ({ getState, dispatch }) => next => action => {
const broadcastEvents = ({ dispatch }) => next => action => {
  next(action)

  if (action.type === 'event/conversion') {
    let fb = action.payload.facebook;
    let goog = action.payload.google;
    let amp = action.payload.amplitude;

    // console.log('event/conversion payload: ');
    // console.log(action.payload);
    
    // Amplitude
    if (amp) {
      let name = amp.name;
      let properties = amp.properties || {};
        
      amplitude.getInstance().logEvent(name, properties);
    }

    // Google Analytics
    if (goog) {
      let args = {
        category: goog.eventCategory,
        action:   goog.eventAction,
      }
      if (goog.eventLabel) args.label = goog.eventLabel
      
      ReactGA.event(args);
    }
    
    // Facebook Pixel
    if (fb) {
      let verb = fb.eventType === 'standard' ? 'track' : 'trackCustom';

      ReactPixel[verb](fb.eventName, fb.data) // TODO add data
    }
  }

  if (action.type.includes('current_user')) {
    const user = action.payload ? action.payload.user : false

    if (user && !user.is_guest) {
      LogRocket.identify(user.id, {
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        userType: user.display_role
      });
    }
  }
}

export default broadcastEvents 
