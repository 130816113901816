import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import amplitude from 'amplitude-js';
import LogRocket from 'logrocket';
import isbot from 'isbot';
import * as Sentry from '@sentry/browser';

// This file contains:
// 1. Set API base URL
// 2. Set API keys for 3rd party services e.g: Google Maps
// 3. Meta data defaults

const origin = document.location.origin;
const host = document.location.host;
const is_local = host.includes('localhost') || host.includes('lvh') || host.includes('keanu.local');
const is_staging = host.includes('artschmo.com') || host.includes('artbaby-staging.netlify.app');
const is_production = host.includes('artinres.com') || host.includes('artbaby-production.netlify.app');
const local_path = host.includes('keanu.local') ? 'keanu.local:3000' : 'lvh.me:3000';
const api_path = is_local
  ? `http://api.${local_path}`
  : `https://api.${is_production ? 'artinres.com' : 'artschmo.com'}`;
const meta_path = is_local ? `http://meta.${local_path}` : `https://meta.${host}`;
const artists_path = is_local ? `http://artists.${local_path}` : `https://artists.${host}`;

export const settings = {
  origin: origin,
  api: api_path + '/v2',
  env: is_production ? 'production' : is_staging ? 'staging' : 'development',
  artists_path,
  meta_path,
};

export const keys = {
  google: settings.env === 'production' ? 'UA-120062443-1' : 'UA-120062443-2',
  facebook: settings.env === 'production' ? '362094577646220' : '430918247675463',
  amplitude: settings.env === 'production' ? '1ff428ecf49475895e8ac7127b4956b1' : '133f217d35864f1df1ecffe21c25f5bf',
  stripe: settings.env === 'production' ? 'pk_live_NMMg3CviEzydJAeNpDC276YA' : 'pk_test_7OUp5hg9RQH05JIPcNIOAlMF',
};

export const meta = {
  title: 'Art in Res',
  separator: '-',
  description: `Find art you will love, pay in installments, and directly support artists when you use Art in Res.`,
};

const allSettings = {
  ...settings,
  keys,
  meta,
};

export default allSettings;

// Setup trackers
ReactGA.initialize(keys.google);
ReactPixel.init(keys.facebook);
amplitude.getInstance().init(keys.amplitude, null, {
  // optional configuration options
  saveEvents: true,
  includeUtm: true,
  includeReferrer: true,
  trackingOptions: {
    city: true,
    ip_address: true,
  },
});

// Setup log/bug tracking - only in production
if (settings.env === 'production' && !isbot(navigator.userAgent)) {
  LogRocket.init('yuvrlr/art-in-res');
  Sentry.init({ dsn: 'https://0cdcb78356d54f37a5ee4f1bac897a25@o367158.ingest.sentry.io/5170392' });
  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra('sessionURL', sessionURL);
    });

    ReactGA.event({
      category: 'LogRocket',
      action: sessionURL,
    });

    amplitude.logEvent('LogRocket', { sessionURL: sessionURL });
  });
}

// Setup Crisp
window.$crisp = [];
window.CRISP_WEBSITE_ID = 'a2e92282-7be0-4f77-846a-d4a35925847b';
(function () {
  var d = document;
  var s = d.createElement('script');
  s.src = 'https://client.crisp.chat/l.js';
  s.async = 1;
  d.getElementsByTagName('head')[0].appendChild(s);
})();
window.$crisp.push(['do', 'chat:hide']);
