import React from 'react'
import { useSelector } from 'react-redux'
import * as css from 'styles'
import styled from '@emotion/styled'
import Cookies from 'js-cookie'

import { Link } from 'components'

export const Meta = () => {
  const host = window.location.host.includes('lvh') ? `http://meta.lvh.me:3000` : `https://meta.${window.location.host}`
  const { data: { user }, status } = useSelector(store => store.current_user)
  const metaToken = Cookies.get('meta_auth_token')
  const authToken = Cookies.get('auth_token')
  const hasMetaToken = metaToken && (metaToken !== undefined) && (metaToken !== 'undefined')
  const isSuperadmin = hasMetaToken && (metaToken === authToken) //useSelector(store => store.auth.data.roles.includes('Superadmin'))
  const isLoggedInAs = !isSuperadmin && hasMetaToken && (status === 'Success')

  return (
    <>
      {isSuperadmin && <MetaButton host={host}/>}
      {isLoggedInAs && <MetaUserBar host={host} user={user}/>}
    </>
  )
}

const MetaUserBarPositioner = styled('div')({
  position: 'fixed',
  zIndex: css.z.admin,
  bottom: 0,
  margin: '0 auto',
  width: '100%',
})

const MetaUserBarWrapper = styled('div')({
  textAlign: 'center',
  background: css.color.verydarkblue,
  color: css.color.white,
  padding: '0 1em',
  lineHeight: '30px',
  maxHeight: '30px',
  fontSize: '0.8em',
  animation: `${css.fadeUp} 1s ease`,
  fontFamily: css.font.heading,
  transition: css.transition.fast,
  '&:hover':{
    background: css.color.darkblue,
  },
  [css.breakpoint.m_down]: {
    fontSize: '0.9em',
    padding: '0 1em !important',
  },
})

const MetaUserBar = ({ host, user: { first_name, last_name, slug, id } }) => {
  return (
    <MetaUserBarPositioner>
      <a href={`${host}/users/${slug || id}`}>
        <MetaUserBarWrapper>Signed in as {first_name} {last_name}</MetaUserBarWrapper>
      </a>
    </MetaUserBarPositioner>
  )
}

const MetaButton = ({ host }) => {
  const url = host + window.location.pathname
  const isArtistPage = window.location.pathname.includes('/artists/')
  return (
    <div style={{
      fontFamily: css.font.heading,
      fontSize: '0.8em',
      padding: '1em',
      // background: 'red',
      position: 'fixed',
      bottom: '0',
      right: '0',
      zIndex: 120,
    }}>
      {isArtistPage && <a href={host + window.location.pathname.replace(`/artists/`,`/session/edit?user_id=`)} target="_blank" rel="noopener noreferrer">LOGIN-AS</a>}
      &nbsp;&nbsp;&nbsp;
      <a href={url} target="_blank" rel="noopener noreferrer">META</a>
    </div>
  )
}

export default Meta
