import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as css from 'styles'
import Notice from './Notice'
import pluralize from 'utils/pluralize'

import { FadeIn } from 'components'

const SaveYourLikes = () => {
  const { modals } = useDispatch()
  const { location, current_user, likes, follows } = useSelector(store=>store)
  const num_likes = likes.data.length
  const num_follows = follows.data.length
  const hasSavedStuff = (num_likes || num_follows)
  
  const isLoggedIn = current_user.data.user.is_guest === false
  const isIntroPage5 = location.pathname === '/intro/5'
  const isWelcomeFlow = location.pathname.includes('/welcome')
  const isCartPage = location.pathname.includes('/cart')
  const isPendingUserData = current_user.status === 'Pending' && current_user.status === 'Idle'

  const showNotice = hasSavedStuff && !isLoggedIn && !isIntroPage5 && !isWelcomeFlow && !isPendingUserData && !isCartPage

  const createAccount = (e) => {
    e.preventDefault()
    modals.open({ name: 'createAccount' })
  }

  return (
    <Notice show={showNotice} background={css.color.blue} position="float">
      <div> 
        <a onClick={createAccount} href="/users">Create an account</a>
      </div>
      <div style={{marginTop: '0.3em'}}>
        to save your&nbsp;

        {num_likes ?
          <FadeIn inline>
            <span style={{ padding: '0 0.25em', background: css.color.darkblue, borderRadius: '4px' }}>{num_likes}</span>
            &nbsp;{pluralize(num_likes, 'like')}
          </FadeIn>
        : ''} 

        {num_follows && num_likes ? ', and ' : ''}

        {num_follows ? 
          <FadeIn inline>
            <span style={{ padding: '0 0.25em', background: css.color.darkblue, borderRadius: '4px' }}>{num_follows}</span> 
            &nbsp;{pluralize(num_follows, 'follow')}
          </FadeIn>
        : ''} 
        
      </div>
    </Notice>
  )
}

export default SaveYourLikes