import { filtersState, filtersReducers, filtersActions } from './filters'
import { makeVisibleData, filterData } from './filters.selectors'

const filtersModel = (model) => {
  if (model.name && model.filters) {
    // Cache initial states
    const filtersInitialState = filtersState()

    // Add additional structure to model
    model.state = { ...model.state, ...filtersInitialState }
    model.reducers = { ...model.reducers, ...filtersReducers(filtersInitialState) }
    model.effects = { ...model.effects, ...filtersActions(model.name) }

    const getVisibleData = makeVisibleData(model.filters)

    model.selectors = { ...model.selectors,
      getVisibleData, // returns function to process data with active filters
      // filterData: state => {
      //   console.log(state)
      //   return state
      // } // filterData(state, model.filters),
      // filterData: state => filterData(state, model.filters),
    }
  }
  return model
}

export default filtersModel
