import React from 'react'
import styled from '@emotion/styled'
import * as css from '../styles'

export const Hr = () => (
  <HrWrapper>•••</HrWrapper>
)

const HrWrapper = styled('div')({
  margin: '0em 0',
  border: '0',
  height: 'auto',
  textAlign: 'center',
  fontSize: '2em',
  letterSpacing: '0.5em',
  color: css.color.offwhitedark3,
})

export default Hr