// import { dispatch } from '@rematch/core'
import { dispatch } from 'redux/store'

// handle state changes with impure functions.
// use async/await for async actions

export const apiActions = ({ name, request }) => ({
  // store - is injected from '@rematch/core' - or redux, haven't traced it.
  // payload - set in mapDispatch inside component, e.g: {body, id}
  // name, request - are defined in your model/apiFactory
  reset: (payload, store) => dispatch[name].initial(),
  fetch: (payload, store) => dispatch({ type: `${name}/request`, name, request, payload: payload || {} }),
  reload: (payload, store) => dispatch({ reload: true, type: `${name}/request`, name, request, payload: payload || {} }),
})

export default apiActions
