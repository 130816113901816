import React from 'react'
import * as css from 'styles'
import styled from '@emotion/styled'
// import propStyles from 'prop-styles'

import InputWrapper from './InputWrapper'
import { Icon } from 'components'

const style = {
  width: '100%',
  height: '100%',
  paddingLeft: 0, //`${css.space.s} !important`,
  paddingRight: 0, //`${css.space.s} !important`,
  // marginLeft: `-${css.space.s} !important`,
  // marginRight: `-${css.space.s} !important`,
  border: 0,
  background: 'transparent !important',
  appearance: 'none',
  position: 'relative',
}

const SelectWrapper = styled('div')({
  width: '100%',
  height: '100%',
  position: 'relative',
  background: css.color.white,
  
  // '&:after':{
  '& .select-icon':{
    transition: css.transition.fast,
    // content: ''"▼"', // this is a down triangle ▼  '\25B2' - needs '' around ""
    // content: "poop",
    pointerEvents: 'none',
    position: 'absolute',
    display: 'block',
    // background: 'red',
    zIndex: 3,
    right: css.space.s,
    top: `calc(${css.space.xs} - 2px)`,
    fontSize: css.font.l,
    height: '100%',
    lineHeight: '100%',
    color: css.color.gray,

    [css.breakpoint.s_down]:{
      right: css.space.s,
      top: '0.425em',
      fontSize: css.font.m,
    },
  },

  '& select':{
    cursor: 'pointer',
  },

  '&:hover, &:focus': {
    '& .select-icon':{
      color: css.color.black,
    },
  },
})

const Select = (props) => (
  <InputWrapper>
    <SelectWrapper>
      <div className="select-icon"><Icon type="arrow" angle="90deg"/></div>
      <select {...props} ref={props.register} style={{...props.style, position: 'relative', zIndex: 1}}>{props.children}</select>
    </SelectWrapper>
  </InputWrapper>
)

export default styled(Select)(style)
