// Filter an array of objects by query, and pre-defined filters.
// Query object - { gender: 'female', search: 'justin' }
// Filters object - { gender: (item, test) => item.gender === test, }
const filterByQuery = (query, filters) => (item) => {
  // Returns filter applying all filter functions
  return Object.keys(query).every(filter =>
    // If filter doesn't exist, just return all items
    filters.hasOwnProperty(filter) ? filters[filter](item, query[filter]) : true
  )
}
export default filterByQuery
