import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import * as css from 'styles'
import styled from '@emotion/styled'
import propStyles from 'prop-styles'
import { keyframes } from '@emotion/core'
import anime from 'animejs'

import Layout from 'layouts/Default'
import { CenterContent, FadeIn } from 'components'


const arrow1 = keyframes({
  '0%': { transform: 'scale(0.5) rotate(-90deg) translate(36px, -36px)', opacity: 1, },
  '50%': { transform: 'scale(1) rotate(0deg) translate(30px, -30px)', opacity: 1, },
  '100%': { transform: 'translate(0px, 0px)' },
})

const arrow2 = keyframes({
  '0%': { transform: 'scale(0.5) rotate(-90deg) translate(-36px, 36px)', opacity: 1, },
  '50%': { transform: 'scale(1) rotate(0deg) translate(-30px, 30px)', opacity: 1, },
  '100%': { transform: 'translate(0px, 0px)' },
})

const line = keyframes({
  '0%': { transform: 'scale(1) rotate(0deg)', opacity: 0 },
  '80%': { transform: 'scale(1) rotate(0deg)', opacity: 0 },
  // '30%': { transform: 'scale(1) rotate(0deg)' },
  '100%': { transform: 'scale(1) rotate(0deg)', opacity: 1, },
})

const SVG = styled('svg')({
  '& .group': { transformOrigin: '96px 96px', },
  '& .arrow1':{ transformOrigin: '96px 96px', },
  '& .arrow2':{ transformOrigin: '96px 96px', },
  '& .line':  { transformOrigin: '96px 96px', },

  '& #arrow1':{ animation: `${arrow1} 2s ease infinite alternate` },
  '& #arrow2':{ animation: `${arrow2} 2s ease infinite alternate` },
  '& #line':  { animation: `${line} 2s ease infinite alternate` },
})

const setup1 = () => {
  // Timeline Setup
  var a1 = '#arrow1-b'
  var a2 = '#arrow2-b'
  var l = '#line-b'
  var g = '#group-b'
  var tl = anime.timeline({
    // duration: 2000,
    loop: true,
    // direction: 'alternate',
    easing: 'easeInOutQuad',
    delay: 0,
    autoplay: false,
  });
  
  // Positioning Setup
  tl.set(g, { rotate: -90, opacity: 0, scale: 0.8 })
  tl.set(a1, { translateX: 35, translateY: -35 })
  tl.set(a2, { translateX: -35, translateY: 35 })
  tl.set(l, { strokeDashoffset: -42.5, strokeDasharray: '0 90', opacity: 0 })
  
  // Rotate
  tl.add({ targets: g, duration: 800, rotate: -90, delay: 1000, opacity: 1, scale: 1, easing: 'easeOutBack' })
  tl.add({ targets: g, duration: 800, rotate: 0, easing: 'easeOutExpo' }, '-=400')
  tl.add({ targets: a1, duration: 600, translateX: 30, translateY: -30 }, '-=900')
  tl.add({ targets: a2, duration: 600, translateX: -30, translateY: 30 }, '-=900')
  
  // Expand
  tl.add({ targets: a1, duration: 1000, translateX: 0, translateY: 0 })
  tl.add({ targets: a2, duration: 1000, translateX: 0, translateY: 0 }, '-=1000')
  tl.add({ targets: l, duration: 750, strokeDashoffset: 0, strokeDasharray: '90 90', opacity: 1 }, '-=800')
  tl.add({ targets: g, duration: 500, delay: 2000, opacity: 0, })

  return tl
}

const setup = () => {
  // Timeline Setup
  var a1 = '#arrow1-b'
  var a2 = '#arrow2-b'
  var l = '#line-b'
  var g = '#group-b'
  var tl = anime.timeline({
    // duration: 2000,
    loop: true,
    // direction: 'alternate',
    easing: 'easeInOutQuad',
    delay: 0,
    autoplay: false,
    // autoplay: true,
  });

  // Positioning Setup
  tl.set(g, { rotate: -90, opacity: 0, scale: 0.7 })
  tl.set(a1, { translateX: 35, translateY: -35 })
  tl.set(a2, { translateX: -35, translateY: 35 })
  tl.set(l, { strokeDashoffset: -42.5, strokeDasharray: '0 90', opacity: 0 })

  // Rotate
  tl.add({ targets: g, duration: 800, rotate: -90, delay: 500, opacity: 1, scale: 1, easing: 'easeOutBack' })
  tl.add({ targets: g, duration: 800, rotate: 0, easing: 'easeOutExpo' }, '-=400')
  tl.add({ targets: a1, duration: 600, translateX: 30, translateY: -30 }, '-=900')
  tl.add({ targets: a2, duration: 600, translateX: -30, translateY: 30 }, '-=900')

  // Expand
  tl.add({ targets: a1, duration: 500, translateX: 0, translateY: 0, easing: 'easeOutExpo' })
  tl.add({ targets: a2, duration: 500, translateX: 0, translateY: 0, easing: 'easeOutExpo' }, '-=500')
  tl.add({ targets: l, duration: 350, strokeDashoffset: 0, strokeDasharray: '90 90', opacity: 1, easing: 'easeOutExpo' }, '-=400')
  tl.add({ targets: g, duration: 300, delay: 2000, opacity: 0, })

  return tl
}

export const Logo = ({
  size = '320', 
  animated = false,
  play = false,
  controls = false,
}) => {  
  const tl = setup()
  const seek = (e) => tl.seek(tl.duration * (e.target.value / 100))

  useEffect(()=>{
    // tl.seek(800)
    tl.seek(2000)
    if (animated && play) setTimeout(tl.play, 3000)
    return tl.reset
  }, [tl])

  // useEffect(()=>{
  //   if (play) {
  //     tl.play()
  //     return tl.reset
  //   }
  // },[])

  return (
    <>
      {animated ? (
        <SVG
          xmlns="http://www.w3.org/2000/svg"
          width="192"
          height="192"
          viewBox="0 0 192 192"
        >
          <path fill="#FFF" d="M0 0H192V192H0z"></path>
          <g id="group" className="group" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
            <path
              id="arrow1"
              className="arrow1"
              fill="#231F20"
              d="M43.6773773 148.322623L43.6773773 110.677377 34 101 34 158 91 158 81.3226227 148.322623z"
            ></path>
            <path
              id="arrow2"
              className="arrow2"
              fill="#231F20"
              d="M151.322623 40.6773773L151.322623 78.3226227 161 88 161 31 104 31 113.677377 40.6773773z"
            ></path>
            <path
              id="line"
              className="line"
              stroke="#231F20"
              strokeLinecap="square"
              strokeWidth="10"
              d="M127.75 64.75L65.25 127.25"
            ></path>
          </g>
        </SVG>
    ):(
        <SVG
          xmlns="http://www.w3.org/2000/svg"
          width={size}
          height={size}
          viewBox="0 0 192 192"
        >
          <path 
            // fill="#FFF" 
            fill="transparent"
            d="M0 0H192V192H0z"
          ></path>
          <g id="group-b" className="group" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
            <path
              id="arrow1-b"
              className="arrow1"
              fill="#231F20"
              d="M43.6773773 148.322623L43.6773773 110.677377 34 101 34 158 91 158 81.3226227 148.322623z"
            ></path>
            <path
              id="arrow2-b"
              className="arrow2"
              fill="#231F20"
              d="M151.322623 40.6773773L151.322623 78.3226227 161 88 161 31 104 31 113.677377 40.6773773z"
            ></path>
            <path
              id="line-b"
              className="line"
              stroke="#231F20"
              strokeLinecap="square"
              strokeWidth="10"
              d="M127.75 64.75L65.25 127.25"
            ></path>
          </g>
        </SVG>
      )}

      {controls && (
        <div>
          <button onClick={tl.play}>play</button>
          <button onClick={tl.reset}>reset</button>
          &nbsp;
          <input id="progress" type="range" min="0" max="100" defaultValue="0" onChange={seek} />
        </div>
      )}
    </>
  )
}

const LogoScreenWrapper = styled('div')({
  transition: css.transition.xfast,
  // transition: css.transition.xslow,
  background: css.color.white,
  // background: css.color.blue,
  position: 'fixed',
  zIndex: css.z.modal-2,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  opacity: 1,
  // border: `1em solid ${css.color.offwhite}`
}, ({status})=>{
  return status === 'Success' || status === 'Error' ? {
  // return status === 'poop' ? {
    opacity: 0,
    pointerEvents: 'none',
    userSelect: 'none',
  } : {}
})

class PureLogo extends React.PureComponent {
  render () {
    return (
      <Logo size="130"/>
    )
  }
}

export const LogoScreen = ({ status = 'Pending' }) => {  
  const statusCheck = (['checkout'].some(s => window.location.pathname.includes(s))) ? 'Success' : status

  return (
    <LogoScreenWrapper status={statusCheck}>
      <CenterContent height="100vh">
        {/* <FadeIn zoom> */}
          {/* <Logo size="150"/> */}
          <PureLogo/>
        {/* </FadeIn> */}
      </CenterContent>
    </LogoScreenWrapper>
  )
}

const LogoAnimations = () => {
  return (
    <Layout showfooter={false}>
      <CenterContent style={{height: '80vh'}}>

        <Logo/>

      </CenterContent>
    </Layout>
  )
}


export default LogoAnimations