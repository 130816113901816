import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import * as css from '../styles'
import propStyles from 'prop-styles'
import settings from 'settings'

import { Link, FadeIn, Icon, usePrevious } from 'components'
import Logo from 'images/Logo'
import user_icon from 'images/mr-shoulders.svg'

const Header = (props) => {
  const dispatch = useDispatch()

  // Location State
  const location = useSelector(store => store.location)
  const isHomePage = location.type === 'route/home'
  const isTransparent = props.transparent || false

  // User State
  const { roles } = useSelector(store => store.auth.data)
  const { user: { first_name, is_artist, is_guest = true, is_pending = false, is_admin = false, slug }, unread_messages = 0, requests = 0, available_funds } = useSelector(store => store.current_user.data)
  const isAuthorized = !is_guest // auth_token && !is_guest
  const isArtist = roles.includes('Artist') || is_artist
  const isAdmin = roles.includes('Admin') || roles.includes('Superadmin') || is_admin
  const isPending = is_pending
  
  // Like/Save State
  const likes = useSelector(store => store.likes.data.length)
  const likeSaveStatus = 'Idle' // usePrevious(likes) < likes ? 'Success' : 'Idle'
  
  // Cart State
  const cart_items_from_store = useSelector(store => store.cart.data.items)
  const cart = cart_items_from_store.length

  // Header State
  const [state, setState] = useState({
    menuClosed: true,
    scrolling: false,
  })
  const isDesktop = () => window.innerWidth > css.breakpoints.m
  const transparent = !state.scrolling && isTransparent && isDesktop()
  // const dashboard_link = isArtist ? `${settings.artists_path}/dashboard` : '/dashboard'
  const dashboard_link = isArtist && !isAdmin ? `/works` : '/'

  // Header Functions
  const logout = (e) => {
    e.preventDefault()
    dispatch.auth.logout()
    closeMenu()
  }

  const closeMenu = (e) => {
    setState({ menuClosed: true })
  }

  const toggleMenu = (e) => {
    if (e) e.preventDefault();
    setState({ menuClosed: !state.menuClosed })
  }

  const scrollWatcher = (e) => {
    if (window.scrollY > 20) {
      (state.scrolling === false) && setState({ ...state, scrolling: true })
    } else {
      (state.scrolling === true) && setState({ ...state, scrolling: false })
    }
  }

  useEffect((state) => {
    window.addEventListener('scroll', scrollWatcher)
    return () => window.removeEventListener('scroll', scrollWatcher)
  },[state])

  // Setup link groups
  const browse_links = [
    { title: 'Discover', icon: 'work', url: '/artworks', position: 'left', submenu: [
      { title: 'Search', url: '/search', mobileOnly: true },
      { title: 'Artworks', url: '/artworks' },
      { title: 'Artists', url: '/artists' },
      { title: 'Taste Quiz', url: '/recommendations' },
    ] },
    { title: 'Articles', icon: 'detail', url: '/articles', position: 'left', submenu: [
      { title: 'All Articles', icon: 'detail', url: '/articles' },
      { title: 'Curations', icon: 'detail', url: '/curations' },
      { title: 'Inside The Studio', icon: 'detail', url: '/inside-the-studio' },
      { title: 'Collector Education', icon: 'detail', url: '/guides' },
      { title: 'How It Works', url: '/how-it-works', icon: 'question' },
    ] },
    { title: 'Search', icon: 'search', url: '/search', hide_text: true },
  ]

  const account_links = isAuthorized ? [
    { title: 'Messages', count: unread_messages, url: '/messages' },
    isArtist && { title: 'Fans', url: '/fans' },
    isArtist && { title: 'Sales', count: requests, url: '/sales' },
    { title: 'Purchases', url: '/purchases' },
    { break: true },
    { title: 'Gift Cards', url: '/gift-cards' },
    { title: 'Invite Friends', url: '/invite' },
    { title: 'My Account', url: '/account/profile' },
    isArtist && { title: 'My Profile', url: `/artists/${slug}` },
    { break: true },
    { title: 'Logout', url: '/logout', onClick: logout },
  ] : [
    { title: 'Login', icon: 'mrshoulders-outlined', url: '/login' },
    { title: 'Create Account', url: '/create-account', feature: true },
    { title: 'Artist Application', url: '/artist-application' },
  ]

  const shop_links = [
    { title: 'Cart', icon: 'cart', url: '/cart', icon_data: {count: cart}
      // submenu: [
      //   {text: (
      //     <div style={{ lineHeight: '1.4em', fontSize: '0.8em', padding: '0.5em 0', width: '150px', margin: '0 auto' }}>
      //       You have {cart} item(s) in your cart.
      //     </div>
      //   )}
      // ] 
    },
  ]

  const credit_links = available_funds ? [
    {
      title: `$${available_funds} credit`,
      feature: true,
      onClick: (e) => e.preventDefault(),
      submenu: [
        { 
          onClick: (e) => e.preventDefault(),
          text: (
            <div style={{ textAlign: 'center', lineHeight: '1.4em', fontSize: '0.8em', padding: '0.5em 0', width: '150px', margin: '0 auto' }}>
              You currently have:
              <div style={{ background: css.color.lightblue, color: css.color.white, width: '100px', height: '100px', borderRadius: '50%', lineHeight: '105px', margin: '0 auto', marginTop: '0.25em', marginBottom: '0.25em', fontSize: '2.2em' }}>
                ${available_funds}
              </div>
              In your account to spend on any artwork.
            </div>
          ),
        }
      ]
    },
  ] : []

  const artist_links = isPending ? [
    { title: 'Application', url: '/works' },
  ] : isArtist ? [
    { title: 'Work', icon: 'work', url: '/works' },
  ] : []

  const saved_links = [
    { title: 'Likes', url: '/likes', },
    { title: 'Following', url: '/following', },
    { title: 'Collections', url: '/lists', },
    { title: 'History', url: '/history', },
    likes && { title: 'Recommended', url: '/recommendations/results' },
  ]

  // Setup display groups
  const login_left_links = props.login_left_links || browse_links
  const logout_left_links = props.logout_left_links || browse_links

  const login_right_links = props.login_right_links || [
    ...credit_links,
    ...artist_links,
    ...shop_links,
    {
      title: 'Saved', icon: 'heart', icon_data: { status: likeSaveStatus }, url: isAuthorized ? '/likes' : '/login', submenu: saved_links
    },
    { 
      title: first_name ? first_name : 'Finish Setup',
      icon: 'mrshoulders-outlined',  
      icon_data: { notification: unread_messages + requests },
      url: '/account/profile', 
      submenu: account_links,
    },
  ]
  const logout_right_links = props.logout_right_links || [
    { title: 'How It Works', url: '/how-it-works', icon: 'question'},
    ...shop_links,
    { title: 'Login', icon: 'mrshoulders-outlined', url: '/login' },
    // { title: 'Create Account', url: '/create-account', feature: true},
  ]

  const logo_link = props.logo_link ? props.logo_link : isAuthorized ? dashboard_link : '/'
  const left_links = isAuthorized ? login_left_links : logout_left_links
  const right_links = isAuthorized ? login_right_links : logout_right_links


  const mobile_links = isAuthorized ? [
    // ...credit_links,
    ...left_links.filter(l => l.url !== '/search'), 
    { title: 'Saved', submenu: saved_links },
    { title: first_name ? first_name : 'Account', submenu: account_links },
  ] : [
    ...left_links.filter(l => l.url !== '/search'),
    { title: 'Account', submenu: account_links },
  ]

  const renderMenuItem = (link) => !link.mobileOnly && (
    <MenuItem key={link.title+link.url} submenu={link.submenu} feature={link.feature ? 1 : 0}>
      <NavLink feature={link.feature ? 1 : 0} nav key={link.title} to={link.url || ''} target={link.target} onClick={link.onClick} external={link.external || false} artists={link.artists || false} aria-haspopup={link.submenu}>
        {link.icon ? (
          <div Xstyle={{fontSize: '10px', textAlign: 'center'}}>
            <Icon 
              stroke={link.feature || transparent ? css.color.white : css.color.black} 
              inline 
              type={link.icon} 
              size="20px" 
              {...link.icon_data} 
              // style={{ background: transparent ? 'transparent' : css.color.white, }} 
              style={{ background: 'transparent' }} 
            /> 
            {/* <br/>   */}
            {!link.hide_text && <>&nbsp;{link.title}</>}
          </div>
        ):(
          <div>{link.title}</div>
        )}
      </NavLink>
      {link.submenu && link.submenu.length ? (
        <SubMenu ariaLabel="submenu" position={link.position}>
          <div className="submenu-ear-positioner"><div className="submenu-ear"/></div>
          {link.submenu.map((item, i) => item.break ? <hr key={i} /> : !item.mobileOnly ? (
            <li key={link.title + link.url + '-submenu-' + i}>
              {item.new && <div style={{fontSize: '0.6em', color: css.color.blue, margin: '0 0 -0.5em'}}>NEW</div>}
              {item.text ? (
                <span style={{ cursor: 'default' }}>{item.text}</span>
              ):(
                <NavLink nav key={item.title} to={item.url || ''} target={item.target} onClick={item.onClick} external={item.external || false} artists={item.artists || false}>
                  <span dangerouslySetInnerHTML={{ __html: item.text || item.title }} />
                  {/* {item.count ? <span style={{ color: css.color.red, paddingLeft: '5px' }}>({item.count})</span> : null} */}
                  {item.count ? <span style={{ color: css.color.white, padding: '3px 3px 0px 4px', marginLeft: '5px', background: css.color.red, borderRadius: '2px' }}>{item.count}</span> : null}
                </NavLink>
              )}
            </li>
          ) : null)}
        </SubMenu>
      ) : null}
    </MenuItem>
  )

  return(
    <>
      <HeaderWrapper 
        menuOpen={!state.menuClosed} 
        transparent={transparent}
      >

        <DesktopNav twoColumns={!(left_links && left_links.length)}>
          { left_links && left_links.length ? (
            <div style={{position: 'relative', left: '-0.5em'}}>
              <Menu Xstyle={{fontSize: '1.3em', textTransform: 'capitalize !important'}}>
              {left_links && left_links.map((link, i) => renderMenuItem(link))}
              </Menu>
            </div>
          ):null}

          <div style={{ textAlign: 'center' }}>
            <Link to={logo_link} alt="Home">
              <div style={{ position: 'relative', top: '1px' }} alt="Home"><Logo height="14px" stroke={transparent ? css.color.white : css.color.black} /></div>
            </Link>
          </div>

          <div style={{ textAlign: 'right' }}>
            <Menu>
            {right_links && right_links.map((link, i) => renderMenuItem(link))}
            </Menu>
          </div>
        </DesktopNav>

        <MobileNav>
          <div>
            {/* {status === 'Success' && isAuthorized ? ( */}
              <FadeIn inline>
                <Link to={isAuthorized ? '/account' : '/login'} artists={isArtist} style={{ textTransform: 'uppercase' }}>
                  <Icon type="mrshoulders-outlined" inline size="24px" Xstyle={{ position: 'relative', top: '3px' }} /> 
                  {/* Login */}
                </Link>
              </FadeIn>
            {/* ):( */}
              {/* <Link to="/login" style={{ marginRight: '1em', textTransform: 'uppercase' }}>Login</Link> */}
            {/* )} */}

            {isAuthorized && (
              <Link to="/likes">
                <Icon status={likeSaveStatus} type="heart" inline size="24px" Xstyle={{ position: 'relative', top: '3px' }} />
              </Link>
            )}
          </div>

          <div style={{textAlign: 'center'}}>
            <a href={logo_link}>
              {/* <img src={logo} alt={title + ' logo'} height="14px" style={{position: 'relative', top: '3px'}} /> */}
              <div style={{ position: 'relative', top: '3px' }}><Logo height="14px"/></div>
            </a>
          </div>

          <div style={{ textAlign: 'right' }}>
            {isArtist ? (
              <Link to="/works">
                <Icon type="work" inline size="24px" Xstyle={{ position: 'relative', top: '2px', marginLeft: '10px' }} />
              </Link>
            ) : (
              <Link to="/cart">
                <Icon count={cart} type="cart" inline size="24px" Xstyle={{ position: 'relative', top: '3px', marginLeft: '10px' }} />
              </Link>
            )}

            <a href="#menu" onClick={toggleMenu}>
              <MenuButton open={!state.menuClosed}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 100 100"
                >
                  <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeLinecap="square"
                    strokeWidth="10"
                  >
                    <line x1="9" y1="22" x2="91" y2="22" id="top"></line>
                    <line x1="9" y1="50" x2="91" y2="50" id="middle"></line>
                    <line x1="9" y1="78" x2="91" y2="78" id="bottom"></line>
                  </g>
                </svg>
              </MenuButton>
            </a>
          </div>
        </MobileNav>

      </HeaderWrapper>

      <MobileMenu closed={state.menuClosed}>
        
        <ul style={{position: 'relative'}}>
          {available_funds > 0 && (
            <FadeIn>
              <div style={{
                position: 'absolute', 
                right: '1em', 
                width: '130px', 
                textAlign: 'right', 
                fontFamily: css.font.heading,
                top: '1.75em',
              }}>
                <div style={{
                  // background: css.color.lightblue, color: css.color.white, borderRadius: '50%',
                  width: '100px', height: '100px',
                  margin: '0 auto', marginTop: '0.25em', marginBottom: '0.25em',
                  color: css.color.darkblue,
                }}>
                  <div style={{ fontSize: '0.7em', marginBottom: '0.8em' }}>{isArtist ? 'Balance' : 'Credit'}</div>
                  <div style={{ fontSize: '1.3em' }}>${available_funds}</div>
                </div>
              </div>
            </FadeIn>
          )}

          {mobile_links && mobile_links.map((link, i) => link.break ? <hr key={'mobilehr'+i} /> : (
            <li key={'mobile'+link.title}>
              <FadeIn delay={`${150+(i*10)}ms`}>
                {/* <NavLink Xfeature={link.feature ? 1 : 0} nav key={link.title} to={link.url || ''} target={link.target} onClick={link.onClick || closeMenu} external={link.external || false} artists={link.artists || false}> */}
                  <div style={{fontSize: '0.7em', padding: '0 0 0.4em'}}>
                    {link.title} 
                    {/* {link.count ? <span style={{ color: css.color.white, padding: '5px', background: css.color.red }}>({link.count})</span> : null} */}
                    {link.count ? <span style={{ color: css.color.white, padding: '3px 3px 0px 4px', marginLeft: '5px', background: css.color.red, borderRadius: '2px' }}>{link.count}</span> : null}
                  </div>
                {/* </NavLink> */}
                {link.submenu && (
                  <ul>
                  {link.submenu.map((link, n) => link.break ? null : (
                      <li key={'mobile' + link.title}>
                      <FadeIn right delay={`${i * 0.5 * (150 + (n * 10))}ms`}>
                      <NavLink Xfeature={link.feature ? 1 : 0} nav key={link.title} to={link.url || ''} target={link.target} onClick={link.onClick || closeMenu} external={link.external || false} artists={link.artists || false}>
                        <div>
                          {link.title}
                          {/* {link.count ? <span style={{ color: css.color.white, padding: '5px', background: css.color.red }}>({link.count})</span> : null} */}
                          {link.count ? <span style={{ color: css.color.white, padding: '3px 7px 0px 7px', marginLeft: '10px', background: css.color.red, borderRadius: '2px' }}>{link.count}</span> : null}
                        </div>
                      </NavLink>
                      </FadeIn>
                      </li>
                    ))}
                  </ul>
                )}
              </FadeIn>
            </li>
          ))}
        </ul>
      </MobileMenu>
    </>
  )
}

const HeaderWrapper = styled('header')({
  position: 'fixed',
  zIndex: css.z.header,
  top: 0,
  left: 0,
  right: 0,
  fontFamily: css.font.heading,
  boxShadow: `2px 2px 10px rgba(0,0,0,0.025)`,
  transition: css.transition.norm,
  background: css.color.white,

  height: `${css.heights.header}px`,
  // lineHeight: `${css.heights.header}px`,
  [css.breakpoint.m_down]: {
    height: `${css.heights.header_s}px`,
    lineHeight: `${css.heights.header_s}px`,
  },
}, propStyles({
  transparent: {
    [css.breakpoint.m_up]:{
      background: 'transparent',
      boxShadow: `2px 2px 10px rgba(0,0,0,0)`,
      '& a':{
        color: `${css.color.white}`,
        '&:hover':{
          color: `${css.color.white}`,
        }
      }
    },
  },
  menuOpen: {
    [css.breakpoint.m_down]: {
      background: css.color.white,
    }
  },
}))

const DesktopNav = styled('div')({
  ...css.widths.outer,
  display: 'grid',
  gridTemplateColumns: '1fr 130px 1fr',
  alignItems: 'center',
  // justifyContent: 'center',
  height: '100%',
  lineHeight: '1em',

  [css.breakpoint.m_down]: {
    display: 'none',
  },
}, propStyles({
  twoColumns: {
    gridTemplateColumns: '220px 1fr',
  }
}))

const MobileNav = styled('div')({
  // position: 'relative',
  ...css.widths.outer,
  // margin: css.margins.page_mobile,
  fontSize: css.font.s,
  lineHeight: `${css.heights.header_s}px`,

  display: 'grid',
  gridTemplateColumns: '1fr 100px 1fr',

  '& .icon-container':{
    position: 'relative',
    top: '8px !important',
    padding: '0 10px',
  },

  '& > div:nth-of-type(1)':{marginLeft: '-10px'},
  '& > div:nth-of-type(3)':{marginRight: '-10px'},

  [css.breakpoint.m_up]: {
    display: 'none',
  },
})

const Menu = styled('ul')({
  listStyle: 'none',
  margin: 0,
  padding: 0,
})

const MenuItem = styled('li')({
  display: 'inline-block',

  '& > a':{
    width: "100%",
    height: "100%",
    display: "block",
    textDecoration: "none",
    padding: "0 0.75em",

    '& > span':{
      borderBottom: `2px solid transparent`,
    },
    
    '& img': {
      height: '1em',
      position: 'relative',
      top: '2px',
      marginLeft: '4px',
    }
  },

},propStyles({
  feature: {
    margin: '0 5px',
  },
  submenu: {
    position: "relative",
    '& a': {
      '& div': {
        // color: css.color.primary,
        borderBottom: `2px solid transparent !important`,
      },
    },
    "&:hover, &:active, &:focus": { 
      "& ul": { 
        display: "block" 
      },
      '& a': {
        '& div': {
          // color: css.color.primary,
          borderBottom: `2px solid transparent !important`,
        },
      },
    },
    '& ul': {
      display: 'none',
      visibility: "hidden",
      opacity: "0",
      position: "absolute",
      // transition: "all 0.5s ease",
    },
    "&:hover > ul, &:focus-within > ul,  ul:hover": {
      visibility: "visible",
      opacity: "1",
      display: "block",
    },
  }
}))


const SubMenu = styled('ul')({
  position: 'absolute',
  // top: `calc(1em + 10px)`,
  marginTop: '12px',
  zIndex: css.z.header + 1,
  right: 0,
  minWidth: '210px',
  width: 'max-content',
  textAlign: 'left',
  // textAlign: 'center',
  // border: `1px solid ${css.color.lightgray}`,
  borderRadius: '4px',
  background: css.color.white,
  // boxShadow: '0px 0px 25px -4px rgba(196,196,196,1)',
  boxShadow: '0px 0px 25px -4px rgba(0,0,0,0.3)',
  // padding: '1em 0.75em',
  padding: '1em 0em',
  animation: `${css.fadeZoomShort} ${css.speed[2]}ms ease`,

  '& .submenu-ear-positioner':{
    position: 'absolute',
    // right: '10px',
    margin: '-30px 0 0 0',
    width: '100%',
    textAlign: 'right',
  },

  '& .submenu-ear':{
    borderColor: `${css.color.white} transparent`,
    borderStyle: 'solid',
    borderWidth: '0px 20px 20px 20px',
    height: '0px',
    width: '0px',
    display: 'inline-block',
    marginRight: '40px',
    // float: 'right',
    // boxShadow: '0px 0px 15px -4px rgba(196,196,196,1)',
  },

  '& li':{
    display: "block",
    width: "100%",
    cursor: "pointer",
    transition: "100ms all ease-in-out",
    padding: '0 1.5em',
    
    '& a':{
      width: "100%",
      height: "100%",
      display: "block",
      textDecoration: "none",
      transition: "100ms all ease-in-out",
      lineHeight: "2.5em",
      color: `${css.color.black} !important`,
      '& span': { 
        borderBottom: "2px solid transparent", 
        // marginLeft: '1em' 
      },
    },

    '&:hover':{
      background: css.color.offwhite,
      '& a':{
        '& span':{
          // borderBottomColor: css.color.gray,
          // color: css.color.darkgray,
        }
      }
    }
  },

  '& hr':{ 
    margin: '0.75em 0',
    border: 0,
    borderBottom: `1px dotted ${css.color.gray} !important`,
    height: '0px',
    background: 'transparent',
  }
}, ({ position })=>{
  const positions = {
    right: {},
    left: {
      left: 0,
      right: 'auto',
      '& .submenu-ear-positioner':{
        textAlign: 'left',
      }
    },
  }
  return positions[position || 'right']
  // right: position==='center' ? '-60px' : 0,
})

const NavLink = styled(Link)({
  // margin: `0 ${css.space.r}`,
  transition: css.transition.fast,
  
  color: css.color.black,
  textDecoration: 'none',
  textTransform: 'uppercase',
  fontSize: css.font.xxs,
  letterSpacing: '1.5px',

  '& > div':{
    display: 'inline-block',
    padding: '0.25em 0 0 0',
    borderBottom: `2px solid transparent`,
    // transition: css.transition.norm,
    transition: `${css.speed[2]}ms border ease-in-out`,
  },

  '&:hover': {
    // color: css.color.primary,
    '& > div': {
      // color: css.color.primary,
      // color: css.color.primary,
      borderBottom: `2px solid ${css.color.lightgrayhover}`,
    },
  },

  '&.active': {
    '& > span, & > div': {
      borderBottomColor: css.color.primary,
      color: css.color.primary,
      [css.breakpoint.m_down]:{
        borderBottomColor: css.color.darkgray,
        color: css.color.darkgray,
      }
    }
  }
}, propStyles({
  feature: {
    borderRadius: '1em',
    background: css.color.blue,
    
    '& span, & div':{
      color: css.color.white + ' !important',
    },
    '&:hover':{
      background: css.color.darkblue,
      color: css.color.white,
    }
  }
}))

const MobileMenu = styled('div')({
  position: 'fixed',
  zIndex: css.z.header,
  top: `${css.heights.header_s}px`,
  bottom: 0,
  left: 0,
  right: 0,
  background: css.color.white,
  borderTop: `0.5px dotted ${css.color.lightgray}`,

  display: 'flex',
  margin: '0 auto',
  // justifyContent: 'center',
  // alignItems: 'center',

  opacity: 0,
  animation: `${css.fadeIn} ${css.speed[3]}ms ease forwards`,

  '& ul': {
    margin: 'auto',
    width: '100%',
    maxHeight: '100%',
    padding: '2em 2em 5em',
    overflowY: 'scroll',
    WebkitOverflowScrolling: 'touch !important',

    '& li': {
      listStyle: 'none',
      padding: 0,
      margin: '0 0 1.5em',
      // fontSize: css.font.l,
      fontSize: '1.2rem',
      fontFamily: css.font.heading,

      '& ul':{
        listStyle: 'none',
        padding: 0,
        margin: 0,
        '& li': {
          listStyle: 'none',
          padding: 0,
          margin: 0,
          // fontSize: css.font.l,
          fontSize: '2rem',
          fontFamily: css.font.heading,
          '& > div':{
            padding: 0,
            margin: 0,
          }
        }
      },
    },
  },
  '& a': {
    margin: 0,
    textTransform: 'none',
    letterSpacing: 0,

    '& div': {
      // padding: `${css.space.xs} ${css.space.r} !important`,
      padding: `0.3em 0 !important`,
      // background: css.color.red,
      border: '0 !important',
    },

    '& img': {
      height: '1em',
      position: 'relative',
      top: '2px',
      marginLeft: '4px',
    }
  },
  '& hr': {
    margin: '0.75em 2em',
    border: 0,
    borderBottom: `1px dotted ${css.color.gray} !important`,
    height: '0px',
    background: 'transparent',
  },
}, propStyles({
  closed: {
    display: 'none',
  },
}))

const MenuButton = styled('div')({
  display: 'inline-block',
  // width: css.heights.header_s,
  // height: css.heights.header_s,
  // backgroundSize: '80%',
  // padding: '0.9em',
  // position: 'fixed',
  // top: 0,
  // right: '0.25em',

  width: '42px',
  height: '24px',
  padding: '0 10px',
  position: 'relative',
  top: '9px',
  // marginTop: '-9px',

  // width: css.heights.header_s,
  // height: css.heights.header_s,
  // padding: '0em',

  textTransform: 'uppercase',
  letterSpacing: '1px',

  '& img': {
    width: '100%',
    display: 'block',
  },
  '& svg': {
    width: '100%',
    display: 'block',
    transition: css.transition.norm,
  },
  '& line:nth-of-type(1)': {
    transition: css.transition.norm,
  },
  '& line:nth-of-type(2)': {
    transition: css.transition.fast,
  },
  '& line:nth-of-type(3)': {
    transition: css.transition.norm,
  }
}, propStyles({
  open: {
    '& svg': {
      transform: 'rotate(90deg)',
    },
    '& line:nth-of-type(1)': {
      transform: 'translateY(25%) rotate(45deg)',
      transformOrigin: '45% 20%',
    },
    '& line:nth-of-type(2)': {
      strokeOpacity: 0,
    },
    '& line:nth-of-type(3)': {
      transform: 'translateY(-25%) rotate(-45deg)',
      transformOrigin: '45% 80%',
    }
  },
}))

export default Header
