import Settings from 'settings'
import Cookies from 'js-cookie'
import { dispatch } from 'redux/store'
import { navigate } from 'components'

// TODO add amplitude.getInstance().setUserId('USER_ID');

export const auth = {
  name: 'auth',
  request: {
    url: `${Settings.api}/auth:endpoint`,
  },
  state: {
    data: {
      // authenticated: Cookies.get('_artinres_session') ? true : false,
      valid: null,
      auth_token: Cookies.get('auth_token') || false,
      meta_auth_token: Cookies.get('meta_auth_token') || false,
      user: {
        first_name: Cookies.get('username') || false,
        last_name: false, // Cookies.get('lname') || false,
        has_password: false, // Cookies.get('haspass') || false,
      },
      roles: [
        // Cookies.get('airsa') ? 'Superadmin' : ''
      ],
    },
    error: { errors: {} },
  },
  effects: {
    login: (payload, store) => dispatch({ 
      type: 'auth/fetch', 
      payload: { 
        url: `${Settings.api}/auth/submit`, 
        method: 'post',
        data: payload.data,
        success: (data) => {
          if (payload.success) payload.success(data)

          // Setup Cookies + Fetch current_user data
          dispatch({ type: 'auth/setUserCookies', data })
          dispatch({ type: 'current_user/fetch', payload: {endpoint: '/all'} })
          dispatch({ type: 'follows/fetch' })
          dispatch({ type: 'likes/fetch' })
          dispatch({ type: 'dislikes/fetch' })
          dispatch({ type: 'saves/fetch' })
          dispatch({ type: 'lists/fetch' })

          // Redirects
          if (['intro', 'welcome'].some(s => window.location.pathname.includes(s))) {
            navigate('/')
          } 
          else if (data.roles.includes('Artist') && !data.roles.includes('Superadmin')) {
            // window.location = `${Settings.artists_path}/dashboard`
            navigate('/works')
          }
        },
      }
    }),
    logout: (payload, store) => dispatch({
      type: 'auth/fetch',
      payload: {
        url: `${Settings.api}/auth/logout`,
        method: 'get',
        nodata: true,
        success: (payload) => {
          console.log('logout success')
          dispatch({ type: 'auth/removeUserCookies' }) // needs to be first
          dispatch({ type: 'auth/reset' })
          dispatch({ type: 'current_user/reset' })
          dispatch({ type: 'likes/reset' })
          dispatch({ type: 'dislikes/reset' })
          dispatch({ type: 'follows/reset' })
          dispatch({ type: 'saves/reset' })
          dispatch({ type: 'fans/reset' })
          dispatch({ type: 'recommendations/reset' })
          dispatch({ type: 'conversations/reset' })
          dispatch({ type: 'conversation/reset' })
          dispatch({ type: 'installment_purchases/reset' })
          dispatch({ type: 'installment_purchase/reset' })
          dispatch({ type: 'purchases/reset' })
          dispatch({ type: 'purchase/reset' })
          dispatch({ type: 'rentals/reset' })
          dispatch({ type: 'rental/reset' })
          dispatch({ type: 'works/reset' })
          dispatch({ type: 'forms/reset' })
          // navigate('/')
          setTimeout(() => { 
            window.location = '/'
          },1000)
        },
      }
    }),
    createAccount: ({ 
      data,
      success, 
      error, 
      login = false, 
      flow = false, 
      confirmation = false, 
    }, store) => dispatch({ 
      type: 'auth/fetch', 
      payload: {
        url: `${Settings.api}/auth/submit`,
        method: 'post',
        data: { ...data, flow },
        success: (payload) => {
          if (success) success(payload)

          if (confirmation) {
            dispatch({ type: 'modals/open', payload: { name: 'confirmAccount' } })
          }

          if (login) {
            dispatch({ type: 'auth/setUserCookies', data: payload })
            dispatch({ type: 'current_user/fetch', payload: { endpoint: '/all' } })
            dispatch({ type: 'auth/resetStatus' })
          } else {
            dispatch({ type: 'auth/removeUserCookies' })
            dispatch({ type: 'auth/reset' })
          }
        },
        error: (payload) => {
          if (error) error(payload)
        }
      }
    }),
    createPassword: (payload, store) => dispatch({ 
      type: 'auth/fetch', 
      payload: {
        url: `${Settings.api}/auth/update_account`,
        method: 'patch',
        data: payload,
        success: (data) => {
          // Cookies.set('auth_token', payload.auth_token)
          navigate('/welcome/2')
          dispatch({ type: 'auth/reset' })
        },
      }
    }),
    createPasswordDuringCheckout: (payload, store) => dispatch({ 
      type: 'auth/fetch', 
      payload: {
        url: `${Settings.api}/auth/save_password`,
        method: 'patch',
        data: payload,
        success: (data) => {
          dispatch({ type: 'auth/setUserCookies', data: payload })
          dispatch({ type: 'current_user/fetch', payload: { endpoint: '/all' } })
          dispatch({ type: 'auth/resetStatus' })
          if (payload.success) payload.success(data)
        },
      }
    }),
    updateAccount: ({success, data}, store) => dispatch({ 
      type: 'auth/fetch', 
      payload: {
        url: `${Settings.api}/auth/update_account`,
        method: 'patch',
        data,
        success: (response_data) => {
          success(response_data)
          dispatch({ type: 'auth/reset' })
        },
      }
    }),
    checkToken: (payload, store) => dispatch({ 
      type: 'auth/fetch', 
      payload: {
        url: `${Settings.api}/auth/token`,
        method: 'post',
        data: payload,
        success: (payload) => {
          console.log('checkToken success')
          dispatch({ type: 'auth/setUserCookies', payload })
          dispatch({ type: 'auth/reset' })
        }
      }
    }),
    setUserCookies: (payload = {}) => {
      const settings = { domain: `.${window.location.hostname}` }
      // SET - current proper cookies - may be getting set through API responses so i commented out for now
      if (!Cookies.get('auth_token') && payload.auth_token) Cookies.set('auth_token', payload.auth_token, settings)
      if (!Cookies.get('meta_auth_token') && payload.meta_auth_token) Cookies.set('meta_auth_token', payload.meta_auth_token, settings)
      // if (!Cookies.get('username') && payload.user) Cookies.set('username', payload.user.first_name, settings)
    },
    removeUserCookies: () => {
      const settings = { domain: `.${window.location.hostname}` }
      Cookies.remove('auth_token', settings)
      Cookies.remove('meta_auth_token', settings)
      // Cookies.remove('username', settings)
    },
    // forgotPassword: (payload, store) => dispatch({}),
  }
}
